import {
  buses,
  CustomDict,
  DemoGroup,
  setupGroups,
} from 'demo/controllers/devices/advancedDevices/devicesUtils';
import DemoDatabase from 'demo/persistentStorage';
import { demoDevicesController } from 'demo/provider';
import { createNewItemWithCustomId } from 'demo/utility';
import { Bus } from 'store/selectedVessel/types';
import { GroupItem } from 'types/groups';
import { CalibrationConfig } from 'views/CalibrationsView';

export class DemoAdvancedDeviceCache {
  private static instance: DemoAdvancedDeviceCache | null = null;
  private _calibrations: CalibrationConfig[] = [];
  private _customDicts: CustomDict[] = [];
  private _buses: Bus[] = [];
  private _groups: DemoGroup[] = [];

  public static getInstance(): DemoAdvancedDeviceCache {
    if (this.instance === null) {
      this.instance = new DemoAdvancedDeviceCache();
    }
    return this.instance;
  }

  async getCalibrations(): Promise<CalibrationConfig[]> {
    if (this._calibrations.length === 0) {
      this._calibrations = await DemoDatabase.getInstance().DeviceCalibrations.getAllWithDefault();
    }
    return this._calibrations;
  }

  addCalibration(data: CalibrationConfig): void {
    this._calibrations = createNewItemWithCustomId(this._calibrations, data, 'id');
    DemoDatabase.getInstance().DeviceCalibrations.add(
      this._calibrations[this._calibrations.length - 1]
    );
  }

  deleteCalibration(id: number): void {
    this._calibrations = this._calibrations.filter(calibration => calibration.id !== id);
    DemoDatabase.getInstance().DeviceCalibrations.delete(id);
  }

  getCustomDicts(): CustomDict[] {
    if (this._customDicts.length === 0) {
      this._customDicts = require('demo/data/devices/customDicts.json');
    }
    return this._customDicts;
  }

  getBuses(): Bus[] {
    if (this._buses.length === 0) {
      this._buses = buses;
    }
    return this._buses.filter(bus => bus.supportsPropertyAdd);
  }

  getGroups() {
    if (this._groups.length === 0) {
      this._groups = setupGroups();
    }
    return this._groups.map(group => {
      return { ...group, groupSize: group.ids.length };
    });
  }

  retrieveGroupByName = (name: string): GroupItem[] => {
    if (this._groups.length === 0) {
      this._groups = setupGroups();
    }
    const groupSelected = this._groups.find(group => group.groupName === name);
    const response: GroupItem[] = [];
    if (!groupSelected) {
      return response;
    }
    groupSelected.ids.forEach(idObj => {
      const deviceFound = demoDevicesController.devices.find(
        device => device.id === Number(idObj.device)
      );
      if (!deviceFound) {
        return;
      }
      const propertyFound = deviceFound.vhs.find(property => property.id === idObj.property);
      if (!propertyFound) {
        return;
      }
      response.push({
        property: propertyFound.fullResponse(),
        deviceId: deviceFound.id.toString(),
        deviceControllable: deviceFound.controllable,
        deviceBus: deviceFound.bus,
        deviceName: deviceFound.name,
        deviceOnline: deviceFound.online,
      });
    });
    return response;
  };

  updateGroupName = (groupName: string, newGroupName: string): void => {
    const groupIndex = this._groups.findIndex(group => group.groupName === groupName);
    if (groupIndex === -1) {
      return;
    }
    this._groups[groupIndex].groupName = newGroupName;
  };
}
