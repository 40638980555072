import { DemoAdvancedDeviceCache } from 'demo/caches/AdvancedDeviceCache';
import { DefaultDeviceAlertResponse } from 'demo/controllers/alerts/alertsUtils';
import DemoDatabase from 'demo/persistentStorage';
import {
  AdvancedDevicesPropertyVh,
  databaseResponse,
  DeviceAlertTable,
} from 'demo/persistentStorage/database/tables';
import { DeviceAlertConfig } from 'demo/structs/alerts/DeviceAlertConfig';
import { ValueHolder } from 'store/selectedDevice/types';

import DemoAdvancedDevice from './AdvancedDevice';

class DemoAdvancedDeviceProperty {
  id!: number;
  name!: string;
  propertyName!: string;
  propertyUid!: string;
  value!: string;
  controllable!: boolean;
  instance!: string;
  deviceUid!: string;
  multiplier!: number;
  offset!: number;
  unit!: string;
  dictionary!: Record<string, string>;
  customDictionary!: string | null;
  category!: string;
  includeInSummary!: boolean;
  gaugeLow!: number;
  gaugeHigh!: number;
  rangeLow!: number;
  rangeHigh!: number;
  loggingEnabled!: boolean;
  deviceGroup!: string;
  deviceId!: number;
  active!: boolean;
  alertConfig: DeviceAlertConfig | null = null;

  constructor(device: DemoAdvancedDevice, property: AdvancedDevicesPropertyVh) {
    Object.assign(this, {
      ...property,
      propertyName: property.name,
      deviceUid: device.uid,
      offset: 0,
      deviceId: device.id,
    });
    this.retrieveAlert(device);
  }

  async retrieveAlert(device: DemoAdvancedDevice) {
    const tempRecord = await DemoDatabase.getInstance()
      .DeviceAlert.where<databaseResponse<DeviceAlertTable>>('alertIdIndex', 'prop_' + this.id)
      .catch(e => console.log(e));
    if (tempRecord && tempRecord.length !== 0) {
      const alertData = tempRecord[0];
      this.alertConfig = new DeviceAlertConfig(
        alertData.id,
        device,
        this,
        alertData.instant,
        alertData.warnLevelHigh,
        alertData.warnLevelLow,
        alertData.criticalLevelHigh,
        alertData.criticalLevelLow
      );
      this.alertConfig.setConfirmedCriticality(this.calculateCriticality());
      device.highestWarningLevel();
    }
  }

  getName(): string {
    if (null == this.name || this.name === '') {
      return this.propertyName;
    }
    return this.name;
  }

  containsDict(): boolean {
    return null != this.dictionary && Object.keys(this.dictionary).length !== 0;
  }

  getParsedValue(currentValue?: string): string {
    if (currentValue === undefined) {
      currentValue = this.value;
    }
    try {
      if (this.containsDict()) {
        return this.dictionary![parseInt(currentValue)];
      }
    } catch (e) {
      //noop
    }
    if (!isNaN(Number(currentValue))) {
      let retval: string = currentValue;
      if (null != this.unit) {
        retval += this.unit;
      }
      return retval;
    } else {
      return currentValue;
    }
  }

  calculateCriticality(): string {
    const currentValue = this.value;
    if (null == currentValue || currentValue === '' || this.alertConfig == null) {
      return 'NORMAL';
    }
    if (isNaN(Number(currentValue))) {
      return 'NORMAL';
    }
    const dVal = Number(currentValue);
    if (this.alertConfig.criticalLevelLowEnable) {
      if (dVal <= Number(this.alertConfig.criticalLevelLow)) {
        return 'CRITICAL_LOW';
      }
    }
    if (this.alertConfig.criticalLevelHighEnable) {
      if (dVal >= Number(this.alertConfig.criticalLevelHigh)) {
        return 'CRITICAL_HIGH';
      }
    }
    if (this.alertConfig.warnLevelLowEnable) {
      if (dVal <= Number(this.alertConfig.warnLevelLow)) {
        return 'WARNING_LOW';
      }
    }
    if (this.alertConfig.warnLevelHighEnable) {
      if (dVal >= Number(this.alertConfig.warnLevelHigh)) {
        return 'WARNING_HIGH';
      }
    }
    return 'NORMAL';
  }

  fullResponse(): ValueHolder {
    const response = {
      id: this.id,
      propertyUid: this.propertyUid,
      userDefinedName: this.name,
      instance: this.instance,
      feedbackInstance: this.instance,
      name: this.name,
      value: this.value,
      rawValue: this.value,
      parsedValue: this.getParsedValue(),
      average: 0,
      active: this.active,
      includeInSummary: this.includeInSummary,
      persistProperty: true,
      controllable: this.controllable,
      loggingEnabled: this.loggingEnabled,
      multiplier: this.multiplier,
      offset: 0,
      unit: this.unit,
      propertyName: this.propertyName,
      category: this.category,
      lastUpdated: new Date().toISOString(),
      fullName: this.name,
      dictionary: this.dictionary ?? {},
      // invert: boolean,
      // curveId: number,
      // curveName: string,
      // deviceGroup: string,
      // includeInSeaReady: boolean,
      gaugeLow: this.gaugeLow,
      gaugeHigh: this.gaugeHigh,
      rangeLow: this.rangeLow,
      rangeHigh: this.rangeHigh,
      // hasAlertConfig: boolean,
      customDictionary: this.customDictionary,
      // rawValue: string,
      displayValue: this.retrieveDisplayValue(),
      ...(this.alertConfig ? this.alertConfig.deviceResponse() : DefaultDeviceAlertResponse),
    };
    // @ts-ignore
    return response;
  }

  retrieveDisplayValue = (): string | null => {
    let displayValue = null;
    if (this.customDictionary !== null) {
      const dictFound = DemoAdvancedDeviceCache.getInstance()
        .getCustomDicts()
        .find(customDict => customDict.name === this.customDictionary);
      if (dictFound && dictFound.valuePairs && dictFound.valuePairs.length > 0) {
        try {
          const numVal = Number(this.value);
          const valuePair = dictFound.valuePairs.find((val: any) => Number(val.value) === numVal);
          if (valuePair) {
            displayValue = valuePair.displayValue;
          }
        } catch (e) {
          //noop
        }
      }
    }
    return displayValue;
  };

  databaseStruct(): AdvancedDevicesPropertyVh {
    return {
      // id: this.id,
      category: this.category,
      controllable: this.controllable,
      value: this.value,
      deviceGroup: this.deviceGroup,
      deviceUid: this.deviceUid, //FIXME: Is this needed,
      gaugeHigh: this.gaugeHigh,
      gaugeLow: this.gaugeLow,
      includeInSeaReady: this.includeInSummary,
      instance: this.instance,
      instantReport: false, //FIXME: Is this needed,
      invert: false,
      loggingEnabled: this.loggingEnabled,
      multiplier: this.multiplier,
      name: this.name,
      propertyName: this.propertyName,
      propertyUid: this.propertyUid,
      rangeHigh: this.rangeHigh,
      rangeLow: this.rangeLow,
      unit: this.unit,
      deviceId: this.deviceId,
      customDictionary: this.customDictionary,
      includeInSummary: this.includeInSummary,
      active: this.active,
      dictionary: this.dictionary,
    };
  }
}

export default DemoAdvancedDeviceProperty;
