import { DemoAlertCache } from 'demo/caches/AlertCache';
import DemoDatabase from 'demo/persistentStorage';
import { getRandom } from 'demo/utility';
import { GeofenceParams, GeofenceRoute } from 'store/selectedVessel/types';
import { isVesselInside } from 'views/VesselDashboard/tabs/VesselDashboardSecurity/components/GeofenceExp';

import DemoVesselController from './vesselController';

class DemoGeofenceController {
  private static instance: DemoGeofenceController | null = null;
  private _geofence: GeofenceParams | null = null;
  private _geofenceRoute: GeofenceRoute[] = [];

  public static getInstance(): DemoGeofenceController {
    if (this.instance === null) {
      this.instance = new DemoGeofenceController();
    }
    return this.instance;
  }

  async retrieveGeofence(): Promise<GeofenceParams> {
    if (this._geofence === null) {
      const [lat, lon] = this.checkLatAndLon(
        DemoVesselController.getInstance().vessel?.latitude,
        DemoVesselController.getInstance().vessel?.longitude
      );
      const geofenceData =
        await DemoDatabase.getInstance().VesselGeofence.getAllWithDefault<GeofenceParams>([
          {
            id: 1,
            lat: (lat + 0.0002).toString(),
            lon: (lon + 0.0006).toString(),
            enabled: false,
            radius: 150,
            delay: 5,
            courseTracking: true,
          },
        ]);
      this._geofence = geofenceData[0];
    }
    return this._geofence;
  }

  get geofenceRoute(): GeofenceRoute[] {
    if (this._geofenceRoute.length === 0) {
      this.generateRoute();
    }
    return this._geofenceRoute;
  }

  generateRoute = (): void => {
    const [lat, lon] = this.checkLatAndLon(
      DemoVesselController.getInstance().vessel?.latitude,
      DemoVesselController.getInstance().vessel?.longitude
    );
    const [geofenceLat, geofenceLon] = this.checkLatAndLon(
      this._geofence?.lat,
      this._geofence?.lon
    );
    const length = 50;
    const newRoute: GeofenceRoute[] = [];
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        newRoute.push({
          id: i,
          latitude: geofenceLat.toString(),
          longitude: geofenceLon.toString(),
          timestamp: new Date().toLocaleDateString(),
          speed: 10,
          heading: 15,
          depth: 10,
        });
      } else if (i === length - 1) {
        newRoute.push({
          id: i,
          latitude: lat.toString(),
          longitude: lon.toString(),
          timestamp: new Date().toLocaleDateString(),
          speed: 10,
          heading: 15,
          depth: 10,
        });
      } else {
        const randomLat = getRandom(lat + 0.0005, geofenceLat, 6);
        const randomLon = getRandom(lon + 0.001, lon, 6);
        newRoute.push({
          id: i,
          latitude: randomLat.toString(),
          longitude: randomLon.toString(),
          timestamp: new Date().toLocaleDateString(),
          speed: 10,
          heading: 15,
          depth: 10,
        });
      }
    }
    this._geofenceRoute = newRoute;
  };

  checkLatAndLon = (latVal: string | undefined, lonVal: string | undefined): [number, number] => {
    let lat = 0;
    let lon = 0;
    if (latVal && !isNaN(Number(latVal))) {
      lat = Number(latVal);
    }
    if (lonVal && !isNaN(Number(lonVal))) {
      lon = Number(lonVal);
    }
    return [lat, lon];
  };

  changeState = (state: boolean): boolean => {
    if (this._geofence) {
      this._geofence = { ...this._geofence, enabled: state };
      DemoDatabase.getInstance().VesselGeofence.update(1, this._geofence);
      return true;
    }
    return false;
  };

  updateGeofenceParams = async (
    lat: string,
    lon: string,
    radius: number,
    delay: number
  ): Promise<void> => {
    const vesselInsideGeofence = isVesselInside(
      DemoVesselController.getInstance().vessel?.latitude,
      DemoVesselController.getInstance().vessel?.longitude,
      this._geofence?.lat ?? '0',
      this._geofence?.lon ?? '0',
      this._geofence?.radius
    );
    const updatedVesselInsideGeofence = isVesselInside(
      DemoVesselController.getInstance().vessel?.latitude,
      DemoVesselController.getInstance().vessel?.longitude,
      lat,
      lon,
      radius
    );
    const vesselInsideGeofenceWarning = isVesselInside(
      DemoVesselController.getInstance().vessel?.latitude,
      DemoVesselController.getInstance().vessel?.longitude,
      this._geofence?.lat ?? '0',
      this._geofence?.lon ?? '0',
      this._geofence?.radius,
      0.8
    );
    const updatedVesselInsideGeofenceWarning = isVesselInside(
      DemoVesselController.getInstance().vessel?.latitude,
      DemoVesselController.getInstance().vessel?.longitude,
      lat,
      lon,
      radius,
      0.8
    );
    if (vesselInsideGeofence && !updatedVesselInsideGeofence) {
      await DemoAlertCache.getInstance().createNewAlert(
        'GEOFENCE',
        'geo_123',
        'Geofence Alert',
        'Vessel is outside geofence',
        `${this._geofence?.lat} ${this._geofence?.lon}`,
        'CRITICAL'
      );
    } else if (vesselInsideGeofenceWarning && !updatedVesselInsideGeofenceWarning) {
      await DemoAlertCache.getInstance().createNewAlert(
        'GEOFENCE',
        'geo_123',
        'Geofence Alert',
        'Vessel is outside geofence',
        `${this._geofence?.lat} ${this._geofence?.lon}`,
        'WARNING'
      );
    } else if (updatedVesselInsideGeofence) {
      DemoAlertCache.getInstance().removeAlert(123, 'GEOFENCE');
    }
    this._geofence = {
      courseTracking: false,
      enabled: this._geofence?.enabled ?? false,
      lat,
      lon,
      radius,
      delay,
    };
    DemoDatabase.getInstance().VesselGeofence.update(1, this._geofence);
  };
}

export default DemoGeofenceController;
