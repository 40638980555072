import { GetVesselAuditPaginatedInterface } from 'api/selectedVessel';
import DemoDatabase from 'demo/persistentStorage';
import { createNewItemWithCustomId } from 'demo/utility';
import { VesselLog } from 'store/selectedVessel/types';
import { Note, Vessel } from 'store/types';
import { BasicVessel } from 'store/vessels/types';

import { LogsController } from './alerts/alertController';
import DemoNotesController from './notesController';

type VesselLocation = {
  latitude: string | null;
  longitude: string | null;
  heading: number;
  speed: number;
  depth: number;
  sourceCount: number;
  sourceUsed: number;
  geofenceState: string;
  locationValid: boolean;
};

class DemoVesselController {
  private static instance: DemoVesselController | null = null;
  private _vessel: Vessel | null = null;
  private _vesselLocation: VesselLocation | null = null;
  private _vessels: BasicVessel[] = [];
  private _notes: Note[] = [];
  private _logs: VesselLog[] = [];

  private notesController = new DemoNotesController();

  public static getInstance(): DemoVesselController {
    if (this.instance === null) {
      this.instance = new DemoVesselController();
    }
    return this.instance;
  }

  get vessel(): Vessel | null {
    if (this._vessel?.vesselSubscription) {
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 31);
      this._vessel = {
        ...this._vessel,
        vesselSubscription: {
          ...this._vessel?.vesselSubscription,
          nextBillingDate: nextWeek.toISOString(),
        },
      };
    }
    return this._vessel;
  }

  get vesselLocation(): VesselLocation {
    this._vesselLocation = {
      latitude: this._vessel?.latitude ?? null,
      longitude: this._vessel?.longitude ?? null,
      heading: 0,
      speed: 0,
      depth: 0,
      sourceCount: 0,
      sourceUsed: 0,
      geofenceState: 'NORMAL',
      locationValid: true,
    };
    return this._vesselLocation as VesselLocation;
  }

  retrieveNotes = async (): Promise<Note[]> => {
    this._notes = await this.notesController.retrieveNotes();
    return this._notes;
  };

  retrieveLogs = async (): Promise<VesselLog[]> => {
    if (this._logs.length === 0) {
      this._logs = await DemoDatabase.getInstance().VesselLogs.getAllWithDefault();
    }
    return this._logs;
  };

  clearLogs = (): void => {
    const instance = DemoDatabase.getInstance();
    if (instance) {
      DemoDatabase.getInstance().VesselLogs.clear();
    }
  };

  retrieveVessel = async (): Promise<Vessel | null> => {
    if (!this._vessel) {
      this._vessel = (await DemoDatabase.getInstance().Vessel.getAllWithDefault<Vessel>())[0];
    }
    if (this._vessel?.vesselSubscription) {
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 31);
      this._vessel = {
        ...this._vessel,
        vesselSubscription: {
          ...this._vessel?.vesselSubscription,
          nextBillingDate: nextWeek.toISOString(),
        },
      };
    }

    return this._vessel;
  };

  retrieveVessels = async (): Promise<BasicVessel[]> => {
    await this.updateVesselsList();
    return this._vessels;
  };

  updateDiscovering = (discovering: boolean): void => {
    if (this._vessel) {
      this._vessel = { ...this._vessel, discovering: discovering };
      setTimeout(() => {
        if (this._vessel) {
          this._vessel = { ...this._vessel, discovering: !discovering };
        }
      }, 5000);
    }
  };

  updateVesselsList = async (): Promise<void> => {
    if (!this._vessel) {
      this._vessel = (await DemoDatabase.getInstance().Vessel.getAllWithDefault<Vessel>())[0];
    }
    const vesselsData: BasicVessel[] = [
      {
        id: this._vessel.id,
        name: this._vessel.name,
        model: this._vessel.model,
        latitude: this._vessel.latitude,
        longitude: this._vessel.longitude,
        numberOfMonitoredDevices: this._vessel.numberOfMonitoredDevices,
        devicesOnline: this._vessel.devicesOnline,
        warningDeviceCount: this._vessel.warningCount ?? 0,
        criticalDeviceCount: this._vessel.criticalCount,
        state: this._vessel.state,
        authorised: true,
        requested: false,
        slotNumber: '',
        lastSeen: this._vessel.lastSeen,
        subscriptionState: this._vessel.vesselSubscription?.currentSubscriptionState ?? 'VOUCHER',
        userRole: 'Owner',
        manufacturer: '',
        geofenceBreached: true,
        online: true,
      },
      {
        id: 'FAKE-1',
        name: 'Gale',
        model: null,
        latitude: '40.4432567',
        longitude: '5.3432654',
        numberOfMonitoredDevices: 5,
        devicesOnline: 5,
        warningDeviceCount: 4,
        criticalDeviceCount: 0,
        state: 'WARNING',
        authorised: false,
        requested: false,
        slotNumber: '',
        lastSeen: '',
        subscriptionState: 'VOUCHER',
        userRole: 'Owner',
        manufacturer: '',
        geofenceBreached: true,
        online: true,
      },
      {
        id: 'FAKE-2',
        name: 'Pura Vida',
        model: null,
        latitude: '40.4432567',
        longitude: '29.3432654',
        numberOfMonitoredDevices: 25,
        devicesOnline: 24,
        warningDeviceCount: 2,
        criticalDeviceCount: 10,
        state: 'CRITICAL',
        authorised: false,
        requested: false,
        slotNumber: '',
        lastSeen: '',
        subscriptionState: 'VOUCHER',
        userRole: 'Owner',
        manufacturer: '',
        geofenceBreached: true,
        online: true,
      },
      {
        id: 'FAKE-2',
        name: 'Cleopatra',
        model: null,
        latitude: '40.4432567',
        longitude: '-10.482389',
        numberOfMonitoredDevices: 32,
        devicesOnline: 30,
        warningDeviceCount: 0,
        criticalDeviceCount: 0,
        state: 'NORMAL',
        authorised: false,
        requested: false,
        slotNumber: '',
        lastSeen: '',
        subscriptionState: 'VOUCHER',
        userRole: 'Owner',
        manufacturer: '',
        geofenceBreached: true,
        online: true,
      },
    ];
    this._vessels = vesselsData;
  };

  updateToggle = (toggle: keyof Vessel, state: boolean): void => {
    if (this._vessel) {
      if (toggle in this._vessel) {
        this._vessel = { ...this._vessel, [toggle]: state };
        DemoDatabase.getInstance().Vessel.update(this._vessel.id, this._vessel);
      }
    }
  };

  updateSubscriptionToggle = (toggle: string, state: boolean): void => {
    if (this._vessel && this._vessel.currentUserPerms) {
      this._vessel = {
        ...this._vessel,
        currentUserPerms: { ...this._vessel.currentUserPerms, [toggle]: state },
      };
      DemoDatabase.getInstance().Vessel.update(this._vessel.id, this._vessel);
    }
  };

  updateVessel = (data: Partial<Vessel>): void => {
    if (this._vessel) {
      const newVessel = { ...this._vessel };
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (key in newVessel) {
          //Sometimes the date adds an extra 'Z' to the end causing a crash.
          const isDate = key === 'handoverDate' || key === 'buildDate' || key === 'trialDate';
          if (isDate) {
            let value = data[key as keyof Vessel] as string;
            const lastTwo = value.slice(-2);
            if (lastTwo === 'ZZ') {
              value = value.slice(0, -1);
              // @ts-ignore
              newVessel[key as keyof Vessel] = value;
              return;
            }
          }
          // @ts-ignore
          newVessel[key as keyof Vessel] = data[key as keyof Vessel];
        }
      });
      this._vessel = newVessel;
      DemoDatabase.getInstance().Vessel.update(this._vessel.id, this._vessel);
    }
  };

  addNewNote = (note: Note): void => {
    this.notesController.addNewNote(note);
  };
  deleteNote = (id: number): void => {
    this.notesController.deleteNote(id);
  };

  retrievePage = async (data: GetVesselAuditPaginatedInterface) => {
    // this._time = this.calcDifference(data.dateFrom, data.dateTo);
    const logItems = await this.retrieveLogs();
    const logController = new LogsController();
    const page = logController.retrievePage(logItems.reverse(), data, 'timestamp') as VesselLog[];
    return logController.mergeAlertLogData(page, data);
  };

  createNewLog = (message: string, type?: string): void => {
    const newLog = {
      id: 1,
      objectId: '',
      objectName: 'System',
      messageType: type ?? 'SYSTEM',
      message: message,
      timestamp: new Date().toISOString(),
      location: '188.30.79.128',
    };
    this._logs = createNewItemWithCustomId(this._logs, newLog, 'id');
    DemoDatabase.getInstance().VesselLogs.add(this._logs[this._logs.length - 1]);
  };
}

export default DemoVesselController;
