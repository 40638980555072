import { DemoAdvancedDeviceCache } from 'demo/caches/AdvancedDeviceCache';
import { DemoAlertCache } from 'demo/caches/AlertCache';
import AdvancedDevice from 'demo/classes/AdvancedDevice';
import AdvancedDeviceProperty from 'demo/classes/AdvancedDeviceProperty';
import { checkPropertyAlert } from 'demo/controllers/alerts/alertsUtils';
import DemoNotificationsController from 'demo/controllers/notificationsController';
import { getDemoGraphData } from 'demo/data/graph';
import DemoDatabase from 'demo/persistentStorage';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
  databaseResponse,
} from 'demo/persistentStorage/database/tables';
import { DeviceAlertConfig } from 'demo/structs/alerts/DeviceAlertConfig';
import { updateItemInObject } from 'demo/utility';
import { DeviceProperty, Property, ValueHolder, VesselDevice } from 'store/selectedDevice/types';
import { DeviceBasicResponse, DeviceRequest } from 'types/devices';
import { DateFilters, ValueResponse } from 'types/graphs';
import { GroupItem } from 'types/groups';
import { AlertLog } from 'types/logs';
import { SimpleDeviceFieldWrapper } from 'types/simpleDevices';
import { v4 as uuidv4 } from 'uuid';
import { CalibrationConfig } from 'views/CalibrationsView';

import GudeController from './gudeController';

class DemoDevicesController {
  private _devices_db: AdvancedDevice[] = [];
  public deviceId: string | null = null;

  get devices(): AdvancedDevice[] {
    return this._devices_db;
  }

  retrieveDevice(deviceId: string): VesselDevice {
    this.deviceId = deviceId;
    return this.retrieveDeviceInfo(this.deviceId) as VesselDevice;
  }

  retrieveBasic = (): DeviceBasicResponse[] => {
    if (this._devices_db.length === 0) {
      this.databaseInit();
    }
    return this._devices_db.map(device => device.basicResponse());
  };

  retrieveDeviceInfo = (id: string): VesselDevice | null => {
    this.randomDeviceDbUpdate(id);
    const deviceFound = this._devices_db.find(device => device.id.toString() === id.toString());
    if (deviceFound) {
      return deviceFound.fullResponse();
    }
    return null;
  };

  retrieveDevicesFull = (): VesselDevice[] => {
    const devices = this.devices;
    return devices.map(device => {
      return device.fullResponse();
    });
  };

  addDevice = (newMonitoredDevice: DeviceRequest): void => {
    const newDevice: AdvancedDeviceTable = {
      bus: newMonitoredDevice.bus,
      cloudSync: true,
      controllable: true,
      userDefinedName: newMonitoredDevice.name,
      gatewayAddress: '',
      address: '',
      uid: uuidv4(),
    };
    DemoDatabase.getInstance()
      .AdvancedDevice.add(newDevice)
      .then(res => {
        this._devices_db.push(new AdvancedDevice({ id: Number(res), ...newDevice }));
        if (newMonitoredDevice.bus === 'GUDE') {
          const gudeController = new GudeController();
          const device = this._devices_db[this._devices_db.length - 1];
          const properties = gudeController.generateProperties(device.id);
          DemoDatabase.getInstance()
            .AdvancedDevicePropertyVh.addBatch(properties)
            .then(() => {
              properties.map(property =>
                this._devices_db[this._devices_db.length - 1].vhs.push(
                  // @ts-ignore
                  new AdvancedDeviceProperty(device.uid, property, device.id)
                )
              );
            });
        }
      })
      .catch(err => console.log(err));
  };

  deleteDevice = (id: string): void => {
    this._devices_db = this._devices_db.filter(device => Number(device.id) !== Number(id));
    DemoDatabase.getInstance()
      .AdvancedDevice.delete(Number(id))
      .catch(err => console.log(err));
  };

  updateDevice = async (deviceId: string, data: Partial<VesselDevice>): Promise<void> => {
    const deviceIndexFound = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndexFound === -1) {
      return;
    }
    const newDevice = updateItemInObject(
      this._devices_db[deviceIndexFound].deviceUpdateResponse(),
      data
    );
    if (newDevice) {
      await DemoDatabase.getInstance().AdvancedDevice.updatePartial(Number(deviceId), newDevice);
      this._devices_db[deviceIndexFound].mergeUpdate(newDevice);
    }
  };

  async retrieveAlertLogs(): Promise<AlertLog[]> {
    const newAlertLogs: AlertLog[] = [];
    const currentDevice = this.retrieveDeviceInfo(this.deviceId as string) as VesselDevice;
    if (!currentDevice) {
      return newAlertLogs;
    }
    const alertLogs = await DemoAlertCache.getInstance().retrieveAlertLogs();
    // @ts-ignore
    alertLogs.forEach((log: AlertLog & { alertId: string }) => {
      const id = log.alertId.substr(log.alertId.indexOf('_') + 1);
      if (currentDevice && !isNaN(Number(id))) {
        currentDevice.valueHolders.forEach(valueHolder => {
          if (valueHolder.id === Number(id)) {
            newAlertLogs.push(log);
          }
        });
      }
    });
    return newAlertLogs;
  }

  updateDeviceNotifications = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    this._devices_db[deviceIndex].deviceNotifications = state;
    DemoDatabase.getInstance()
      .AdvancedDevice.update(Number(deviceId), this._devices_db[deviceIndex].databaseStruct())
      .catch(err => console.log(err));
  };

  updateDeviceControllable = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    this._devices_db[deviceIndex].controllable = state;
    DemoDatabase.getInstance()
      .AdvancedDevice.update(Number(deviceId), this._devices_db[deviceIndex].databaseStruct())
      .catch(err => console.log(err));
  };

  updateDeviceSync = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    this._devices_db[deviceIndex].cloudSync = state;
    DemoDatabase.getInstance()
      .AdvancedDevice.update(Number(deviceId), this._devices_db[deviceIndex].databaseStruct())
      .catch(err => console.log(err));
  };

  updateVhValue = (devicePropertyId: number, state: boolean, key: keyof ValueHolder): void => {
    let deviceIndexFound: number | null = null;
    let vhIndexFound = -1;
    this._devices_db.forEach((device, deviceIndex) => {
      if (deviceIndexFound !== null) {
        return;
      }
      vhIndexFound = device.vhs.findIndex(vh => Number(vh.id) === devicePropertyId);
      if (vhIndexFound !== -1) {
        deviceIndexFound = deviceIndex;
        return;
      }
    });
    if (deviceIndexFound === null) {
      return;
    }
    // @ts-ignore
    this._devices_db[deviceIndexFound].vhs[vhIndexFound][key] = state;
    DemoDatabase.getInstance()
      .AdvancedDevicePropertyVh.update(
        Number(this._devices_db[deviceIndexFound].vhs[vhIndexFound].id),
        this._devices_db[deviceIndexFound].vhs[vhIndexFound].databaseStruct()
      )
      .catch(err => console.log(err));
  };

  updateDeviceProperty = async (
    deviceId: string,
    propertyId: number,
    data: Property
  ): Promise<void> => {
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    const propertyIndex = this._devices_db[deviceIndex].vhs.findIndex(
      vh => Number(vh.id) === propertyId
    );
    if (propertyIndex === -1) {
      return;
    }
    Object.assign(this._devices_db[deviceIndex].vhs[propertyIndex], {
      ...this._devices_db[deviceIndex].vhs[propertyIndex],
      ...data,
    });
    await DemoDatabase.getInstance().AdvancedDevicePropertyVh.update(
      Number(propertyId),
      this._devices_db[deviceIndex].vhs[propertyIndex].databaseStruct()
    );
  };

  addDeviceProperty = (deviceId: string, data: Property): void => {
    if (this._devices_db.length === 0) {
      return;
    }
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    let busPropFound: DeviceProperty | undefined = undefined;
    const busProps = this.retrieveBusProps(this._devices_db[deviceIndex].bus.bus);
    if (busProps.length > 0) {
      busPropFound = busProps.find(busProp => busProp.uid === data.propertyUid);
    }
    //@ts-ignore
    const vhData: AdvancedDevicesPropertyVh = {
      ...data,
      ...(busPropFound && {
        propertyName: busPropFound.name,
        category: busPropFound.category,
        dictionary: busPropFound.dictionary,
        rangeHigh: data.rangeHigh ? data.rangeHigh : busPropFound.maxVal,
        rangeLow: data.rangeHigh ? data.rangeLow : busPropFound.minVal,
        unit: busPropFound.unit,
      }),
      value: '0',
      deviceUid: '',
      deviceId: this._devices_db[deviceIndex].id,
    };
    DemoDatabase.getInstance()
      .AdvancedDevicePropertyVh.add(vhData)
      .then(res => {
        const newVh = new AdvancedDeviceProperty(this._devices_db[deviceIndex], vhData);
        newVh.id = Number(res);
        this._devices_db[deviceIndex].vhs.push(newVh);
      });
  };

  updateDevicePropertyValue = async (
    deviceProperty: number,
    value: number | string
  ): Promise<void> => {
    if (this._devices_db.length <= 0) {
      return;
    }
    for (let i = 0; i < this._devices_db.length; i++) {
      for (
        let valueHolderIndex = 0;
        valueHolderIndex < this._devices_db[i].vhs.length;
        valueHolderIndex++
      ) {
        if (this._devices_db[i].vhs[valueHolderIndex].id === deviceProperty) {
          await DemoDatabase.getInstance()
            .AdvancedDevicePropertyVh.updatePartial(deviceProperty, {
              value: value.toString(),
            })
            .catch(err => console.log(err));
          this._devices_db[i].vhs[valueHolderIndex].value = value.toString();
          //TODO: Check if this should be here since it is set bellow?
          break;
        }
      }
    }
  };

  setWarningLevels = (
    deviceId: string,
    payload: {
      propertyId: number;
      warnLevelLow: string;
      warnLevelHigh: string;
      criticalLevelLow: string;
      criticalLevelHigh: string;
      warnTextLow: string;
      warnTextHigh: string;
      criticalTextLow: string;
      criticalTextHigh: string;
      repeatOptions: string;
      instant: boolean;
      alertDelay: number;
      instantAlertLevel?: string;
    }
  ): void => {
    const deviceIndexFound = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndexFound === -1) {
      return;
    }
    const vhIndexFound = this._devices_db[deviceIndexFound].vhs.findIndex(
      vh => vh.id === payload.propertyId
    );
    if (vhIndexFound === -1) {
      return;
    }
    const vh = this._devices_db[deviceIndexFound].vhs[vhIndexFound];
    const type = checkPropertyAlert(payload, vh.value, vh.alertConfig !== null);
    if (type === 'new') {
      this._devices_db[deviceIndexFound].vhs[vhIndexFound].alertConfig = new DeviceAlertConfig(
        null,
        this._devices_db[deviceIndexFound],
        this._devices_db[deviceIndexFound].vhs[vhIndexFound],
        payload.instant,
        payload.warnLevelHigh,
        payload.warnLevelLow,
        payload.criticalLevelHigh,
        payload.criticalLevelLow
      );
    } else if (type === 'update') {
      vh.alertConfig!.updateAlertData(payload);
    }
    vh.alertConfig!.setConfirmedCriticality(vh.calculateCriticality());
    if (type === 'remove') {
      if (this._devices_db[deviceIndexFound].vhs[vhIndexFound].alertConfig !== null) {
        DemoDatabase.getInstance()
          .DeviceAlert.delete(
            Number(this._devices_db[deviceIndexFound].vhs[vhIndexFound].alertConfig!.id)
          )
          .then(res => console.log(res))
          .catch(err => console.log(err));
        vh.alertConfig = null;
      }
    } else {
      DemoNotificationsController.getInstance().createNotification(
        `Device ${vh.name}: ${vh.alertConfig!.state} Value Reached. Current Value: ${vh.value}${
          vh.unit
        }`
      );
      if (type === 'new') {
        DemoDatabase.getInstance()
          .DeviceAlert.add(
            this._devices_db[deviceIndexFound].vhs[vhIndexFound].alertConfig!.databaseResponse()
          )
          .then(res => console.log(res))
          .catch(err => console.log(err));
      } else {
        DemoDatabase.getInstance()
          .DeviceAlert.update(Number(vh.alertConfig!.id), vh.alertConfig!.databaseResponse())
          .then(res => console.log(res))
          .catch(err => console.log(err));
      }
    }
    DemoAlertCache.getInstance().updateDeviceAlert(type, vh.alertConfig, vh.id);
  };

  // checkVesselDevices = (): void => {
  //   const devicesMonitored = this._devices.length;
  //   let warningCount = 0;
  //   let criticalCount = 0;
  //   const newDevices: VesselDevice[] = [];
  //   this._devices.forEach(device => {
  //     if (device.state === 'WARNING') {
  //       warningCount++;
  //     }
  //     if (device.state === 'CRITICAL') {
  //       criticalCount++;
  //     }
  //     let alertLevel = 'NORMAL';
  //     device.valueHolders.forEach(vh => {
  //       if (alertLevel === 'CRITICAL') {
  //         return;
  //       }
  //       const vhState = vh.state;
  //       if (vhState === 'WARNING' && alertLevel === 'NORMAL') {
  //         alertLevel = 'WARNING';
  //       }
  //       if (vhState === 'CRITICAL' && (alertLevel === 'NORMAL' || alertLevel === 'WARNING')) {
  //         alertLevel = 'CRITICAL';
  //       }
  //     });
  //     newDevices.push({ ...device, state: alertLevel });
  //   });
  //   demoVesselController.updateVessel({
  //     numberOfMonitoredDevices: devicesMonitored,
  //     devicesOnline: devicesMonitored,
  //     criticalCount,
  //     warningCount,
  //   });
  //   if (this._devices.length === newDevices.length) {
  //     this._devices = newDevices;
  //     storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
  //   }
  // };

  deleteDeviceProperty = (deviceId: string, propertyId: number): void => {
    if (this._devices_db.length <= 0) {
      return;
    }
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    const propertyIndex = this._devices_db[deviceIndex].vhs.findIndex(vh => vh.id === propertyId);
    if (propertyIndex === -1) {
      return;
    }
    //TODO Check when virtual bus is added.
    DemoDatabase.getInstance()
      .AdvancedDevicePropertyVh.delete(propertyId)
      .catch(err => console.log(err));
    this._devices_db[deviceIndex].vhs.splice(propertyIndex, 1);
  };

  retrieveGroupByName = (name: string): GroupItem[] => {
    return DemoAdvancedDeviceCache.getInstance().retrieveGroupByName(name);
  };

  updateGroupName = (groupName: string, newGroupName: string): void => {
    DemoAdvancedDeviceCache.getInstance().updateGroupName(groupName, newGroupName);
  };

  retrieveBusProps = (id: string): DeviceProperty[] => {
    switch (id) {
      case 'MODBUSTCP':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require('demo/data/devices/properties/ModbusTCPProp.json') as DeviceProperty[];
      case 'MODBUSRTU':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require('demo/data/devices/properties/ModbusRTUProp.json') as DeviceProperty[];
      case 'KNX':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require('demo/data/devices/properties/knx.json') as DeviceProperty[];
      case 'VIRTUAL':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require('demo/data/devices/properties/virtual.json') as DeviceProperty[];
      default:
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require('demo/data/devices/properties/knx.json') as DeviceProperty[];
    }
  };

  databaseInit = (): void => {
    DemoDatabase.getInstance()
      .AdvancedDevice.getAll<databaseResponse<AdvancedDeviceTable>>()
      .then(tempDevices => {
        this._devices_db = Array.from(tempDevices, device => new AdvancedDevice(device));
      })
      .catch(err => console.log(err));
  };

  addNewCalibration = (data: CalibrationConfig): void => {
    DemoAdvancedDeviceCache.getInstance().addCalibration(data);
  };

  deleteCalibration = (id: number): void => {
    DemoAdvancedDeviceCache.getInstance().deleteCalibration(id);
  };

  retrieveGraphData = (propertyId: number, dateFrom: string, dateTo: string): ValueResponse[] => {
    const demoGraphController = new DemoGraphController();
    let vhFound: ValueHolder | undefined = undefined;
    this._devices_db.forEach(device => {
      if (vhFound) {
        return;
      }
      const temp = device.vhs.find(vh => vh.id === propertyId);
      vhFound = temp ? temp.fullResponse() : undefined;
    });
    if (!vhFound) {
      return [];
    }
    return demoGraphController.generateData(dateFrom, dateTo, vhFound);
  };

  randomDeviceDbUpdate = (deviceId: string): void => {
    /**
     * Randomly change the value of the fields by random values within 0->100 or min->max
     * to simulate live data.
     */
    const deviceIndex = this._devices_db.findIndex(device => device.id === Number(deviceId));
    if (deviceIndex === -1) {
      return;
    }
    this._devices_db[deviceIndex].randomUpdate();
  };
}

export default DemoDevicesController;

class DemoGraphController {
  generateData = (
    dateFrom: string,
    dateTo: string,
    device: SimpleDeviceFieldWrapper
  ): ValueResponse[] => {
    const dif: number = +new Date(dateTo) - +new Date(dateFrom);
    const hoursDifference = Math.floor(dif / 1000 / 60 / 60);
    let dateRange = 'LAST_HOUR';
    if (hoursDifference < 2) {
      dateRange = 'LAST_HOUR';
    } else if (hoursDifference <= 25) {
      dateRange = 'LAST_DAY';
    } else if (hoursDifference <= 146) {
      dateRange = 'LAST_7_DAYS';
    }
    const graphLogs = getDemoGraphData(dateRange as DateFilters);
    const timestamps = this.changeTimestamps(dateRange, graphLogs);
    const values = this.generateRandomData(device, graphLogs);
    const newLog: ValueResponse[] = [];
    for (let i = 0; i < timestamps.length - 1; i++) {
      newLog.push({
        average: values.values[i] ?? 0,
        max: values.minMax[i] ? values.minMax[i].max : 0,
        min: values.minMax[i] ? values.minMax[i].min : 0,
        timestamp: timestamps[i],
      });
    }
    return newLog;
  };

  generateRandomData = (
    data: SimpleDeviceFieldWrapper,
    logs: ValueResponse[]
  ): { values: number[]; minMax: { min: number; max: number }[] } => {
    const length = logs.length;
    let newValues = [];
    const newMinMax = [];

    const max = data.gaugeHigh ? data.gaugeHigh : Number(data.value) + 40;
    const step = (max - data.gaugeLow) / length;
    if (data.fieldName === 'chargeLevel') {
      const decrementalArray = Array.from(
        { length: (max - data.gaugeLow) / step + 1 },
        (_, i) => data.gaugeLow + i * step
      );
      newValues = decrementalArray.reverse();
      newValues.forEach(value => {
        const randMin = this.getRandom(value - 2, value, 2);
        const randMax = this.getRandom(value, value + 2, 2);
        newMinMax.push({ min: randMin, max: randMax });
      });
    } else {
      for (let i = 0; i < length; i++) {
        const randomNumber = this.getRandom(data.gaugeLow, max, 2);
        newValues.push(randomNumber);
        const randMin = this.getRandom(data.gaugeLow - 2, randomNumber - 0.5, 2);
        const randMax = this.getRandom(randomNumber + 0.5, max + 2, 2);
        newMinMax.push({ min: randMin, max: randMax });
      }
    }
    return { values: newValues, minMax: newMinMax };
  };

  changeTimestamps = (dateRange: string, logs: ValueResponse[]): string[] => {
    const length = logs.length;
    const newTimestamps: string[] = [];
    const currentTime = new Date();
    const firstTime = this.subtractTimeFromDate(
      currentTime,
      dateRange === 'LAST_HOUR' ? 1 : dateRange === 'LAST_DAY' ? 24 : 168
    );
    const difference = currentTime.valueOf() - firstTime.valueOf();
    const interval = difference / 1000 / 60 / length;

    newTimestamps.push(firstTime.toISOString());
    let currentNewVal = firstTime;
    for (let i = 0; i < length; i++) {
      currentNewVal = this.addMinutes(currentNewVal, interval);
      newTimestamps.push(currentNewVal.toISOString());
    }
    return newTimestamps;
  };

  subtractTimeFromDate = (objDate: Date, hours: number): Date => {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = hours * 60 * 60 * 1000;
    return new Date(numberOfMlSeconds - addMlSeconds);
  };

  addMinutes = (date: Date, minutes: number): Date => {
    return new Date(date.getTime() + minutes * 60000);
  };

  getRandom = (min: number, max: number, decimalPlaces: number): number => {
    const rand = Math.random() * (max - min) + min;
    const power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
  };
}
