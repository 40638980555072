import CrossImg from 'assets/images/cross.png';
import brandImage from 'assets/images/modal-top-image.jpg';
import link from 'assets/images/subscriptionTypeIcon/link-icon.png';
import TickImg from 'assets/images/tick.png';
import Button from 'components/Button';
import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { brandBlue, standardWhite } from 'styles/colors';

type Props = {
  handleClose: (close: boolean) => void;
  title?: string;
  success: boolean;
  msg?: string;
  auto?: boolean;
};

const Modal = (props: Props): ReactElement => {
  const { handleClose, title, success, msg, auto } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Main>
      <Card>
        <CardEssentials>
          <Image src={brandImage} />
          <ImageContainer>
            <ImagePadding>
              <Logo src={link} alt="" />
            </ImagePadding>
          </ImageContainer>
        </CardEssentials>
        <Form>
          {title ? <Title>{title}</Title> : null}
          <AlertTypeImage>
            <img src={success ? TickImg : CrossImg} alt="" />
          </AlertTypeImage>
          <AlertMessage>{msg ? msg : 'Something went wrong.'}</AlertMessage>
        </Form>
        {!auto && (
          <Button variant="info" onClick={() => handleClose(false)}>
            {success ? 'Continue' : 'Close'}
          </Button>
        )}
      </Card>
    </Main>
  );
};

export default Modal;

const Main = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const Card = styled.div`
  position: relative;
  width: 460px;
  min-width: 460px;
  height: fit-content;
  min-height: 460px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: ${standardWhite};
  border-radius: 10px;
  border: 1px solid #56565a;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  button {
    width: 418px;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 76px;
`;

const ImagePadding = styled.div`
  padding: 8px;
  border-radius: 50%;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  background: ${standardWhite};
  overflow: hidden;
`;

const Logo = styled.img`
  border-radius: 50%;
  background: skyblue;
  width: 100px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Form = styled.div`
  position: relative;
  padding: 0 20px 20px 20px;
  height: fit-content;
  min-height: 200px;
  padding-bottom: 80px;
`;

const CardEssentials = styled.div`
  position: relative;
  height: 200px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: ${brandBlue};
`;

const AlertTypeImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  img {
    max-width: 100px;
    height: auto;
  }
`;

const AlertMessage = styled.div`
  color: ${brandBlue};
  text-align: center;
  font-size: 20px;
`;
