import initMetaData from 'demo/data/simpleDevices/metaData.json';

import { DemoSimpleDevicesCache } from './caches/simpleDevices/SimpleDevicesCache';
import { DefaultDeviceAlertResponse } from './controllers/alerts/alertsUtils';
import { DemoDeviceGenerator } from './controllers/devices/advancedDevices/deviceGenerator';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
  databaseResponse,
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceMetaDataTable,
  SimpleDeviceTable,
} from './persistentStorage/database/tables';
import {
  generateHydraulicsAdvanced,
  generateHydraulicsSimple,
  generateMainSwitchboardAdvanced,
  generateMainSwitchboardSimple,
} from './utils/CustomDashboards';

export interface InitialDeviceI {
  name: string;
  type: string;
  deviceName: string;
  location?: string;
  fields: string[];
  valueHolders: string[];
}

export interface InitialValueHolderI {
  name: string;
  uid: string;
  value?: string;
  status?: {
    value: string;
    warnLow?: string;
    warnHigh?: string;
    critLow?: string;
    critHigh?: string;
  };
  gaugeLow?: number;
  gaugeHigh?: number;
  instance?: string;
  customDictionary?: string;
}

export const generateAdvancedDevicesRecords = (): {
  devices: AdvancedDeviceTable[];
  vhs: AdvancedDevicesPropertyVh[];
} => {
  const devices: {
    name: string;
    bus: string;
    valueHolders: InitialValueHolderI[];
    // eslint-disable-next-line @typescript-eslint/no-var-requires
  }[] = require('demo/data/devices/AdvancedDevices.json');
  const newDevices: AdvancedDeviceTable[] = [];
  const newVhs: AdvancedDevicesPropertyVh[] = [];
  let deviceId = 1;
  let valueHolderId = 1;
  devices.forEach(device => {
    const deviceGenerator = new DemoDeviceGenerator(
      device.name,
      device.bus,
      device.valueHolders,
      valueHolderId
    );
    const { device: newDevice, vhs } = deviceGenerator.generateDevicesAndVhs(deviceId);
    newVhs.push(...vhs);
    newDevices.push(newDevice);
    valueHolderId = valueHolderId + vhs.length;
    deviceId++;
  });
  const { device: mainSwitchboardDevice, vhs: mainSwitchboardVhs } =
    generateMainSwitchboardAdvanced(valueHolderId, deviceId);
  newVhs.push(...mainSwitchboardVhs);
  newDevices.push(mainSwitchboardDevice);
  valueHolderId = valueHolderId + mainSwitchboardVhs.length;
  deviceId++;

  const { device: newDevice, vhs } = generateHydraulicsAdvanced(valueHolderId, deviceId);
  newVhs.push(...vhs);
  newDevices.push(newDevice);
  return { devices: newDevices, vhs: newVhs };
};

export const generateSimpleDevicesForDb = (
  devices: databaseResponse<AdvancedDeviceTable>[],
  vhs: (AdvancedDevicesPropertyVh & { id: number })[]
): {
  simpleDevices: SimpleDeviceTable[];
  fields: SimpleDeviceFieldWrapperTable[];
  metaFields: SimpleDeviceMetaDataTable[];
} => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const initialDevices = require('demo/data/simpleDevices/initialDevices.json');
  const templates = DemoSimpleDevicesCache.getInstance().getTemplates();
  const simpleDevices: SimpleDeviceTable[] = [];
  const fields: SimpleDeviceFieldWrapperTable[] = [];
  const metaFields: SimpleDeviceMetaDataTable[] = [];
  let deviceId = 1;
  initialDevices.forEach((initialDevice: InitialDeviceI) => {
    const deviceFound = devices.find(device => device.userDefinedName === initialDevice.deviceName);
    if (!deviceFound) {
      return;
    }
    const deviceResponse = createSimpleDevice(templates, initialDevice);
    if (deviceResponse === null) {
      return;
    }
    simpleDevices.push(deviceResponse.device);
    const template = deviceResponse.template;
    const filteredVhs = vhs.filter(vh => vh.deviceId === Number(deviceFound.id));
    const [tempFields, tempMetaField] = createFields(
      filteredVhs,
      deviceId,
      template,
      initialDevice.valueHolders,
      initialDevice.deviceName
    );
    fields.push(...tempFields);
    metaFields.push(...tempMetaField);
    deviceId++;
  });
  const mainSwitchboardData = generateMainSwitchboardSimple(templates, devices, vhs, deviceId);
  if (mainSwitchboardData !== null) {
    const {
      device: mainSwitchboardDevice,
      fields: mainSwitchboardFields,
      metaData: mainSwitchboardMetaData,
    } = mainSwitchboardData;
    simpleDevices.push(mainSwitchboardDevice);
    fields.push(...mainSwitchboardFields);
    metaFields.push(...mainSwitchboardMetaData);
    deviceId++;
  }
  const hydraulicData = generateHydraulicsSimple(templates, devices, vhs, deviceId);
  if (hydraulicData !== null) {
    const {
      device: hydraulicDevice,
      fields: hydraulicFields,
      metaData: hydraulicMetaData,
    } = hydraulicData;
    simpleDevices.push(hydraulicDevice);
    fields.push(...hydraulicFields);
    metaFields.push(...hydraulicMetaData);
    deviceId++;
  }
  return { simpleDevices, fields, metaFields };
};

export const createSimpleDevice = (
  templates: any[],
  initialDevice: any
): { template: any; device: SimpleDeviceTable } | null => {
  const template = templates.find(
    (template: {
      name: string;
      system: string;
      subSystem: string;
      controllable: boolean;
      displayComponent: string;
      fields: [];
      id: number;
    }) =>
      template.name === initialDevice.type &&
      (initialDevice.system !== undefined ? initialDevice.system === template.system : true)
  );
  if (!template) {
    return null;
  }
  const location = initialDevice.location ?? 'Default Location';
  DemoSimpleDevicesCache.getInstance().createNewLocation(location);
  return {
    device: {
      controllable: template.controllable,
      displayComponent: template.displayComponent,
      location: location,
      name: initialDevice.name,
      subSystem: template.subSystem,
      system: template.system,
      manufacturer: '',
      model: '',
      showInDefaultInterface: true,
    },
    template: template,
  };
};

export const createFields = (
  vhs: (AdvancedDevicesPropertyVh & { id: number })[],
  simpleDeviceId: number,
  template: any,
  initialVhs: string[],
  deviceName: string
): [SimpleDeviceFieldWrapperTable[], SimpleDeviceMetaDataTable[]] => {
  const fields: SimpleDeviceFieldWrapperTable[] = [];
  const metaFields: SimpleDeviceMetaDataTable[] = [];
  template.fields.forEach((field, index) => {
    const vhInit = initialVhs[index];
    if (!vhInit) {
      return;
    }
    if (field.count && field.count > 0) {
      for (let i = 1; i <= field.count; i++) {
        const vhFound = vhs.find(vh => vh.name === field.name.replace('${index}', i));
        if (!vhFound) {
          return;
        }
        fields.push({
          controllable: field.controllable,
          fieldName: field.name.replace('${index}', i),
          fieldOrder: 10,
          fieldType: field.fieldType,
          includeInGauges:
            field.fieldType === 'M_ENGINE_SPEED' || template.system === 'CUSTOM_DASHBOARDS',
          deviceId: simpleDeviceId,
          dpvhId: vhFound.id.toString(), //TODO: Check if this should be number or string
        });
      }
    } else {
      const vhFound = vhs.find(vh => vh.name === vhInit);
      if (!vhFound) {
        return;
      }
      fields.push({
        controllable: field.controllable,
        fieldName: field.name,
        fieldOrder: 10,
        fieldType: field.fieldType,
        includeInGauges:
          field.fieldType === 'M_ENGINE_SPEED' || template.system === 'CUSTOM_DASHBOARDS',
        deviceId: simpleDeviceId,
        dpvhId: vhFound.id.toString(), //TODO: Check if this should be number or string
      });
    }
  });
  const metaDataFound = initMetaData.filter(
    initMetaDataItem => initMetaDataItem.deviceName === deviceName
  );
  metaDataFound.forEach(metaDataFoundItem => {
    metaFields.push({ key: metaDataFoundItem.key, value: metaDataFoundItem.value, simpleDeviceId });
  });
  return [fields, metaFields];
};

export const dashboardDataNormalise = (
  vhs: (AdvancedDevicesPropertyVh & { id: number })[],
  devices: any,
  initData: any[]
): [(AdvancedDevicesPropertyVh & { id: number })[], string[]] => {
  const vhsResponse: (AdvancedDevicesPropertyVh & { id: number })[] = [];
  const vhNames: string[] = [];
  // const metaData: string[] = [];
  initData.forEach(mainSwitchInitDevice => {
    const foundDevice = devices.find(
      device => device.userDefinedName === mainSwitchInitDevice.name
    );
    if (!foundDevice) {
      if (mainSwitchInitDevice.name === 'MetaData') {
        mainSwitchInitDevice.props.forEach(prop => {
          vhNames.push(prop.vh);
        });
      }
      return;
    }
    mainSwitchInitDevice.props.forEach(prop => {
      const vh = vhs.find(vh => vh.deviceId === foundDevice.id && vh.name === prop.vh);
      if (!vh) {
        return;
      }
      vhsResponse.push(vh);
      vhNames.push(prop.vh);
    });
  });
  return [vhsResponse, vhNames];
};

export const createDeviceAlertConfig = (
  deviceId: string,
  propertyId: number,
  state?: string,
  warnLow?: string,
  warnHigh?: string,
  critLow?: string,
  critHigh?: string
) => {
  return {
    ...DefaultDeviceAlertResponse,
    deviceId: deviceId,
    alertId: 'prop_' + propertyId,
    warnLevelHigh: warnHigh ?? 'NOT SET',
    warnLevelLow: warnLow ?? 'NOT SET',
    criticalLevelHigh: critHigh ?? 'NOT SET',
    criticalLevelLow: critLow ?? 'NOT SET',
    state: state ?? 'NORMAL',
  };
};
