import { StoreConfig } from 'demo/persistentStorage/database/tables';

import {
  getItems,
  getItemsWithNextId,
  retrieveArray,
  retrieveDefaultData,
} from '../Utils/StoreUtils';

export class LocalStorageStoreHandler {
  localStoreName: string;
  storeName: string;
  config: StoreConfig;
  constructor(storeName: string, config: StoreConfig) {
    this.storeName = storeName;
    this.config = config;
    this.localStoreName = 'demo-' + storeName;
  }

  //Read
  getAll<T>(): Promise<T[]> {
    return new Promise(resolve => {
      let allItems: T[] = [];
      const item = localStorage.getItem(this.localStoreName);
      if (item) {
        allItems = JSON.parse(item);
      }
      resolve(allItems);
    });
  }

  get<T>(key: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  getAllWithDefault<T>(passedDefault?: any[]): Promise<T[]> {
    return new Promise(async (resolve, reject) => {
      const array = retrieveArray<T>(this.localStoreName);
      if (array.length === 0) {
        if (passedDefault !== undefined) {
          this.addBatch(passedDefault);
          resolve(passedDefault);
          return;
        }
        const defaultArray = retrieveDefaultData<T>(this.storeName);
        this.addBatch(defaultArray);
        resolve(defaultArray);
        return;
      }
      resolve(array);
    });
  }

  getById<T>(id: string | number, key: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const array: T[] = retrieveArray(this.localStoreName);
      const itemsById: T[] = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          itemsById.push(array[i]);
        }
      }
      resolve(itemsById);
    });
  }

  where<T>(indexKey: string, value: string | number): Promise<T[]> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  count(): Promise<number> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  //Create

  addBatch<T>(records: T[]): Promise<IDBValidKey[]> {
    return new Promise((resolve, reject) => {
      let newItems = [];
      let nextId = 1;
      if (this.config.options && this.config.options.autoIncrement) {
        const data = getItemsWithNextId(this.localStoreName);
        newItems = data.items;
        nextId = data.nextId;
      } else {
        newItems = getItems(this.localStoreName);
      }
      const ids = [];
      for (let i = 0; i < records.length; i++) {
        newItems.push({ id: nextId, ...records[i] });
        ids.push(nextId);
        nextId++;
      }
      localStorage.setItem(this.localStoreName, JSON.stringify(newItems));
      resolve(ids);
    });
  }
  //
  add<T>(record: T): Promise<IDBValidKey> {
    return new Promise((resolve, reject) => {
      let newItems;
      const nextId = 1;
      if (this.config.options && this.config.options.autoIncrement) {
        const { items, nextId } = getItemsWithNextId(this.localStoreName);
        newItems = [...items, { ...record, id: nextId }];
      } else {
        const items = getItems(this.localStoreName);
        newItems = [...items, record];
      }
      localStorage.setItem(this.localStoreName, JSON.stringify(newItems));
      resolve(nextId);
    });
  }

  //Update
  update<T>(id: number | string, updatedRecord: T): Promise<string> {
    return new Promise((resolve, reject) => {
      const items = getItems(this.localStoreName);
      const updatedItems = items.map(item => (item.id === id ? updatedRecord : item));
      localStorage.setItem(this.localStoreName, JSON.stringify(updatedItems));
      resolve('Record updated successfully.');
    });
  }

  updatePartial<T>(id: number, updatedRecord: T): Promise<string> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  updatePartialBulk<T>(updatedRecord: T[]): Promise<string> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  // //Delete
  delete(id: number | string): Promise<string> {
    return new Promise((resolve, reject) => {
      const items = getItems(this.localStoreName);
      localStorage.setItem(
        this.localStoreName,
        JSON.stringify(items.filter(item => item.id !== id))
      );
    });
  }

  deleteWhere(indexKey: string, value: string | number): Promise<string> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  clear(): Promise<string> {
    return new Promise((resolve, reject) => {
      localStorage.setItem(this.localStoreName, '[]');
    });
  }
}
