import DemoAccessController from 'demo/controllers/accessController';
import DemoDatabase from 'demo/persistentStorage';
import {
  databaseResponse,
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceTable,
} from 'demo/persistentStorage/database/tables';
import { DeviceAlertConfig } from 'demo/structs/alerts/DeviceAlertConfig';
import { SimpleDeviceCategoryStatus, VesselSimpleDevicesSettings } from 'types/permissions';
import { SimpleDevice, SimpleDeviceFieldWrapper } from 'types/simpleDevices';

import { DemoAlertCache } from '../AlertCache';

export class DemoSettingsCache {
  private _settings: VesselSimpleDevicesSettings;

  private static instance: DemoSettingsCache | null = null;

  constructor() {
    this._settings = require('demo/data/simpleDevices/Settings.json');
  }

  public static getInstance(): DemoSettingsCache {
    if (this.instance === null) {
      this.instance = new DemoSettingsCache();
    }
    return this.instance;
  }

  retrieveSettingsResponse = async () => {
    if (!this._settings) {
      this._settings = require('demo/data/simpleDevices/Settings.json');
    }
    let highestState = 'NORMAL';

    const defaultInterface = (await DemoAccessController.getInstance().retrieveInterfaces()).find(
      interfaceItem => interfaceItem.id === 'guest'
    );

    if (!defaultInterface) {
      return this._settings as VesselSimpleDevicesSettings;
    }
    for (const category of defaultInterface.categories) {
      const name = category.name;
      let newName = name;
      if (name === 'Marine') {
        newName = 'maritime';
        const status = await this.checkStatusBySystem('MARITIME', category.allowed, true);
        if (highestState !== 'CRITICAL') {
          if (status === 'CRITICAL') {
            highestState = 'CRITICAL';
          } else if (status === 'WARNING' && highestState === 'NORMAL') {
            highestState = 'WARNING';
          }
        }
        this._settings = {
          ...(this._settings && this._settings),
          engineDashboard: status,
        };
      } else if (name === 'Tanks') {
        newName = 'Tank';
        const status = await this.checkStatusBySystem('TANK', category.allowed, true);
        if (highestState !== 'CRITICAL') {
          if (status === 'CRITICAL') {
            highestState = 'CRITICAL';
          } else if (status === 'WARNING' && highestState === 'NORMAL') {
            highestState = 'WARNING';
          }
        }
        this._settings = {
          ...(this._settings && this._settings),
          tankDashboard: status,
        };
      } else if (name === 'Engine') {
        newName = 'Engine';
        const status = await this.checkStatusBySystem('ENGINE', category.allowed, true);
        if (highestState !== 'CRITICAL') {
          if (status === 'CRITICAL') {
            highestState = 'CRITICAL';
          } else if (status === 'WARNING' && highestState === 'NORMAL') {
            highestState = 'WARNING';
          }
        }
        this._settings = {
          ...(this._settings && this._settings),
          engineDashboard: status,
        };
      } else if (name === 'Power') {
        newName = 'Power';
        const batteryStatus = await this.checkStatusBySystem(
          'POWER',
          category.allowed,
          true,
          'BATTERY'
        );
        const generatorStatus = await this.checkStatusBySystem(
          'POWER',
          category.allowed,
          true,
          'GENERATOR'
        );
        this._settings = {
          ...(this._settings && this._settings),
          generatorDashboard: generatorStatus,
          batteryDashboard: batteryStatus,
        };
      }
      const status = await this.checkStatusBySystem(newName.toUpperCase(), category.allowed);
      if (highestState !== 'CRITICAL') {
        if (status === 'CRITICAL') {
          highestState = 'CRITICAL';
        } else if (status === 'WARNING' && highestState === 'NORMAL') {
          highestState = 'WARNING';
        }
      }
      // @ts-ignore
      this._settings = {
        ...(this._settings ?? {}),
        [(newName.toLowerCase() + 'Status') as keyof VesselSimpleDevicesSettings]: status,
      };
    }
    //Check CUSTOM_DASHBOARDS
    const mainSwitchboardStatus = await this.checkStatusBySystem(
      'CUSTOM_DASHBOARDS',
      'ALL',
      true,
      'MAIN_SWITCHBOARD'
    );
    //Check HYDRAULICS
    const hydraulicDashboard = await this.checkStatusBySystem(
      'CUSTOM_DASHBOARDS',
      'ALL',
      true,
      'HYDRAULIC'
    );
    this._settings = {
      ...(this._settings && this._settings),
      mainSwitchboard: mainSwitchboardStatus,
      hydraulicDashboard,
      alertLevel: highestState,
    };
    return this._settings as VesselSimpleDevicesSettings;
  };

  checkStatusBySystem = async (
    system: string,
    allowed: string,
    gauge?: boolean,
    subSystem?: string
  ): Promise<SimpleDeviceCategoryStatus> => {
    let devices = (await DemoDatabase.getInstance().SimpleDevice.where<
      databaseResponse<SimpleDeviceTable>
    >('systemIndex', system)) as unknown as SimpleDevice[];
    for (let i = 0; i < devices.length; i++) {
      const fields =
        await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.where<SimpleDeviceFieldWrapperTable>(
          'deviceIdIndex',
          devices[i].id
        );
      devices[i].fields = (fields as unknown as SimpleDeviceFieldWrapper[]) ?? [];
    }
    if (!devices) {
      return 'DISABLED';
    }
    let status: SimpleDeviceCategoryStatus = 'DISABLED';
    if (allowed !== 'NONE') {
      status = 'NORMAL';
      if (devices) {
        // let devices = JSON.parse(systemDevices);
        if (subSystem !== undefined) {
          //TODO: Check if the device fields has assigned properties.
          devices = devices.filter((device: SimpleDevice) => device.subSystem === subSystem);
        }
        if (gauge) {
          const include = this.includeInGauges(devices);
          status = include ? 'NORMAL' : 'DISABLED';
          if (status !== 'DISABLED') {
            status = this.checkStatus(devices);
          }
        } else {
          status = this.checkStatus(devices);
        }
      }
    }

    return status;
  };

  includeInGauges = (devices: SimpleDevice[]): boolean => {
    return devices.some(device => device.fields.some(field => field.includeInGauges));
  };

  checkStatus = (devices: SimpleDevice[]): SimpleDeviceCategoryStatus => {
    let status: SimpleDeviceCategoryStatus = 'NORMAL';
    devices.forEach(device => {
      if (status === 'CRITICAL') {
        return status;
      }
      device.fields.forEach(field => {
        if (status === 'CRITICAL') {
          return status;
        }
        const alertConfig: DeviceAlertConfig | undefined = DemoAlertCache.getInstance()
          .retrieveDeviceAlerts()
          .find(
            propertyAlertConfig => propertyAlertConfig.alertId.substr(5) === field.dpvhId.toString()
          );
        if (alertConfig) {
          if (alertConfig.state === 'CRITICAL') {
            status = 'CRITICAL';
            return 'CRITICAL';
          } else if (alertConfig.state === 'WARNING') {
            status = 'WARNING';
          }
        }
      });
    });
    return status;
  };
}
