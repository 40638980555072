import { InitialValueHolderI } from 'demo/dataImporter';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
} from 'demo/persistentStorage/database/tables';
import { VesselDevice } from 'store/selectedDevice/types';

import { buses, generateValueHoldersForDb } from './devicesUtils';

export class DemoDeviceGenerator {
  private _name: string;
  private _bus: string;
  private _device: Partial<VesselDevice> | null = null;
  private _valueHolders: InitialValueHolderI[] = [];
  private vhId: number;
  constructor(
    name: string,
    bus: string,
    valueholders: InitialValueHolderI[],
    valueHolderId: number
  ) {
    this._name = name;
    this._bus = bus;
    this._valueHolders = valueholders;
    this.vhId = valueHolderId;
  }

  get device(): VesselDevice {
    return this._device as VesselDevice;
  }

  generateDevicesAndVhs(deviceId: number): {
    device: AdvancedDeviceTable;
    vhs: AdvancedDevicesPropertyVh[];
  } {
    const valueholders = generateValueHoldersForDb(
      deviceId,
      this._valueHolders,
      this._name,
      this._bus,
      this.vhId
    );

    const busFound = buses.find(bus => bus.bus === this._bus);
    const device: AdvancedDeviceTable = {
      address: '0',
      bus: busFound ? busFound.bus : this._bus,
      controllable: true,
      gatewayAddress: '',
      uid: '',
      userDefinedName: this._name,
      cloudSync: true,
    };
    return { device, vhs: valueholders };
  }
}
