import DemoDatabase from 'demo/persistentStorage';
import { createNewItemWithCustomId } from 'demo/utility';
import { Routine } from 'store/vesselRoutines/types';

type DemoRoutine = Routine & { paused: boolean };

class DemoRoutineController {
  private static instance: DemoRoutineController | null = null;
  private _routines: DemoRoutine[] = [];

  public static getInstance(): DemoRoutineController {
    if (this.instance === null) {
      this.instance = new DemoRoutineController();
    }
    return this.instance;
  }

  get routines(): DemoRoutine[] {
    return this._routines;
  }

  retrieveRoutine = async (): Promise<DemoRoutine[]> => {
    if (this._routines.length === 0) {
      this._routines = await DemoDatabase.getInstance().VesselRoutine.getAllWithDefault();
    }
    return this._routines;
  };

  addRoutine = (data: Routine): void => {
    const newData: DemoRoutine = { ...data, paused: false };
    const routineIndex = this._routines.findIndex(routine => routine.id === data.id);
    if (routineIndex === -1) {
      this._routines = createNewItemWithCustomId(this._routines, newData, 'id', true);
    } else {
      this._routines[routineIndex] = { ...data, paused: this._routines[routineIndex].paused };
    }
    DemoDatabase.getInstance().VesselRoutine.add(this._routines[this._routines.length - 1]);
  };

  deleteRoutine = (id: string): void => {
    this._routines = this._routines.filter(routine => routine.id !== id);
    DemoDatabase.getInstance().VesselRoutine.delete(id);
  };

  pauseRoutine = (routineId: string, state: boolean): void => {
    const routineIndex = this._routines.findIndex(routine => routine.id === routineId);
    if (routineIndex === -1) {
      return;
    }
    const newRoutines = [...this._routines];
    newRoutines[routineIndex] = { ...newRoutines[routineIndex], paused: state };
    this._routines = newRoutines;
    DemoDatabase.getInstance().VesselRoutine.update(
      this._routines[routineIndex].id as string,
      this._routines[routineIndex]
    );
  };
}

export default DemoRoutineController;
