import CAN_ATMOSPHERIC_PROPS from 'demo/data/devices/properties/can-atmospheric.json';
import CAN_ELECTRICAL_PROPS from 'demo/data/devices/properties/can-electrical.json';
import CAN_ENGINE_PROPS from 'demo/data/devices/properties/can-engine.json';
import CAN_FLUIDS_PROPS from 'demo/data/devices/properties/can-fluids.json';
import CAN_NAV_PROPS from 'demo/data/devices/properties/can-nav.json';
import GUDE_PROPS from 'demo/data/devices/properties/gude.json';
import KNX_PROPS from 'demo/data/devices/properties/knx.json';
import LINK_DEFAULT_PROPS from 'demo/data/devices/properties/link.json';
import { createDeviceAlertConfig, InitialValueHolderI } from 'demo/dataImporter';
import DemoDatabase from 'demo/persistentStorage';
import { AdvancedDevicesPropertyVh } from 'demo/persistentStorage/database/tables';
import { demoDevicesController } from 'demo/provider';
import { Group } from 'types/groups';

export type DefaultPropType = {
  name: string;
  uid: string;
  dictionary: Record<string, string>;
  instantReport: boolean;
  controllable: boolean;
  loggingByDefault: boolean;
  category: string;
  alternativeName: string;
  activeByDefault: boolean;
  includeInSummaryByDefault: boolean;
  minVal?: number;
  maxVal?: number;
  unit: string;
};

export type CustomDict = {
  name: string;
  description: string;
  valuePairs: {
    value: string;
    displayValue: string;
  }[];
};

export type DemoGroup = Group & { ids: { device: string; property: number }[] };

export const buses = [
  { bus: 'MODBUSTCP', source: 'Modbus TCP', manualAdd: true, supportsPropertyAdd: true },
  { bus: 'MODBUSRTU', source: 'Modbus RTU', manualAdd: true, supportsPropertyAdd: true },
  { bus: 'KNX', source: 'KNX', manualAdd: true, supportsPropertyAdd: true },
  { bus: 'GUDE', source: 'Gude Power Management', manualAdd: true, supportsPropertyAdd: false },
  { bus: 'CAN1', source: 'Can Port 1', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'CAN2', source: 'Can Port 2', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'SYSTEM', source: 'LINK Devices', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'N0183', source: 'NMEA 0183', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'NETWORK', source: 'Network Devices', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'CZONE', source: 'CZone', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'DOMETIC', source: 'Dometic', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'NETWORK', source: 'Network Devices', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'BYME', source: 'Vimar By-Me', manualAdd: false, supportsPropertyAdd: false },
  { bus: 'VIRTUAL', source: 'Virtual', manualAdd: true, supportsPropertyAdd: true },
];

export const generateValueHoldersForDb = (
  deviceId: number,
  valueHolders: InitialValueHolderI[],
  deviceName: string,
  busName: string,
  valueHolderId: number
): AdvancedDevicesPropertyVh[] => {
  const newValueHolders: AdvancedDevicesPropertyVh[] = [];
  valueHolders.forEach(valueHolder => {
    let property: DefaultPropType | undefined;
    if (deviceName === 'LINKbridge') {
      // @ts-ignore
      property = LINK_DEFAULT_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
    } else {
      switch (busName) {
        case 'GUDE':
          // @ts-ignore
          property = GUDE_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
        case 'KNX':
          // @ts-ignore
          property = KNX_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
        case 'CAN2':
          const props = [
            ...CAN_ENGINE_PROPS,
            ...CAN_ELECTRICAL_PROPS,
            ...CAN_ATMOSPHERIC_PROPS,
            ...CAN_FLUIDS_PROPS,
            ...CAN_NAV_PROPS,
            ...KNX_PROPS, //Uses KNXBINARYSWITCH-1
          ];
          // @ts-ignore
          property = props.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
      }
    }
    if (valueHolder.status) {
      let status = 'NORMAL';
      if (valueHolder.status.value === 'CRITICAL' && status !== 'CRITICAL') {
        status = 'CRITICAL';
      } else if (valueHolder.status.value === 'WARNING' && status === 'NORMAL') {
        status = 'WARNING';
      }
      const alertConfig = createDeviceAlertConfig(
        deviceId.toString(),
        valueHolderId,
        status,
        valueHolder.status.warnLow ?? 'NOT SET',
        valueHolder.status.warnHigh ?? 'NOT SET',
        valueHolder.status.critLow ?? 'NOT SET',
        valueHolder.status.critHigh ?? 'NOT SET'
      );
      DemoDatabase.getInstance().DeviceAlert.add(alertConfig);
      // generateValueHoldersAlertConfig(deviceId, valueHolderId, status, valueHolder.status);
    }
    newValueHolders.push({
      category: property ? property.category : 'Light',
      controllable: property ? property.controllable : false,
      value: valueHolder.value ?? '0',
      deviceGroup: '',
      deviceUid: '',
      gaugeHigh: valueHolder.gaugeHigh ?? 0.0,
      gaugeLow: valueHolder.gaugeLow ?? 0.0,
      includeInSeaReady: true,
      instance: valueHolder.instance ? valueHolder.instance : '0',
      instantReport: true,
      invert: false,
      loggingEnabled: property ? property.loggingByDefault : false,
      multiplier: 1,
      name: valueHolder.name,
      propertyName: property ? property.name : 'DPT_FluidLevel (1.001)',
      propertyUid: property ? property.uid : 'FLUID-LEVEL-1',
      rangeHigh: property ? property.maxVal ?? 0.0 : 0,
      rangeLow: property ? property.minVal ?? 0.0 : 0,
      unit: property ? property.unit : '',
      deviceId: deviceId,
      includeInSummary: property ? property.includeInSummaryByDefault : false,
      active: property ? property.activeByDefault : false,
      dictionary: property ? property.dictionary : {},
      customDictionary: valueHolder.customDictionary ?? null,
    });
  });
  return newValueHolders;
};

export const setupGroups = (): DemoGroup[] => {
  if (demoDevicesController.devices.length === 0) {
    return [];
  }
  const groupDeviceId = demoDevicesController.devices[0].id;
  const groupPropIds = [
    demoDevicesController.devices[0].vhs[0].id,
    demoDevicesController.devices[0].vhs[1].id,
    demoDevicesController.devices[0].vhs[2].id,
  ];
  return [
    {
      ids: [
        { device: groupDeviceId.toString(), property: groupPropIds[0] },
        { device: groupDeviceId.toString(), property: groupPropIds[1] },
        { device: groupDeviceId.toString(), property: groupPropIds[2] },
      ],
      groupName: 'Batteries',
      groupSize: 3,
      groupState: 'NORMAL',
    },
  ];
};
