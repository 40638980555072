import { Alerts } from 'store/selectedVessel/types';

export class DemoGeofenceAlert {
  id: number | null = null;
  name = '';
  message = '';
  alertLevel = 'NORMAL';
  repeatOptions = 'NEVER';
  active = true;
  lastTriggered = '';
  lastTriggeredValue = '';
  acknowledged = false;
  silenced = false;
  ackUsername = '';
  ackTimestamp = '';
  silenceUsername = '';
  silenceUserId = '';
  silenceTimestamp = '';
  paused = false;
  notifyUsers: string[] = [];
  currentValue = '';

  constructor(
    payload: {
      id: number;
      name: string;
      message: string;
      alertLevel: string;
      notifyUsers: string[];
    } & { [key: string]: any }
  ) {
    this.name = payload.name;
    this.message = payload.message;
    this.alertLevel = payload.alertLevel;
    this.notifyUsers = payload.notifyUsers;
    this.setIfExists('id', payload.id);
    if (Object.keys(payload).length > 8) {
      this.setIfExists('ackTimestamp', payload.ackTimestamp);
      this.setIfExists('ackUsername', payload.ackUsername);
      this.setIfExists('acknowledged', payload.acknowledged);
      this.setIfExists('lastTriggered', payload.lastTriggered);
      this.setIfExists('lastTriggeredValue', payload.lastTriggeredValue);
      this.setIfExists('paused', payload.paused);
      this.setIfExists('silenceTimestamp', payload.silenceTimestamp);
      this.setIfExists('silenceUserId', payload.silenceUserId);
      this.setIfExists('silenceUsername', payload.silenceUsername);
      this.setIfExists('silenced', payload.silenced);
      this.setIfExists('currentValue', payload.currentValue);
    }
  }

  setIfExists(key: keyof this, value?: any): void {
    if (value !== undefined) {
      this[key] = value;
    }
  }

  silenceAlert(): void {
    this.silenced = true;
    this.silenceTimestamp = new Date().toISOString();
    this.silenceUsername = 'Demo User';
  }

  ackAlert(): void {
    this.acknowledged = true;
    this.ackTimestamp = new Date().toISOString();
    this.ackUsername = 'Demo User';
  }

  response(): Alerts {
    return {
      alertType: 'GEOFENCE',
      state: this.alertLevel,
      id: 'GEO_' + 123,
      name: this.name ? this.name : '',
      message: this.message,
      currentValue: this.currentValue,
      lastTriggered: new Date().toISOString(),
      acknowledged: this.acknowledged,
      silenced: this.silenced,
      ackTimestamp: this.ackTimestamp,
      ackUsername: this.ackUsername,
      active: true,
      deviceId: 0,
      lastTriggeredValue: this.lastTriggeredValue,
      paused: false,
    };
  }

  databaseResponse() {
    return {
      state: this.alertLevel,
      id: this.id,
      name: this.name,
      message: this.message,
      currentValue: this.currentValue,
      acknowledged: this.acknowledged,
      silenced: this.silenced,
      ackTimestamp: this.ackTimestamp,
      ackUsername: this.ackUsername,
      lastTriggeredValue: this.lastTriggeredValue,
    };
  }
}
