import React, { createContext, useState } from 'react';

interface MobileWarningContextInterface {
  hasContinued: boolean;
  setHasContinued: () => void;
}

const MobileWarningContext = createContext<MobileWarningContextInterface>({
  hasContinued: false,
  setHasContinued: () => {
    return;
  },
});

const MobileWarningContextProvider = (props: { children: JSX.Element }) => {
  const [hasContinued, setContinued] = useState(false);

  const setHasContinued = () => {
    setContinued(true);
  };

  return (
    <MobileWarningContext.Provider value={{ hasContinued, setHasContinued }}>
      {props.children}
    </MobileWarningContext.Provider>
  );
};

export { MobileWarningContext, MobileWarningContextProvider };
