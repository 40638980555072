// @ts-ignore
import classNames from 'classnames';
import React from 'react';

import styles from './Select.module.scss';

type Props = { children: any; loading?: boolean } & React.ComponentProps<'select'>;

const Select = React.forwardRef((props: Props, ref: React.Ref<HTMLSelectElement>) => {
  // eslint-disable-next-line react/prop-types
  const { children, className, loading, ...selectProps } = props;

  if (loading)
    return <div className={classNames([styles.linkSelect, styles.linkSelectLoader])}></div>;
  else
    return (
      <select className={classNames([styles.linkSelect, className])} {...selectProps} ref={ref}>
        {children}
      </select>
    );
});

export default Select;
