import { MyResponse, requestMiddleware } from './request';

export async function getAllCountries(): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(require('demo/data/Countries.json'));
}

export async function getLanguageOptions(): Promise<MyResponse> {
  return requestMiddleware([]);
}

export async function getApiVersion(): Promise<MyResponse> {
  return requestMiddleware({ version: '1.3.2', commitId: '', buildDate: '' });
}
