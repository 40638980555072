import { DemoSimpleDevicesCache } from 'demo/caches/simpleDevices/SimpleDevicesCache';
import DemoDatabase from 'demo/persistentStorage';
import {
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceTable,
} from 'demo/persistentStorage/database/tables';
import { SimpleDeviceFieldWrapper } from 'types/simpleDevices';

const defaultSimpleDeviceFieldWrapper = {
  controllable: false,
  criticalLevelHigh: 'NOT SET',
  criticalLevelLow: 'NOT SET',
  dictionary: {},
  displayName: '',
  displayValue: null,
  dpvhId: 0,
  deviceOnline: true,
  gaugeHigh: 0,
  gaugeLow: 0,
  id: 0,
  includeInGauges: false,
  lastSeen: '',
  loggable: false,
  name: '',
  validData: '',
  warnLevelHigh: 'NOT SET',
  warnLevelLow: 'NOT SET',
  warningState: 'NORMAL',
};

export const generateSimpleDevices = (name: string, type: string, location: string): void => {
  const generator = new SimpleDeviceGenerator(name, type, location);
  DemoDatabase.getInstance()
    .SimpleDevice.add(generator.device)
    .then(res => {
      const fields = generator.fields.map(field => ({
        ...field,
        deviceId: res,
      }));
      DemoDatabase.getInstance()
        .SimpleDevicesFieldWrapper.addBatch(fields)
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
  DemoSimpleDevicesCache.getInstance().createNewLocation(generator.device.location);
};

export const mapProperty = async (fieldId: number, propertyId: number | string): Promise<void> => {
  await DemoDatabase.getInstance()
    .SimpleDevicesFieldWrapper.updatePartial(fieldId, {
      dpvhId: propertyId.toString(),
    })
    .catch(err => console.log(err));
};

class SimpleDeviceGenerator {
  private _device: SimpleDeviceTable | null = null;
  private _fields: SimpleDeviceFieldWrapperTable[] = [];
  private templates = DemoSimpleDevicesCache.getInstance().getTemplates();
  constructor(name: string, type: string, location: string) {
    const templateFound = this.templates.find((template: any) => template.id === parseInt(type));
    let system = 'POWER';
    let subSystem = 'BATTERY';
    let controllable = true;
    let displayComponent = 'battery';
    let fields: SimpleDeviceFieldWrapperTable[] = [];
    if (templateFound) {
      system = templateFound.system;
      subSystem = templateFound.subSystem;
      controllable = templateFound.controllable;
      displayComponent = templateFound.displayComponent;
      if (templateFound.name === 'Hydraulic') {
        templateFound.fields.forEach(
          (field: Partial<SimpleDeviceFieldWrapper> & { count: number }) => {
            if (!field.count) {
              fields.push({
                dpvhId: '',
                controllable: true,
                fieldName: field.name!,
                fieldType: field.fieldType!,
                fieldOrder: 0,
                includeInGauges: true,
                deviceId: -1,
              });
            }
            for (let i = 0; i < field.count; i++) {
              fields.push({
                dpvhId: '',
                controllable: true,
                fieldName: field.name!.replace('${index}', i.toString()),
                fieldType: field.fieldType!,
                fieldOrder: 0,
                includeInGauges: true,
                deviceId: -1,
              });
            }
          }
        );
      } else {
        fields = templateFound.fields.map((field: Partial<SimpleDeviceFieldWrapper>) => {
          return {
            dpvhId: '',
            fieldName: field.name,
            fieldType: field.fieldType,
            fieldOrder: 0,
            includeInGauges: field.fieldType === 'M_ENGINE_SPEED' || system === 'CUSTOM_DASHBOARDS',
            controllable: true,
            deviceId: '',
          };
        });
      }
    }
    this._device = {
      name: name,
      manufacturer: '',
      model: '',
      system: system,
      subSystem: subSystem,
      controllable: controllable,
      displayComponent: displayComponent,
      location: location,
      showInDefaultInterface: true,
    };
    this._fields = fields;
  }

  get device(): SimpleDeviceTable {
    return this._device as SimpleDeviceTable;
  }
  get fields(): SimpleDeviceFieldWrapperTable[] {
    return this._fields as SimpleDeviceFieldWrapperTable[];
  }
}

export const SimpleDeviceResponse = (
  field: Partial<SimpleDeviceFieldWrapper>
): SimpleDeviceFieldWrapper => {
  return {
    ...defaultSimpleDeviceFieldWrapper,
    ...field,
  } as SimpleDeviceFieldWrapper;
};
