import DemoAdvancedDevice from 'demo/classes/AdvancedDevice';
import DemoAdvancedDeviceProperty from 'demo/classes/AdvancedDeviceProperty';
import { DefaultAlertsResponse } from 'demo/controllers/alerts/alertsUtils';
import { DeviceAlertTable } from 'demo/persistentStorage/database/tables';
import { demoDevicesController } from 'demo/provider';
import { Alerts } from 'store/selectedVessel/types';

export class DeviceAlertConfig {
  id: number | null;
  deviceId: number;
  name: string;
  alertId: string;
  instant = false;
  warnLevelHigh: string;
  warnLevelLow: string;
  criticalLevelHigh: string;
  criticalLevelLow: string;
  warnTextHigh?: string;
  warnTextLow?: string;
  criticalTextHigh?: string;
  criticalTextLow?: string;
  state = 'NORMAL';
  lastTriggered: string;
  alertDelayEnable = false;
  alertDelayMs = 0;
  repeatOptions = 'NEVER';
  instantAlertLevel = 'NORMAL';
  alertType = 'PROPERTY';
  currentValue = '';

  warnLevelLowEnable = false;
  warnLevelHighEnable = false;
  criticalLevelLowEnable = false;
  criticalLevelHighEnable = false;
  confirmedCriticality = 'NORMAL';

  constructor(
    id: number | null,
    device: DemoAdvancedDevice,
    property: DemoAdvancedDeviceProperty,
    instant: boolean,
    warnLevelHigh: string,
    warnLevelLow: string,
    criticalLevelHigh: string,
    criticalLevelLow: string
  ) {
    this.deviceId = device.id;
    this.alertId = 'prop_' + property.id;
    this.id = id;
    this.name = device.name + ': ' + property.name;
    this.instant = instant;
    this.warnLevelHigh = warnLevelHigh;
    this.warnLevelLow = warnLevelLow;
    this.criticalLevelHigh = criticalLevelHigh;
    this.criticalLevelLow = criticalLevelLow;
    this.currentValue = property.getParsedValue();

    this.warnLevelLowEnable = warnLevelLow !== 'NOT SET';
    this.warnLevelHighEnable = warnLevelHigh !== 'NOT SET';
    this.criticalLevelLowEnable = criticalLevelLow !== 'NOT SET';
    this.criticalLevelHighEnable = criticalLevelHigh !== 'NOT SET';
    this.lastTriggered = new Date().toISOString();
  }

  getState(): string {
    this.state = this.criticalityToAlert(this.confirmedCriticality);
    return this.state;
  }

  setConfirmedCriticality(confirmedCriticality: string): void {
    this.confirmedCriticality = confirmedCriticality;
    this.state = this.criticalityToAlert(confirmedCriticality);
  }

  getAlertMessage(): string {
    switch (this.confirmedCriticality) {
      case 'CRITICAL_LOW':
        if (null != this.criticalTextLow && this.criticalTextLow !== '') {
          return this.criticalTextLow;
        } else {
          return 'Low Critical Value Reached';
        }
      case 'WARNING_LOW':
        if (null != this.warnTextLow && this.warnTextLow !== '') {
          return this.warnTextLow;
        } else {
          return 'Low Warning Value Reached';
        }
      case 'INFO_LOW':
      case 'NORMAL':
      case 'INFO_HIGH':
        return 'Not In Alert';
      case 'WARNING_HIGH':
        if (null != this.warnTextHigh && this.warnTextHigh !== '') {
          return this.warnTextHigh;
        } else {
          return 'High Warning Value Reached';
        }
      case 'CRITICAL_HIGH':
        if (null != this.criticalTextHigh && this.criticalTextHigh !== '') {
          return this.criticalTextHigh;
        } else {
          return 'High Critical Value Reached';
        }
    }
    return 'Not in Alert';
  }

  criticalityToAlert(criticality: string): string {
    switch (criticality) {
      case 'CRITICAL_LOW':
      case 'CRITICAL_HIGH':
      case 'INSTANT_CRITICAL':
        return 'CRITICAL';
      case 'WARNING_LOW':
      case 'WARNING_HIGH':
      case 'INSTANT_WARN':
        return 'WARNING';
      case 'INFO_LOW':
      case 'NORMAL':
      case 'INFO_HIGH':
        return 'NORMAL';
      default:
        return 'NORMAL';
    }
  }

  updateAlertData = (payload: any) => {
    this.warnLevelHigh = payload.warnLevelHigh;
    this.warnLevelLow = payload.warnLevelLow;
    this.criticalLevelHigh = payload.criticalLevelHigh;
    this.criticalLevelLow = payload.criticalLevelLow;
    this.instant = payload.instant;
  };

  deviceResponse() {
    return {
      active: true,
      hasAlertConfig: true,
      lastTriggeredValue: this.lastTriggered,
      paused: false,
      alertType: this.alertType,
      state: this.state,
      message: this.getAlertMessage(),
      lastTriggered: new Date().toISOString(),
      acknowledged: false,
      ackUsername: '',
      ackTimestamp: '',
      silenced: false,
      silencedUsername: '',
      silenceTimestamp: '',
      warnLevelHigh: this.warnLevelHigh,
      warnLevelLow: this.warnLevelLow,
      criticalLevelHigh: this.criticalLevelHigh,
      criticalLevelLow: this.criticalLevelLow,
      alertDelayMs: this.alertDelayMs,
    };
  }

  databaseResponse(): DeviceAlertTable {
    return {
      alertId: this.alertId,
      deviceId: this.deviceId.toString(),
      instant: this.instant,
      state: this.state,
      alertDelayMs: this.alertDelayMs,
      warnLevelHigh: this.warnLevelHigh,
      warnLevelLow: this.warnLevelLow,
      criticalLevelHigh: this.criticalLevelHigh,
      criticalLevelLow: this.criticalLevelLow,
      alertDelayEnable: this.alertDelayEnable,
      alertMessage: this.getAlertMessage(), //Might not be needed
      repeatOptions: this.repeatOptions,
      instantAlertLevel: this.instantAlertLevel,
    };
  }

  alertResponse(): Alerts {
    const device = demoDevicesController.devices.find(
      device => device.id === Number(this.deviceId)
    );
    const propertyId = this.alertId.substr(5);
    if (isNaN(Number(propertyId))) {
      return DefaultAlertsResponse;
    }
    const property = device?.vhs.find(vh => vh.id === Number(propertyId));
    return {
      alertType: 'PROPERTY',
      state: this.state,
      id: this.alertId,
      name: property ? property.name : '',
      message: this.getAlertMessage(),
      currentValue: property ? property.getParsedValue() : '0',
      lastTriggered: new Date().toISOString(),
      acknowledged: false,
      ackUsername: '',
      ackTimestamp: '',
      deviceId: Number(this.deviceId) ?? 1,
      silenced: false,
      // @ts-ignore
      silenceUsername: '',
      silenceTimestamp: '',
    };
  }
}
