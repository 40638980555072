import request, { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getAllDealers(): Promise<MyResponse> {
  return requestMiddleware([]);
}

export async function postCreateRelationship(companyId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/relationships/create?companyId=${companyId}`,
    method: 'POST',
  };

  const response = await request(config);
  return response;
}

export async function postRemoveRelationship(relationshipId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/relationships/${relationshipId}/remove`,
    method: 'DELETE',
  };

  const response = await request(config);
  return response;
}
