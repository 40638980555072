import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getAllManufacturers(): Promise<MyResponse> {
  return requestMiddleware([]);
}

export async function getAllBuses(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/dataUtils?action=getAllBuses',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllDeviceManufacturers(): Promise<MyResponse> {
  return requestMiddleware([
    { name: 'Actia', uid: '199' },
    { name: 'Actisense', uid: '273' },
    { name: 'Advansea', uid: '578' },
    { name: 'AEM Power', uid: '735' },
    { name: 'Airmar', uid: '135' },
    { name: 'Alltek Marine', uid: '459' },
    { name: 'Amphenol LTW Technology', uid: '274' },
    { name: 'Aquatic AV', uid: '600' },
    { name: 'Arlt Tecnologies', uid: '614' },
    { name: 'ASA Electronics', uid: '905' },
    { name: 'Attwood Marine', uid: '502' },
    { name: 'Autonnic', uid: '715' },
    { name: 'Aventics GmbH', uid: '605' },
    { name: 'B & G ', uid: '381' },
    { name: 'Bavaria Yacts', uid: '637' },
    { name: 'Beede Instruments', uid: '185' },
    { name: 'BEP Marine', uid: '295' },
    { name: 'BEP Marine', uid: '116' },
    { name: 'Beyond Measure', uid: '396' },
    { name: 'BJ Technologies (Beneteau)', uid: '802' },
    { name: 'Blue Sea', uid: '969' },
    { name: 'Blue Water Data', uid: '148' },
    { name: 'Blue Water Desalination', uid: '811' },
    { name: 'Boatrax', uid: '1059' },
    { name: 'Böning Automationstechnologie GmbH & Co. KG', uid: '341' },
    { name: 'Broyda Industries', uid: '795' },
    { name: 'CALYPSO Instruments', uid: '1065' },
    { name: 'Canadian Automotive', uid: '796' },
    { name: 'Capi 2', uid: '394' },
    { name: 'Chetco Digitial Instruments', uid: '481' },
    { name: 'Clarion US', uid: '773' },
    { name: 'Coelmo SRL Italy', uid: '286' },
    { name: 'Comar Systems Limited', uid: '438' },
    { name: 'ComNav', uid: '404' },
    { name: 'Cox Powertrain', uid: '968' },
    { name: 'CPAC Systems AB', uid: '165' },
    { name: 'Cummins', uid: '440' },
    { name: 'DaeMyung', uid: '743' },
    { name: 'Data Panel Corp', uid: '868' },
    { name: 'Dief', uid: '329' },
    { name: 'Digital Yacht', uid: '437' },
    { name: 'DisenosY Technolgia', uid: '201' },
    { name: 'Diverse Yacht Services', uid: '641' },
    { name: 'DNA Group', uid: '211' },
    { name: 'Ecotronix', uid: '930' },
    { name: 'Egersund Marine Electronics AS', uid: '426' },
    { name: 'Electronic Design', uid: '373' },
    { name: 'EMMI NETWORK S.L.', uid: '224' },
    { name: 'Empirebus', uid: '304' },
    { name: 'em-trak Marine Electronics', uid: '427' },
    { name: 'Entratech Systems LLC', uid: '1021' },
    { name: 'Eride', uid: '243' },
    { name: 'E-T-A Circuit Breakers', uid: '1088' },
    { name: 'Evinrude/BRP', uid: '163' },
    { name: 'Faria Instruments', uid: '1863' },
    { name: 'Fell Marine', uid: '844' },
    { name: 'Fischer Panda DE', uid: '785' },
    { name: 'Fischer Panda Generators', uid: '356' },
    { name: 'FLIR', uid: '815' },
    { name: 'Floscan Instrument Co. Inc.', uid: '192' },
    { name: 'Furuno', uid: '1855' },
    { name: 'Fusion Electronics', uid: '419' },
    { name: 'FW Murphy/Enovation Controls', uid: '78' },
    { name: 'Garmin', uid: '229' },
    { name: 'Garmin', uid: '645' },
    { name: 'GeoNav', uid: '385' },
    { name: 'Gill Sensors', uid: '803' },
    { name: 'Glendinning', uid: '378' },
    { name: 'GME aka Standard Communications Pty LTD', uid: '475' },
    { name: 'Groco', uid: '272' },
    { name: 'Hamilton Jet', uid: '283' },
    { name: 'Hemisphere GPS Inc', uid: '88' },
    { name: 'HMI Systems', uid: '776' },
    { name: 'Honda Motor Company LTD', uid: '257' },
    { name: 'Humminbird Marine Electronics', uid: '476' },
    { name: 'Humminbird Marine Electronics', uid: '467' },
    { name: 'ICOM', uid: '315' },
    { name: 'Intellian', uid: '606' },
    { name: 'ITC Inc.', uid: '1022' },
    { name: 'Japan Radio Co', uid: '1853' },
    { name: 'JL Audio', uid: '704' },
    { name: 'Kobelt Manufacturing Co. Ltd', uid: '981' },
    { name: 'Kohler Power Systems', uid: '85' },
    { name: 'Korean Maritime University', uid: '345' },
    { name: 'Kvasar AB', uid: '1859' },
    { name: 'KVH', uid: '579' },
    { name: 'L3 Technologies', uid: '890' },
    { name: 'Lcj Capteurs', uid: '499' },
    { name: 'Lintest SmartBoat', uid: '1008' },
    { name: 'Litton', uid: '1858' },
    { name: 'Livorsi Marine', uid: '400' },
    { name: 'Lowarnce', uid: '140' },
    { name: 'Lumishore', uid: '798' },
    { name: 'LxNav', uid: '739' },
    { name: 'Maretron', uid: '137' },
    { name: 'Marinecraft (South Korea)', uid: '571' },
    { name: 'Marines Co (South Korea0', uid: '909' },
    { name: 'Marinesoft Co. LTD', uid: '510' },
    { name: 'Marol Co ltd', uid: '1062' },
    { name: 'Mastervolt', uid: '355' },
    { name: 'MBW Technologies (formerly MAS)', uid: '307' },
    { name: 'McMurdo Group aka Orolia LTD', uid: '573' },
    { name: 'Mercury Marine', uid: '144' },
    { name: 'MMP', uid: '1860' },
    { name: 'Moritz Aerospace', uid: '176' },
    { name: 'Mystic Valley Communications', uid: '198' },
    { name: 'National Instruments Korea', uid: '529' },
    { name: 'National Marine Electronics Association', uid: '530' },
    { name: 'Nautibus Electronic GmbH', uid: '147' },
    { name: 'Nautic-on', uid: '911' },
    { name: 'Nauticoncept', uid: '1083' },
    { name: 'Navico', uid: '275' },
    { name: 'Navionics', uid: '1852' },
    { name: 'Naviop S.R.L.', uid: '503' },
    { name: 'Nexfour Solutions', uid: '896' },
    { name: 'Nobletec', uid: '193' },
    { name: 'NoLand Engineering', uid: '517' },
    { name: 'Northstar Technologies', uid: '1854' },
    { name: 'Northern Lights', uid: '374' },
    { name: 'NovAtel', uid: '305' },
    { name: 'Ocean Sat BV', uid: '478' },
    { name: 'Ocean Signal', uid: '777' },
    { name: 'Oceanvolt', uid: '847' },
    { name: 'Offshore Systems (UK) Ltd.', uid: '161' },
    { name: 'Onwa Marine', uid: '532' },
    { name: 'Onyx Marine Automation s.r.l', uid: '1020' },
    { name: 'Parker Hannnifin aka Village Marine Tech', uid: '451' },
    { name: 'PEAK-System Technik GmbH', uid: '1023' },
    { name: 'Poly Planar', uid: '781' },
    { name: 'Power Pole ', uid: '929' },
    { name: 'ProNav', uid: '1051' },
    { name: 'Prospec', uid: '862' },
    { name: 'Quick-teck Electronics Ltd', uid: '1070' },
    { name: 'Qwerty', uid: '328' },
    { name: 'Raymarine', uid: '1851' },
    { name: 'REAP Systems', uid: '734' },
    { name: 'Rhodan Marine Systems', uid: '894' },
    { name: 'Rockford Corp', uid: '688' },
    { name: 'Rolls Royce Marine', uid: '370' },
    { name: 'Rose Point Navigation Systems', uid: '384' },
    { name: 'Sailormade Marine Telmetry/Tetra Technology LTD', uid: '235' },
    { name: 'SamwonIT', uid: '612' },
    { name: 'SAN GIORGIO S.E.I.N', uid: '460' },
    { name: 'San Jose Technology', uid: '580' },
    { name: 'Scheiber', uid: '1092' },
    { name: 'Sea Cross Marine AB', uid: '471' },
    { name: 'Sea Recovery', uid: '285' },
    { name: 'Seekeeper', uid: '778' },
    { name: 'Sentinel', uid: '9174' },
    { name: 'Shadow-Caster LED lighting LLC', uid: '1084' },
    { name: 'Shenzhen Jiuzhou Himunication', uid: '658' },
    { name: 'Ship Module aka Customware', uid: '595' },
    { name: 'Simrad', uid: '1857' },
    { name: 'SI-TEX Marine Electronics', uid: '470' },
    { name: 'Sleipner Motor AS', uid: '306' },
    { name: 'LINK Imperium Limited', uid: '1100' },
    { name: 'Sonic Corporation', uid: '1047' },
    { name: 'Soundmax', uid: '1011' },
    { name: 'Spot Zero Water', uid: '1066' },
    { name: 'Standard Horizon', uid: '421' },
    { name: 'Still Water Designs and Audto', uid: '799' },
    { name: 'Suzuki Motor Corporation', uid: '586' },
    { name: 'TeamSurv', uid: '838' },
    { name: 'Teleflex Marine (SeaStar Solutions)', uid: '1850' },
    { name: 'The Marine Guardian LLC', uid: '1029' },
    { name: 'Thrane and Thrane', uid: '351' },
    { name: 'Tides Marine', uid: '797' },
    { name: 'Timbolier Industries', uid: '962' },
    { name: 'Tohatsu Co, JP', uid: '431' },
    { name: 'Transas USA', uid: '518' },
    { name: 'Trimble', uid: '1856' },
    { name: 'True Heading AB', uid: '422' },
    { name: 'Twin Disc', uid: '80' },
    { name: 'Undheim Systems', uid: '824' },
    { name: 'Uniden America', uid: '1075' },
    { name: 'USCG', uid: '591' },
    { name: 'VDO (aka Continental-Corporation)', uid: '443' },
    { name: 'Vector Cantech', uid: '1861' },
    { name: 'Veethree Electronics & Marine', uid: '466' },
    { name: 'Vesper Marine Ltd', uid: '504' },
    { name: 'Vetus Maxwell INC.', uid: '1053' },
    { name: 'Victron Energy', uid: '358' },
    { name: 'Volvo Penta', uid: '174' },
    { name: 'Watcheye', uid: '493' },
    { name: 'Webasto ', uid: '540' },
    { name: 'Wema U.S.A dba KUS', uid: '644' },
    { name: 'Westerbeke', uid: '154' },
    { name: 'Wetsound', uid: '1085' },
    { name: 'Woosung', uid: '744' },
    { name: 'Xantrex Technology Inc.', uid: '168' },
    { name: 'Xintex/Atena', uid: '215' },
    { name: 'Yacht Control', uid: '583' },
    { name: 'Yacht Devices', uid: '717' },
    { name: 'Yacht Monitoring Solutions', uid: '233' },
    { name: 'Yamaha Marine', uid: '1862' },
    { name: 'Yanmar Marine', uid: '172' },
    { name: 'ZF Marine Electronics', uid: '228' },
    { name: 'Zontisa Marine', uid: '944' },
  ]);
}

export async function getModelsForManufacturer(id: string): Promise<MyResponse> {
  return requestMiddleware([]);
}
