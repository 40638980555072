import React, { ReactElement } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Select from '../../Select';
import styles from '../../Table/Table.module.scss';

interface Props {
  currentPage: number;
  pages: number;
  updateCurrentPage: (pageNum: number) => void;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  dataType?: string;
  pageLength: number;
  dataLength: number;
  pageSize: number;
  loading?: boolean;
}
const TableFooter = (props: Props): ReactElement => {
  const numberOfButtons = 5;
  const start = Math.floor((props.currentPage - 1) / numberOfButtons) * numberOfButtons;
  let pageNumbers: number[] = [];
  for (let x = 1; x < start + numberOfButtons + 1; x++) {
    if (pageNumbers.length > numberOfButtons - 1) {
      pageNumbers = [];
    }
    if (x <= props.pages) {
      pageNumbers.push(x);
    }
  }

  const navButtonTheme = {
    navHoverLeft: props.currentPage - 1 > 0 ? '#E9ECEF' : 'white',
    navHoverRight: props.currentPage + 1 <= props.pages ? '#E9ECEF' : 'white',
  };

  const getButton = (num: number): JSX.Element => {
    const theme = {
      background: props.currentPage === num ? '#001A3E' : 'white',
      color: props.currentPage === num ? 'white' : '#5E5F64',
      hover: props.currentPage !== num && '#E9ECEF',
      navHoverLeft: props.currentPage - 1 > 0 ? '#E9ECEF' : 'white',
      navHoverRight: props.currentPage + 1 <= props.pages ? '#E9ECEF' : 'white',
    };
    return (
      <ThemeProvider key={num} theme={theme}>
        <PageButton onClick={() => props.updateCurrentPage(num)}>{num}</PageButton>
      </ThemeProvider>
    );
  };

  const getPageButtons = (): JSX.Element => {
    const pageNumbers = [];
    const start = Math.max(
      0,
      Math.floor((props.currentPage - 1) / numberOfButtons) * numberOfButtons
    );
    const end = Math.min(start + numberOfButtons, props.pages);

    for (let i = start; i < end; i++) {
      pageNumbers.push(i + 1);
    }

    const theme = {
      background: 'white',
      color: '#5E5F64',
      hover: '#E9ECEF',
      navHoverLeft: props.currentPage - 1 > 0 ? '#E9ECEF' : 'white',
      navHoverRight: props.currentPage + 1 <= props.pages ? '#E9ECEF' : 'white',
    };

    return (
      <>
        {start > 0 && (
          <ThemeProvider theme={theme}>
            <PageButton onClick={() => props.updateCurrentPage(1)}>1</PageButton>
          </ThemeProvider>
        )}
        {start > 1 && (
          <ThemeProvider theme={theme}>
            <PageButton>...</PageButton>
          </ThemeProvider>
        )}
        {pageNumbers.map(num => getButton(num))}
        {end < props.pages && (
          <ThemeProvider theme={theme}>
            <PageButton>...</PageButton>
          </ThemeProvider>
        )}
        {end < props.pages && (
          <ThemeProvider theme={theme}>
            <PageButton onClick={() => props.updateCurrentPage(props.pages)}>
              {props.pages}
            </PageButton>
          </ThemeProvider>
        )}
      </>
    );
  };
  return (
    <FooterWrap>
      {props.dataLength > 0 ? (
        <div className={styles.selectListLength}>
          <h6>No. of {props.dataType ? props.dataType : 'entries'}</h6>
          <Select
            defaultValue={props.pageSize}
            disabled={props.dataLength < 5}
            className={styles.selector}
            onChange={e => props.setPageSize(Number(e.target.value))}>
            {[5, 10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </div>
      ) : (
        !props.loading && <p>0 {props.dataType ? props.dataType : 'documents'} found</p>
      )}
      {props.dataLength > 0 && (
        <DataCount>
          Showing{' '}
          {props.currentPage === 1 ? 1 : props.currentPage * props.pageSize - props.pageSize + 1} to{' '}
          {props.currentPage * props.pageSize - (props.pageSize - props.pageLength)} of{' '}
          {props.dataLength} {props.dataType ? props.dataType : 'entries'}
        </DataCount>
      )}
      {props.pages > 0 && (
        <ButtonWrap>
          <ThemeProvider theme={navButtonTheme}>
            <NavButtonLeft
              style={{
                borderLeft: 'solid 1px rgba(0, 0, 0, 0.16)',
                borderBottomLeftRadius: '4px',
                borderTopLeftRadius: '4px',
              }}
              onClick={() => {
                if (props.currentPage - 1 > 0) {
                  props.updateCurrentPage(props.currentPage - 1);
                }
              }}>
              {'Previous'}
            </NavButtonLeft>
          </ThemeProvider>
          {getPageButtons()}
          <ThemeProvider theme={navButtonTheme}>
            <NavButtonRight
              style={{ borderBottomRightRadius: '4px', borderTopRightRadius: '4px' }}
              onClick={() => {
                if (props.currentPage + 1 <= props.pages) {
                  props.updateCurrentPage(props.currentPage + 1);
                }
              }}>
              {'Next'}
            </NavButtonRight>
          </ThemeProvider>
        </ButtonWrap>
      )}
    </FooterWrap>
  );
};
export default TableFooter;

const FooterWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-content: center;
  align-items: center;
  margin-bottom: 1em;
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: min-content;
  justify-self: end;
  border-radius: 6px;
`;

const PageButton = styled.div`
  width: min-content;
  min-width: 2.3em;
  height: 2.3em;
  display: grid;
  text-align: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.13);
  border-left: none;
  box-sizing: content-box;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.color};

  :hover {
    background-color: ${props => props.theme.hover};
  }
`;

const NavButtonLeft = styled(PageButton)`
  padding-left: 10px;
  padding-right: 10px;
  :hover {
    background-color: ${props => props.theme.navHoverLeft};
  }
`;

const NavButtonRight = styled(PageButton)`
  padding-left: 10px;
  padding-right: 10px;
  :hover {
    background-color: ${props => props.theme.navHoverRight};
  }
`;

const DataCount = styled.div`
  justify-self: end;
  margin-right: 20px;
`;
