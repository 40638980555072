import { DateFilters, ValueResponse } from 'types/graphs';

export function getDemoGraphData(dateTime: DateFilters): ValueResponse[] {
  switch (dateTime) {
    case 'LAST_DAY':
      return dayData;
    case 'LAST_7_DAYS':
      return weekData;
    default:
      return hourData;
  }
}

const hourData: ValueResponse[] = [
  { average: 6.64, max: 8.12, min: 5.75, timestamp: '2023-03-30T15:15:21.887+00:00' },
  { average: 6.57, max: 7.68, min: 5.75, timestamp: '2023-03-30T15:16:23.691+00:00' },
  { average: 6.28, max: 7.9, min: 5.62, timestamp: '2023-03-30T15:17:20.300+00:00' },
  { average: 6.72, max: 7.5, min: 5.88, timestamp: '2023-03-30T15:18:22.146+00:00' },
  { average: 6.47, max: 7.66, min: 5.62, timestamp: '2023-03-30T15:19:18.814+00:00' },
  { average: 5.24, max: 8.21, min: 2.77, timestamp: '2023-03-30T15:20:20.628+00:00' },
  { average: 5.91, max: 7.3, min: 3.03, timestamp: '2023-03-30T15:21:22.551+00:00' },
  { average: 6.65, max: 7.35, min: 5.76, timestamp: '2023-03-30T15:22:19.138+00:00' },
  { average: 6.28, max: 7.88, min: 5.47, timestamp: '2023-03-30T15:23:20.895+00:00' },
  { average: 6.42, max: 7.23, min: 5.58, timestamp: '2023-03-30T15:24:22.614+00:00' },
  { average: 6.83, max: 8.05, min: 5.84, timestamp: '2023-03-30T15:25:19.287+00:00' },
  { average: 6.41, max: 7.39, min: 5.51, timestamp: '2023-03-30T15:26:21.070+00:00' },
  { average: 6.39, max: 8.16, min: 5.55, timestamp: '2023-03-30T15:27:22.856+00:00' },
  { average: 6.7, max: 7.83, min: 5.45, timestamp: '2023-03-30T15:28:19.514+00:00' },
  { average: 6.67, max: 7.89, min: 6.04, timestamp: '2023-03-30T15:29:21.280+00:00' },
  { average: 6.44, max: 7.39, min: 5.4, timestamp: '2023-03-30T15:30:23.016+00:00' },
  { average: 6.74, max: 9.12, min: 5.82, timestamp: '2023-03-30T15:31:19.618+00:00' },
  { average: 6.43, max: 7.16, min: 5.81, timestamp: '2023-03-30T15:32:21.448+00:00' },
  { average: 6.45, max: 7.41, min: 5.57, timestamp: '2023-03-30T15:33:23.131+00:00' },
  { average: 6.8, max: 8.23, min: 5.73, timestamp: '2023-03-30T15:34:19.747+00:00' },
  { average: 6.31, max: 7.55, min: 5.72, timestamp: '2023-03-30T15:35:21.549+00:00' },
  { average: 6.46, max: 7.98, min: 5.51, timestamp: '2023-03-30T15:36:23.289+00:00' },
  { average: 6.62, max: 8.34, min: 5.86, timestamp: '2023-03-30T15:37:20.017+00:00' },
  { average: 6.21, max: 6.94, min: 5.56, timestamp: '2023-03-30T15:38:21.810+00:00' },
  { average: 6.58, max: 7.36, min: 5.56, timestamp: '2023-03-30T15:39:23.590+00:00' },
  { average: 6.51, max: 7.87, min: 5.41, timestamp: '2023-03-30T15:40:20.206+00:00' },
  { average: 6.34, max: 7.58, min: 5.73, timestamp: '2023-03-30T15:41:22.017+00:00' },
  { average: 6.5, max: 7.54, min: 5.45, timestamp: '2023-03-30T15:42:23.727+00:00' },
  { average: 6.54, max: 7.69, min: 5.69, timestamp: '2023-03-30T15:43:20.352+00:00' },
  { average: 6.3, max: 7.93, min: 5.65, timestamp: '2023-03-30T15:44:22.184+00:00' },
  { average: 6.83, max: 7.93, min: 5.98, timestamp: '2023-03-30T15:45:23.960+00:00' },
  { average: 6.36, max: 7.69, min: 5.41, timestamp: '2023-03-30T15:46:20.407+00:00' },
  { average: 6.39, max: 7.91, min: 5.72, timestamp: '2023-03-30T15:47:22.258+00:00' },
  { average: 6.69, max: 7.37, min: 6.17, timestamp: '2023-03-30T15:48:23.999+00:00' },
  { average: 6.46, max: 8.04, min: 5.8, timestamp: '2023-03-30T15:49:20.736+00:00' },
  { average: 6.34, max: 8.86, min: 5.49, timestamp: '2023-03-30T15:50:22.411+00:00' },
  { average: 6.68, max: 7.7, min: 5.84, timestamp: '2023-03-30T15:51:24.172+00:00' },
  { average: 5.71, max: 7.39, min: 2.69, timestamp: '2023-03-30T15:52:20.861+00:00' },
  { average: 5.31, max: 7.32, min: 2.48, timestamp: '2023-03-30T15:53:22.614+00:00' },
  { average: 6.32, max: 7.74, min: 5.64, timestamp: '2023-03-30T15:54:24.449+00:00' },
  { average: 6.62, max: 8.75, min: 5.55, timestamp: '2023-03-30T15:55:20.951+00:00' },
  { average: 6.35, max: 7.45, min: 5.8, timestamp: '2023-03-30T15:59:23.194+00:00' },
  { average: 6.86, max: 8.86, min: 5.76, timestamp: '2023-03-30T16:00:19.890+00:00' },
  { average: 6.47, max: 7.88, min: 5.47, timestamp: '2023-03-30T16:01:21.713+00:00' },
  { average: 6.5, max: 7.31, min: 5.66, timestamp: '2023-03-30T16:02:23.461+00:00' },
  { average: 6.82, max: 8.46, min: 5.81, timestamp: '2023-03-30T16:03:20.071+00:00' },
  { average: 6.45, max: 7.56, min: 5.72, timestamp: '2023-03-30T16:04:16.764+00:00' },
  { average: 6.21, max: 7.09, min: 5.59, timestamp: '2023-03-30T16:05:23.600+00:00' },
  { average: 6.67, max: 7.78, min: 5.52, timestamp: '2023-03-30T16:06:20.349+00:00' },
  { average: 6.26, max: 7.6, min: 5.65, timestamp: '2023-03-30T16:07:22.064+00:00' },
  { average: 6.26, max: 6.99, min: 5.75, timestamp: '2023-03-30T16:08:23.750+00:00' },
  { average: 6.76, max: 7.52, min: 5.96, timestamp: '2023-03-30T16:09:20.352+00:00' },
  { average: 6.42, max: 7.47, min: 5.78, timestamp: '2023-03-30T16:10:22.225+00:00' },
  { average: 6.29, max: 7.05, min: 5.45, timestamp: '2023-03-30T16:11:24.012+00:00' },
  { average: 6.72, max: 8.29, min: 5.48, timestamp: '2023-03-30T16:12:20.578+00:00' },
  { average: 6.49, max: 7.67, min: 5.43, timestamp: '2023-03-30T16:13:22.438+00:00' },
  { average: 6.26, max: 7.64, min: 5.57, timestamp: '2023-03-30T16:14:24.253+00:00' },
];

const dayData: ValueResponse[] = [
  { average: 8.54, max: 86.81, min: 5.16, timestamp: '2023-04-04T09:25:53.000+00:00' },
  { average: 8.27, max: 84.77, min: 1.95, timestamp: '2023-04-04T12:09:00.000+00:00' },
  { average: 5.72, max: 13.81, min: 1.58, timestamp: '2023-04-04T13:08:59.000+00:00' },
  { average: 9.09, max: 79.87, min: 3.72, timestamp: '2023-04-04T15:16:48.000+00:00' },
  { average: 8.13, max: 17.02, min: 2.74, timestamp: '2023-04-04T17:16:53.000+00:00' },
  { average: 7.38, max: 13.02, min: 2.83, timestamp: '2023-04-04T18:16:56.000+00:00' },
  { average: 7.52, max: 13.49, min: 5.75, timestamp: '2023-04-04T19:16:59.000+00:00' },
  { average: 6.61, max: 10.17, min: 3.02, timestamp: '2023-04-04T20:17:00.000+00:00' },
  { average: 5.77, max: 8.24, min: 5.12, timestamp: '2023-04-04T21:17:07.000+00:00' },
  { average: 5.78, max: 7.65, min: 2.3, timestamp: '2023-04-04T22:17:09.000+00:00' },
  { average: 5.74, max: 16.47, min: 4.97, timestamp: '2023-04-04T23:17:11.000+00:00' },
  { average: 5.78, max: 10.25, min: 4.68, timestamp: '2023-04-05T00:17:12.000+00:00' },
  { average: 5.75, max: 7.89, min: 4.93, timestamp: '2023-04-05T01:17:14.000+00:00' },
  { average: 5.75, max: 7.13, min: 5.02, timestamp: '2023-04-05T02:17:15.000+00:00' },
  { average: 5.74, max: 6.97, min: 4.76, timestamp: '2023-04-05T03:17:18.000+00:00' },
  { average: 5.76, max: 13.95, min: 4.68, timestamp: '2023-04-05T04:17:19.000+00:00' },
  { average: 5.75, max: 7.57, min: 2.82, timestamp: '2023-04-05T06:17:22.000+00:00' },
  { average: 6.56, max: 12.84, min: 2.48, timestamp: '2023-04-05T07:17:25.000+00:00' },
];

const weekData: ValueResponse[] = [
  { min: 0.4, max: 62.56, average: 1.38, timestamp: '2023-10-18T00:00:00.000+00:00' },
  { min: 0.4, max: 62.56, average: 1.38, timestamp: '2023-10-19T06:05:37.255+00:00' },
  { min: 0.3, max: 50.66, average: 1.34, timestamp: '2023-10-20T06:28:17.532+00:00' },
  { min: 0.3, max: 65.75, average: 1.42, timestamp: '2023-10-20T20:43:30.000+00:00' },
  { min: 0.35, max: 38.84, average: 1.83, timestamp: '2023-10-21T10:13:30.004+00:00' },
  { min: 0.3, max: 65.75, average: 1.43, timestamp: '2023-10-23T08:23:21.710+00:00' },
  { min: 0.3, max: 65.75, average: 1.42, timestamp: '2023-10-24T08:56:43.821+00:00' },
];
