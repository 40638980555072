import NoSyncPin from 'assets/images/icons/pins/pale-grey-not-syncing-pin.png';
import React, { ReactElement } from 'react';
import { VesselType } from 'store/vessels/types';
import { brandAmber, brandBlue, brandLightGrey, brandRed } from 'styles/colors';

import DynamicPin from '../DynamicPin';

type Props = {
  state: string;
  vesselType?: VesselType;
  noSync?: boolean;
  type?: string;
  iconStyle?: React.CSSProperties;
};

export function PinIcon({ state, noSync, type, vesselType }: Props): ReactElement {
  let color: string;
  switch (state) {
    case 'NORMAL':
      color = brandBlue;
      break;
    case 'WARNING':
      color = brandAmber;
      break;
    case 'CRITICAL':
      color = brandRed;
      break;
    case 'OFFLINE':
      color = brandLightGrey;
      break;
    case 'BUS_OFFLINE':
      color = brandLightGrey;
      break;
    default:
      color = brandLightGrey;
  }

  return (
    <>
      {noSync ? (
        <img src={NoSyncPin} alt={'Non-Sync Pin'} />
      ) : (
        <DynamicPin geofence={type === 'GEOFENCE'} background={color} vesselType={vesselType} />
      )}
    </>
  );
}
