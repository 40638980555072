import DemoDatabase from 'demo/persistentStorage';

export class DemoSimpleDevicesCache {
  private static instance: DemoSimpleDevicesCache | null = null;
  private _templates: any[] = [];
  private _locations: string[] = [];

  public static getInstance(): DemoSimpleDevicesCache {
    if (this.instance === null) {
      this.instance = new DemoSimpleDevicesCache();
    }
    return this.instance;
  }

  getTemplates(): any[] {
    if (this._templates.length === 0) {
      this._templates = require('demo/data/simpleDevices/templates.json');
      for (let i = 0; i < this._templates.length; i++) {
        this._templates[i].id = i + 1;
      }
    }
    return this._templates;
  }

  async getLocations(): Promise<string[]> {
    if (this._locations.length === 0) {
      this._locations =
        await DemoDatabase.getInstance().VesselSimpleDevicesLocations.getAllWithDefault<string>();
    }
    return this._locations;
  }

  createNewLocation(newLocation: string): void {
    if (!this._locations.includes(newLocation)) {
      this._locations.push(newLocation);
      DemoDatabase.getInstance().VesselSimpleDevicesLocations.add(newLocation);
    }
  }
}
