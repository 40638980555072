export class storeHandler {
  db: IDBDatabase;
  storeName: string;
  constructor(db: IDBDatabase, storeName: string) {
    this.db = db;
    this.storeName = storeName;
  }

  //Read
  getAll<T>(): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.getAll();

      request.onsuccess = () => resolve(request.result as T[]);
      request.onerror = () => reject(request.error);
    });
  }

  get<T>(key: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(key);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  getById<T>(id: string | number, key: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.openCursor();

      const records: T[] = [];
      request.onsuccess = event => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          if (cursor.value[key] === id) {
            records.push(cursor.value);
          }
          cursor.continue();
        } else {
          resolve(records);
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  //Not used yet
  getAllWithDefault<T>(passedDefault?: any[]): Promise<T[]> {
    return new Promise((resolve, reject) => {
      //
    });
  }

  where<T>(indexKey: string, value: string | number): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const index = store.index(indexKey);
      const range = IDBKeyRange.only(value);
      const request = index.openCursor(range);

      const records: T[] = [];
      request.onsuccess = event => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          records.push(cursor.value);
          cursor.continue();
        } else {
          resolve(records);
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  count(): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.count();

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  //Create

  addBatch<T>(records: T[]): Promise<IDBValidKey[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const ids: IDBValidKey[] = [];
      records.forEach(record => {
        // Remove the `id` field to avoid conflicts
        // @ts-ignore
        const { id, ...recordWithoutId } = record;
        const request = store.add(recordWithoutId);
        request.onsuccess = () => {
          ids.push(request.result as IDBValidKey);
        };
        request.onerror = event => {
          reject(`Error adding record: ${(event.target as IDBRequest).error}`);
        };
      });

      transaction.oncomplete = () => resolve(ids);
      transaction.onerror = event =>
        reject(`Error adding records: ${(event.target as IDBRequest).error}`);
    });
  }

  add<T>(record: T): Promise<IDBValidKey> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      // Remove the `id` field to avoid conflicts
      // @ts-ignore
      const { id, ...recordWithoutId } = record;
      const request = store.add(recordWithoutId);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  //Update
  update<T>(id: number | string, updatedRecord: T): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(id);

      request.onsuccess = event => {
        let record = (event.target as IDBRequest).result;
        if (record) {
          record = updatedRecord;
          if (store.keyPath) {
            record[store.keyPath as string] = id;
          }
          const updateRequest = store.put(record);
          updateRequest.onsuccess = () => resolve('Record updated successfully.');
          updateRequest.onerror = event =>
            reject(`Error updating record: ${(event.target as IDBRequest).error}`);
        } else {
          reject('Record not found.');
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  updatePartial<T>(id: number, updatedRecord: T): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(id);

      request.onsuccess = event => {
        let record = (event.target as IDBRequest).result;
        if (record) {
          record = { ...record, ...updatedRecord };
          const updateRequest = store.put(record);
          updateRequest.onsuccess = () => resolve('Record updated successfully.');
          updateRequest.onerror = event =>
            reject(`Error updating record: ${(event.target as IDBRequest).error}`);
        } else {
          reject('Record not found.');
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  updatePartialBulk<T>(updatedRecord: T[]): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      updatedRecord.forEach(updatedRecord => {
        // @ts-ignore
        const request = store.get(updatedRecord.id);
        request.onsuccess = function (event) {
          let record = (event.target as IDBRequest).result;
          if (record) {
            record = { ...record, ...updatedRecord };
            const updateRequest = store.put(record);
            updateRequest.onerror = function (event) {
              reject(`Error updating record: ${(event.target as IDBRequest).error}`);
            };
          } else {
            reject('Record not found.');
          }
        };
        request.onerror = function (event) {
          console.error(
            // @ts-ignore
            `Error updating record with ID ${updatedRecord.id}:`,
            (event.target as IDBRequest).error
          );
        };
      });
      transaction.oncomplete = () => resolve('Bulk update completed successfully.');
      transaction.onerror = event =>
        reject(`Transaction failed: ${(event.target as IDBRequest).error}`);
    });
  }

  //Delete
  delete(id: number | string): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(id);
      request.onsuccess = () => resolve(`Record with id ${id} deleted successfully.`);
      request.onerror = event =>
        reject(`Failed to delete record with id ${id}: ${(event.target as IDBRequest).error}`);
    });
  }

  deleteWhere(indexKey: string, value: string | number): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const index = store.index(indexKey);
      const request = index.openCursor(IDBKeyRange.only(value));
      request.onsuccess = function (event) {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          cursor.delete();
          cursor.continue();
        } else {
          resolve('All matching records have been deleted.');
        }
      };
      request.onerror = event =>
        reject(`Failed to delete record with id: ${(event.target as IDBRequest).error}`);
    });
  }

  clear(): Promise<string> {
    return new Promise((resolve, reject) => {
      //
    });
  }
}
