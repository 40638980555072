import { ValueHolder } from 'store/selectedDevice/types';
import { Alerts } from 'store/selectedVessel/types';

export const DefaultDeviceAlertResponse = {
  instant: false,
  warnLevelHigh: 'NOT SET',
  warnLevelLow: 'NOT SET',
  criticalLevelHigh: 'NOT SET',
  criticalLevelLow: 'NOT SET',
  state: 'NORMAL',
  lastTriggeredValue: '',
  ackTimestamp: null,
  ackUserId: null,
  ackUserName: '',
  acknowledged: false,
  alertDelayEnable: false,
  alertDelayMs: 0,
  alertMessage: '',
  repeatOptions: 'NEVER',
  silenceTimestamp: null,
  silenceUserId: null,
  silenceUsername: '',
  silenced: false,
  instantAlertLevel: 'NORMAL',
  hasAlertConfig: false,
};

export const DefaultAlertsResponse: Alerts = {
  active: false,
  lastTriggeredValue: '',
  paused: false,
  alertType: '',
  state: 'NORMAL',
  id: '1',
  name: '',
  message: '',
  currentValue: '0',
  lastTriggered: new Date().toISOString(),
  acknowledged: false,
  ackUsername: '',
  ackTimestamp: '',
  deviceId: 1,
  silenced: false,
};

export const defaultCustomAlert = {
  // id: string;
  ackTimestamp: null,
  ackUsername: '',
  acknowledged: false,
  active: false,
  alertLevel: 'NORMAL',
  lastTriggered: '',
  lastTriggeredValue: '',
  matchAllConditions: false,
  message: '',
  name: '',
  paused: false,
  repeatOptions: 'NEVER',
  silenceTimestamp: null,
  silenceUserId: null,
  silenceUsername: '',
  silenced: false,
};

export const checkPropertyAlert = (
  alertConfig: any,
  currentValue: string,
  containsAlertConfig: boolean
): 'update' | 'new' | 'same' | 'remove' => {
  if (alertConfig.instant) {
    const prevState = alertConfig.state;
    return prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update';
  }
  if (!containsAlertConfig) {
    return 'new';
  }
  const prevState = alertConfig.state;
  const value = !isNaN(Number(currentValue)) ? Number(currentValue) : 0;
  const warnLevelLow = !isNaN(Number(alertConfig.warnLevelLow))
    ? Number(alertConfig.warnLevelLow)
    : 'NOT SET';
  const warnLevelHigh = !isNaN(Number(alertConfig.warnLevelHigh))
    ? Number(alertConfig.warnLevelHigh)
    : 'NOT SET';
  const criticalLevelLow = !isNaN(Number(alertConfig.criticalLevelLow))
    ? Number(alertConfig.criticalLevelLow)
    : 'NOT SET';
  const criticalLevelHigh = !isNaN(Number(alertConfig.criticalLevelHigh))
    ? Number(alertConfig.criticalLevelHigh)
    : 'NOT SET';
  if (criticalLevelHigh !== 'NOT SET' && value >= criticalLevelHigh) {
    return prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update';
  } else if (warnLevelHigh !== 'NOT SET' && value >= warnLevelHigh) {
    return prevState === 'WARNING' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update';
  } else if (criticalLevelLow !== 'NOT SET' && value <= criticalLevelLow) {
    return prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update';
  } else if (warnLevelLow !== 'NOT SET' && value <= warnLevelLow) {
    return prevState === 'WARNING' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update';
  } else {
    if (
      criticalLevelHigh === 'NOT SET' &&
      warnLevelHigh === 'NOT SET' &&
      criticalLevelLow === 'NOT SET' &&
      warnLevelLow === 'NOT SET'
    ) {
      alertConfig.hasAlertConfig = false;
      return 'remove';
    }
    return prevState === 'NORMAL' ? 'same' : 'remove';
  }
};

export const calcAlertMsg = (state: string, defaultMessage: string, vh?: ValueHolder): string => {
  if (!vh) {
    return defaultMessage;
  }
  if (vh.dictionary && Object.keys(vh.dictionary).length > 0 && vh.value !== undefined) {
    return `${vh.name} ${vh.dictionary[Number(vh.value)]}`;
  }
  if (state === 'WARNING') {
    if (vh.value <= vh.warnLevelLow) {
      if (vh.warnTextLow) {
        return vh.warnTextLow;
      }
    } else {
      if (vh.warnTextHigh) {
        return vh.warnTextHigh;
      }
    }
  } else if (state === 'CRITICAL') {
    if (vh.value <= vh.criticalLevelLow) {
      if (vh.criticalTextLow) {
        return vh.criticalTextLow;
      }
    } else {
      if (vh.criticalTextHigh) {
        return vh.criticalTextHigh;
      }
    }
  }
  return defaultMessage;
};
