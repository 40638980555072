import { DemoDeviceGenerator } from 'demo/controllers/devices/advancedDevices/deviceGenerator';
import { createFields, createSimpleDevice, dashboardDataNormalise } from 'demo/dataImporter';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
  databaseResponse,
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceMetaDataTable,
  SimpleDeviceTable,
} from 'demo/persistentStorage/database/tables';

export const generateMainSwitchboardAdvanced = (
  valueHolderId: number,
  deviceId: number
): { device: AdvancedDeviceTable; vhs: AdvancedDevicesPropertyVh[] } => {
  const device: {
    name: string;
    bus: string;
    valueHolders: { name: string; uid: string; gaugeLow?: number; gaugeHigh?: number }[];
  } = {
    name: 'Main Switchboard',
    bus: 'CAN2',
    valueHolders: [],
  };
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 6; j++) {
      device.valueHolders.push({
        name: `Power Source ${i + 1}: Gauge ${j + 1} Property`,
        uid: '127508-2',
        gaugeLow: 0,
        gaugeHigh: 60,
      });
    }
    for (let j = 0; j < 5; j++) {
      device.valueHolders.push({
        name: `Power Source ${i + 1}: Button ${j + 1} Property`,
        uid: 'KNXBINARYSWITCH-1',
      });
    }
    device.valueHolders.push({
      name: `Power Source ${i + 1}: isEngaged`,
      uid: 'KNXBINARYSWITCH-1',
    });
    device.valueHolders.push({
      name: `Power Source ${i + 1}: state`,
      uid: 'KNXBINARYSWITCH-1',
    });
  }
  const deviceGenerator = new DemoDeviceGenerator(
    device.name,
    device.bus,
    device.valueHolders,
    valueHolderId
  );
  return deviceGenerator.generateDevicesAndVhs(deviceId);
};

export const generateMainSwitchboardSimple = (
  templates: any,
  devices: databaseResponse<AdvancedDeviceTable>[],
  vhs: (AdvancedDevicesPropertyVh & { id: number })[],
  deviceId: number
): {
  device: SimpleDeviceTable;
  fields: SimpleDeviceFieldWrapperTable[];
  metaData: SimpleDeviceMetaDataTable[];
} | null => {
  const mainSwitchboardDevice = createSimpleDevice(templates, {
    type: 'Main Switchboard',
    name: 'Main Switchboard',
  });
  if (mainSwitchboardDevice === null) {
    return null;
  }
  const template = mainSwitchboardDevice.template;
  const deviceToMap: { name: string; props: { field: string; vh: string }[] } = {
    name: 'Main Switchboard',
    props: [],
  };
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 6; j++) {
      deviceToMap.props.push({
        field: `Power Source ${i + 1}: Gauge ${j + 1} Property`,
        vh: `Power Source ${i + 1}: Gauge ${j + 1} Property`,
      });
    }
    for (let j = 0; j < 5; j++) {
      deviceToMap.props.push({
        field: `Power Source ${i + 1}: Button ${j + 1} Property`,
        vh: `Power Source ${i + 1}: Button ${j + 1} Property`,
      });
    }
    deviceToMap.props.push({
      field: `Power Source ${i + 1}: isEngaged`,
      vh: `Power Source ${i + 1}: isEngaged`,
    });
    deviceToMap.props.push({
      field: `Power Source ${i + 1}: state`,
      vh: `Power Source ${i + 1}: state`,
    });
  }
  const [mainSwitchboardVhs, vhNames] = dashboardDataNormalise(vhs, devices, [deviceToMap]);
  const [tempFields, tempMetaData] = createFields(
    mainSwitchboardVhs,
    deviceId,
    template,
    vhNames,
    'Main Switchboard'
  );

  return { device: mainSwitchboardDevice.device, fields: tempFields, metaData: tempMetaData };
};

export const generateHydraulicsAdvanced = (
  valueHolderId: number,
  deviceId: number
): { device: AdvancedDeviceTable; vhs: AdvancedDevicesPropertyVh[] } => {
  const sources = [];
  const modes = [];
  const controls = [];
  const statuses = [];
  const statusValues = ['0', '1', '4', '5', '7', '9', '10'];
  for (let i = 0; i < 5; i++) {
    sources.push({ name: `Source ${i + 1} on property`, uid: 'KNXBINARYSWITCH-1' });
    sources.push({ name: `Source ${i + 1} off property`, uid: 'KNXBINARYSWITCH-1' });
    sources.push({ name: `Source ${i + 1} auto property`, uid: 'KNXBINARYSWITCH-1' });
  }
  for (let i = 0; i < 5; i++) {
    modes.push({ name: `Mode ${i + 1} property`, uid: 'KNXBINARYSWITCH-1' });
  }
  for (let i = 0; i < 20; i++) {
    controls.push({ name: `Control ${i + 1} button1 property`, uid: 'KNXBINARYSWITCH-1' });
    controls.push({ name: `Control ${i + 1} button2 property`, uid: 'KNXBINARYSWITCH-1' });
  }
  const enabled = { name: 'Hydraulics Enabled', uid: 'KNXBINARYSWITCH-1' };
  for (let i = 0; i < 50; i++) {
    const randomIndex = Math.floor(Math.random() * statusValues.length);
    statuses.push({ name: `Status ${i + 1}`, uid: '127750-3', value: statusValues[randomIndex] });
  }

  const device = {
    name: 'Hydraulics',
    bus: 'CAN2',
    valueHolders: [...sources, ...modes, ...controls, enabled, ...statuses],
  };
  const deviceGenerator = new DemoDeviceGenerator(
    device.name,
    device.bus,
    device.valueHolders,
    valueHolderId
  );
  return deviceGenerator.generateDevicesAndVhs(deviceId);
};

export const generateHydraulicsSimple = (
  templates: any,
  devices: databaseResponse<AdvancedDeviceTable>[],
  vhs: (AdvancedDevicesPropertyVh & { id: number })[],
  deviceId: number
): {
  device: SimpleDeviceTable;
  fields: SimpleDeviceFieldWrapperTable[];
  metaData: SimpleDeviceMetaDataTable[];
} | null => {
  const hydraulicDevice = createSimpleDevice(templates, {
    type: 'Hydraulic',
    name: 'Hydraulic',
  });
  if (hydraulicDevice === null) {
    return null;
  }
  const template = hydraulicDevice.template;
  const deviceToMap: { name: string; props: { field: string; vh: string }[] } = {
    name: 'Hydraulics',
    props: [],
  };
  for (let i = 0; i < 5; i++) {
    deviceToMap.props.push({
      field: `Source ${i + 1} on property`,
      vh: `Source ${i + 1} on property`,
    });
    deviceToMap.props.push({
      field: `Source ${i + 1} off property`,
      vh: `Source ${i + 1} off property`,
    });
    deviceToMap.props.push({
      field: `Source ${i + 1} auto property`,
      vh: `Source ${i + 1} auto property`,
    });
  }
  for (let i = 0; i < 5; i++) {
    deviceToMap.props.push({ field: `Mode ${i + 1} property`, vh: `Mode ${i + 1} property` });
  }
  for (let i = 0; i < 20; i++) {
    deviceToMap.props.push({
      field: `Control ${i + 1} button1 property`,
      vh: `Control ${i + 1} button1 property`,
    });
    deviceToMap.props.push({
      field: `Control ${i + 1} button2 property`,
      vh: `Control ${i + 1} button2 property`,
    });
  }
  deviceToMap.props.push({ field: 'Hydraulics Enabled', vh: 'Hydraulics Enabled' });
  for (let i = 0; i < 50; i++) {
    deviceToMap.props.push({ field: `Status ${i + 1}`, vh: `Status ${i + 1}` });
  }
  const [hydraulicVhs, hydraulicVhNames] = dashboardDataNormalise(vhs, devices, [deviceToMap]);
  const [tempFields, tempMetaData] = createFields(
    hydraulicVhs,
    deviceId,
    template,
    hydraulicVhNames,
    'Hydraulic'
  );
  return { device: hydraulicDevice.device, fields: tempFields, metaData: tempMetaData };
};
