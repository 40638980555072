import { Alerts } from 'store/selectedVessel/types';
import { Condition } from 'store/vesselRoutines/types';

export class DemoCustomAlert {
  id: number | null = null;
  name = '';
  message = '';
  alertLevel = 'NORMAL';
  matchAllConditions = false;
  conditions: Condition[] = [];
  repeatOptions = 'NEVER';
  active = true;
  lastTriggered = '';
  lastTriggeredValue = '';
  acknowledged = false;
  silenced = false;
  ackUsername = '';
  ackTimestamp = '';
  silenceUsername = '';
  silenceUserId = '';
  silenceTimestamp = '';
  paused = false;
  notifyUsers: string[] = [];

  constructor(
    payload: {
      id: number;
      name: string;
      message: string;
      alertLevel: string;
      notifyUsers: string[];
      matchAllConditions: boolean;
      conditions: Condition[];
      repeatOptions: string;
    } & { [key: string]: any }
  ) {
    this.name = payload.name;
    this.message = payload.message;
    this.alertLevel = payload.alertLevel;
    this.notifyUsers = payload.notifyUsers;
    this.matchAllConditions = payload.matchAllConditions;
    this.conditions = payload.conditions;
    this.repeatOptions = payload.repeatOptions;
    this.id = payload.id;
    if (Object.keys(payload).length > 8) {
      this.setIfExists('ackTimestamp', payload.ackTimestamp);
      this.setIfExists('ackUsername', payload.ackUsername);
      this.setIfExists('acknowledged', payload.acknowledged);
      this.setIfExists('lastTriggered', payload.lastTriggered);
      this.setIfExists('lastTriggeredValue', payload.lastTriggeredValue);
      this.setIfExists('paused', payload.paused);
      this.setIfExists('silenceTimestamp', payload.silenceTimestamp);
      this.setIfExists('silenceUserId', payload.silenceUserId);
      this.setIfExists('silenceUsername', payload.silenceUsername);
      this.setIfExists('silenced', payload.silenced);
    }
  }

  setIfExists(key: keyof this, value?: any) {
    if (value !== undefined) {
      this[key] = value;
    }
  }

  response(): Alerts {
    return {
      alertType: 'CUSTOM',
      state: this.alertLevel,
      id: 'CUS_' + this.id,
      name: this ? this.name : '',
      message: this.message,
      currentValue: '',
      lastTriggered: new Date().toISOString(),
      paused: this.paused,
      acknowledged: false,
      ackUsername: this.ackUsername,
      ackTimestamp: this.ackTimestamp,
      deviceId: 0,
      silenced: this.silenced,
      active: true,
      lastTriggeredValue: this.lastTriggeredValue,
    };
  }
}
