import { CustomAlertI } from 'demo/controllers/alerts/alertController';
import { calcAlertMsg, defaultCustomAlert } from 'demo/controllers/alerts/alertsUtils';
import DemoDatabase from 'demo/persistentStorage';
import { CustomAlertTable } from 'demo/persistentStorage/database/tables';
import { demoDevicesController } from 'demo/provider';
import { DemoCustomAlert } from 'demo/structs/alerts/CustomAlert';
import { DeviceAlertConfig } from 'demo/structs/alerts/DeviceAlertConfig';
import { DemoGeofenceAlert } from 'demo/structs/alerts/GeofenceAlert';
import { storageUtil } from 'demo/utility';
import { ValueHolder } from 'store/selectedDevice/types';
import { Alerts } from 'store/selectedVessel/types';
import { VesselAlert } from 'store/types';
import { Condition } from 'store/vesselRoutines/types';
import { AlertLog, AlertType, Status } from 'types/logs';

export class DemoAlertCache {
  private static instance: DemoAlertCache | null = null;
  private _customAlerts: DemoCustomAlert[] = [];
  private _propertyAlertConfigs: DeviceAlertConfig[] = [];
  private _geofenceAlerts: DemoGeofenceAlert[] = [];
  private _logs: AlertLog[] = [];

  public static getInstance(): DemoAlertCache {
    if (this.instance === null) {
      this.instance = new DemoAlertCache();
    }
    return this.instance;
  }

  retrieveAlertsData = async (): Promise<void> => {
    this.retrieveDeviceAlerts();
    const customAlerts = await DemoDatabase.getInstance().CustomAlert.getAll<CustomAlertI[]>();
    this._customAlerts =
      // @ts-ignore
      customAlerts.length > 0 ? customAlerts.map(alert => new DemoCustomAlert(alert)) : [];
    const geoAlerts = storageUtil('demo-vessel-alerts-geofence', 'GET');
    if (geoAlerts) {
      const parsed = JSON.parse(geoAlerts);
      if (parsed.length > 0)
        this._geofenceAlerts = [
          new DemoGeofenceAlert({
            id: parsed[0].id,
            name: parsed[0].name,
            message: parsed[0].message,
            alertLevel: parsed[0].state,
            notifyUsers: parsed[0].notifyUsers,
            currentValue: parsed[0].currentValue,
          }),
        ];
    }
  };

  retrieveDeviceAlerts = (): DeviceAlertConfig[] => {
    if (this._propertyAlertConfigs.length === 0) {
      demoDevicesController.devices.forEach(device => {
        if (device.state !== 'NORMAL') {
          device.vhs.forEach(vh => {
            if (vh.alertConfig !== null) {
              this._propertyAlertConfigs.push(vh.alertConfig);
            }
          });
        }
      });
    }
    return this._propertyAlertConfigs;
  };

  updateDeviceAlert(type: string, alertConfig: DeviceAlertConfig | null, vhId: number): void {
    const propAlertIndex = this._propertyAlertConfigs.findIndex(
      alertItem => Number(alertItem.alertId.substr(5)) === vhId
    );
    if (propAlertIndex === -1) {
      return;
    }
    if (type === 'delete') {
      this._propertyAlertConfigs.splice(propAlertIndex, 1);
    } else if (type === 'update') {
      this._propertyAlertConfigs[propAlertIndex] = alertConfig!;
    } else if (type === 'new') {
      this._propertyAlertConfigs.push(alertConfig!);
    }
  }

  retrieveAllAlerts = async (): Promise<Alerts[]> => {
    if (this._geofenceAlerts.length === 0) {
      await this.retrieveAlertsData();
    }
    const propertyAlerts: Alerts[] =
      this._propertyAlertConfigs.length > 0
        ? this._propertyAlertConfigs.map(alert => alert.alertResponse())
        : [];
    const customAlerts: Alerts[] =
      this._customAlerts.length > 0 ? this._customAlerts.map(alert => alert.response()) : [];
    const geofenceAlerts: Alerts[] =
      this._geofenceAlerts.length > 0 ? [this._geofenceAlerts[0].response()] : [];
    return [...propertyAlerts, ...customAlerts, ...geofenceAlerts];
  };

  retrieveCustomAlert = (alertId: string): CustomAlertTable | null => {
    const alertFound = this._customAlerts.find(customAlert => customAlert.id === Number(alertId));
    if (alertFound) {
      return alertFound;
    }
    return null;
  };

  createCustomAlert = async (payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }): Promise<void> => {
    const customAlert: CustomAlertTable = { ...defaultCustomAlert, ...payload };
    const res = await DemoDatabase.getInstance().CustomAlert.add(customAlert);
    // @ts-ignore
    this._customAlerts.push(new DemoCustomAlert({ ...customAlert, id: Number(res) }));
  };

  updateCustomAlert = async (
    alertId: string,
    payload: {
      name: string;
      message: string;
      alertLevel: string;
      notifyUsers: string[];
      matchAllConditions: boolean;
      conditions: Condition[];
      repeatOptions: string;
    }
  ): Promise<void> => {
    const alertIndexFound = this._customAlerts.findIndex(
      customAlert => customAlert.id === Number(alertId)
    );
    if (alertIndexFound === -1) {
      return;
    }
    this._customAlerts[alertIndexFound].notifyUsers = payload.notifyUsers?.filter(
      user => user !== undefined
    );
    await DemoDatabase.getInstance().CustomAlert.updatePartial(
      Number(alertId),
      this._customAlerts[alertIndexFound]
    );
  };

  pauseCustomAlert = async (alertId: string, paused: boolean): Promise<void> => {
    await DemoDatabase.getInstance().CustomAlert.updatePartial(Number(alertId), {
      paused: paused,
    });
  };

  deleteCustomAlert = (alertId: string): void => {
    this._customAlerts = this._customAlerts.filter(alert => alert.id !== Number(alertId));
    DemoDatabase.getInstance().CustomAlert.delete(Number(alertId));
  };

  createNewAlert = async (
    type: string,
    id: string,
    name: string,
    msg: string,
    value: string,
    state: string,
    vh?: ValueHolder,
    deviceId?: string
  ): Promise<void> => {
    const newMessage = calcAlertMsg(state, msg, vh);
    const newAlert: VesselAlert = {
      alertType: type,
      state: state,
      id: id,
      name: name,
      message: newMessage,
      currentValue: value, //Might not be needed.
      lastTriggered: new Date().toISOString(),
      acknowledged: false,
      ackUsername: null,
      ackTimestamp: null,
      deviceId: deviceId ?? '1',
      silenced: false,
      silenceUsername: null,
      silenceTimestamp: null,
      description: '',
      notify: false,
      alert: true,
      fired: true,
      lastStateChange: new Date(),
      remind: false,
      remindMinutes: 0,
      notifyUsers: [],
      matchAllConditions: false,
    };
    if (type === 'GEOFENCE') {
      storageUtil('demo-vessel-alerts-geofence', 'POST', JSON.stringify([newAlert]));
      this._geofenceAlerts = [
        new DemoGeofenceAlert({
          alertLevel: newAlert.state,
          id: 123,
          name: newAlert.name,
          message: newAlert.message,
          notifyUsers: newAlert.notifyUsers,
          currentValue: newAlert.currentValue,
        }),
      ];
      return;
    } else if (type === 'PROPERTY') {
      //
    } else if (type === 'CUSTOM') {
      //
    }
    await this.createNewAlertLog(type as AlertType, newAlert, vh);
  };

  removeAlert = async (id: number, type: string): Promise<void> => {
    if (type === 'PROPERTY') {
      await DemoDatabase.getInstance().DeviceAlert.delete(Number(id));
    } else if (type === 'GEOFENCE') {
      this._geofenceAlerts = [];
      storageUtil('demo-vessel-alerts-geofence', 'POST', '[]');
    }
  };

  silenceAlert = async (): Promise<void> => {
    if (this._geofenceAlerts.length === 0) {
      return;
    }
    this._geofenceAlerts[0].silenceAlert();
    storageUtil(
      'demo-vessel-alerts-geofence',
      'POST',
      JSON.stringify([this._geofenceAlerts[0].databaseResponse()])
    );
  };

  ackAlert = async (): Promise<void> => {
    if (this._geofenceAlerts.length === 0) {
      return;
    }
    this._geofenceAlerts[0].ackAlert();
    storageUtil(
      'demo-vessel-alerts-geofence',
      'POST',
      JSON.stringify([this._geofenceAlerts[0].databaseResponse()])
    );
  };

  //Alert Logs
  retrieveAlertLogs = async (): Promise<AlertLog[]> => {
    if (this._logs.length === 0) {
      this._logs = await DemoDatabase.getInstance().VesselAlertLogs.getAllWithDefault();
    }
    return this._logs;
  };

  createNewAlertLog = async (
    type: AlertType,
    alert: VesselAlert,
    valueHolder?: ValueHolder
  ): Promise<void> => {
    const logs = await this.retrieveAlertLogs();
    const newLog: AlertLog & { alertId: string } = {
      ackTimestamp: '',
      ackUsername: '',
      acknowledged: false,
      alertType: type,
      deviceName: valueHolder?.fullName ?? valueHolder?.name ?? '',
      id: (+new Date()).toString(),
      message: alert.message,
      name: alert.name,
      note: '',
      status: alert.state as Status,
      triggered: new Date().toISOString(),
      alertId: alert.id,
    };
    this._logs = [newLog, ...logs];
    DemoDatabase.getInstance().VesselAlertLogs.add(newLog);
  };
}
