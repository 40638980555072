import { Property } from 'csstype';
import React, { ReactElement } from 'react';

type Props = {
  children: unknown;
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  flex?: Property.Flex;
  flexFlow?: Property.FlexFlow;
  className?: string;
  style?: React.CSSProperties;
};

function FlexContainer(props: Props): ReactElement {
  const { children, className, style, ...flexParams } = props;
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        ...flexParams,
        ...style,
      }}>
      {children as ReactElement}
    </div>
  );
}

export default FlexContainer;
