import React, { createContext, useEffect, useRef } from 'react';

interface TabNameContextInterface {
  setDirectTabName?: (name: string) => void;
  setToDefault?: () => void;
}

const TabNameContext = createContext<TabNameContextInterface>({});

const TabNameContextProvider = (props: { children: JSX.Element }) => {
  const isDirectTabNameSet = useRef(false);

  useEffect(() => {
    setToDefault();
  }, []);

  const setToDefault = () => {
    if (!window.location.hostname.includes('.link')) {
      document.title = 'LINK Cloud (local server)';
    } else {
      document.title = 'LINK Smart Vessel Solutions';
    }
  };

  const setDirectTabName = (name: string) => {
    if (window.location.hostname.includes('.link')) {
      document.title = `${name} | LINK`;
      isDirectTabNameSet.current = true;
    }
  };

  return (
    <TabNameContext.Provider value={{ setDirectTabName, setToDefault }}>
      {props.children}
    </TabNameContext.Provider>
  );
};

export { TabNameContext, TabNameContextProvider };
