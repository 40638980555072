import 'styles/_fonts.scss';

import brandImage from 'assets/images/modal-top-image.jpg';
import React, { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { brandBlue, brandDarkGrey, standardWhite } from 'styles/colors';

import useBreakpoint from '../../hooks/useBreakpoint';

type Props = {
  children: React.ReactNode;
  handleClose?: (close: boolean) => void;
  title?: string;
  display?: string;
  handleBack?: (previousStep: string) => void;
  previousStep?: string;
  modalRef?: React.MutableRefObject<HTMLInputElement | null>;
  invisible?: boolean;
  responsive?: boolean;
};

export const MODAL_MED = 550;
export const MODAL_SMALL = 450;
export const MODAL_SMALLER = 400;
export const MODAL_SMALLEST = 350;

const Modal = (props: Props): ReactElement => {
  const {
    children,
    handleClose,
    title,
    display,
    handleBack,
    previousStep,
    modalRef,
    invisible,
    responsive,
  } = props;
  const [width, setWidth] = useState<string>('460px');
  const top = 'linear-gradient(0deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 1) 100%)';
  const bottom = 'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.01) 100%)';
  const [logoSize, setLogoSize] = useState('100px');
  const [isVisible, setIsVisible] = useState(true);
  const [imageContainerTop, setImageContainerTop] = useState('76px');
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (responsive) {
      if (breakpoint <= MODAL_MED && breakpoint > MODAL_SMALL) {
        setWidth('420px');
        setLogoSize('80px');
        setImageContainerTop('70px');
      } else if (breakpoint <= MODAL_SMALL && breakpoint > MODAL_SMALLER) {
        setWidth('420px');
        setLogoSize('80px');
        setImageContainerTop('70px');
      } else if (breakpoint <= MODAL_SMALLER && breakpoint > MODAL_SMALLEST) {
        setWidth('420px');
        setLogoSize('80px');
        setImageContainerTop('70px');
      } else if (breakpoint <= MODAL_SMALLEST) {
        setWidth('300px');
        setLogoSize('70px');
        setImageContainerTop('40px');
      } else {
        setWidth('460px');
        setLogoSize('100px');
        setImageContainerTop('70px');
      }
    }
  }, [breakpoint]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleCloseModal = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (handleClose) {
        handleClose(false);
      }
    }, 300);
  };

  return (
    <Main
      isVisible={isVisible}
      style={{ display: display ?? 'flex', visibility: invisible ? 'hidden' : 'visible' }}>
      <Card width={width} isVisible={isVisible}>
        <CardEssentials>
          <Image src={brandImage} />
          {handleBack && previousStep ? (
            <BackButton onClick={() => handleBack(previousStep)}>
              <ArrowCorner />
              <ArrowLine />
            </BackButton>
          ) : null}
          {handleClose && (
            <CloseButton onClick={() => handleCloseModal()}>
              <StickContainer1>
                <Stick />
              </StickContainer1>
              <StickContainer2>
                <Stick />
              </StickContainer2>
            </CloseButton>
          )}
          <ImageContainer top={imageContainerTop}>
            <ImagePadding>
              <Logo size={logoSize}>LINK</Logo>
            </ImagePadding>
          </ImageContainer>
        </CardEssentials>
        {title ? <Title>{title}</Title> : null}
        <FadeEdge style={{ marginBottom: '-20px', background: top }} />
        <Form ref={modalRef}>{children}</Form>
        <FadeEdge style={{ marginTop: '-20px', background: bottom }} />
      </Card>
    </Main>
  );
};

export default Modal;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`;

const mainFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const mainFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Main = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  animation: ${({ isVisible }) => (isVisible ? mainFadeIn : mainFadeOut)} 0.3s ease-in-out forwards;
`;

const Card = styled.div<{ isVisible: boolean; width: string }>`
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s ease-in-out forwards;
  width: ${props => props.width};
  min-width: ${props => props.width};
  height: fit-content;
  min-height: 360px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: ${standardWhite};
  border-radius: 10px;
  border: 1px solid #56565a;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
`;

const ImageContainer = styled.div<{ top: string }>`
  width: 100%;
  position: absolute;
  top: ${props => props.top};
`;

const ImagePadding = styled.div`
  padding: 8px;
  border-radius: 50%;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  background: ${standardWhite};
  overflow: hidden;
`;

const Logo = styled.div<{ size: string }>`
  border-radius: 50%;
  background: ${brandBlue};
  width: ${props => props.size};
  height: ${props => props.size};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  user-select: none;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 550px) {
    font-size: 30px;
  }
  @media screen and (max-width: 350px) {
    font-size: 26px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Form = styled.div`
  position: relative;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: normal;
  scrollbar-color: ${brandBlue} rgb(230, 233, 236);
  max-height: 60vh;
  @media screen and (max-height: 680px) {
    max-height: 52vh;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgb(230, 233, 236);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${brandBlue};
  }
`;

const CardEssentials = styled.div`
  position: relative;
  height: 200px;
  @media screen and (max-width: 550px) {
    height: 180px;
  }
  @media screen and (max-width: 350px) {
    height: 140px;
  }
`;

const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  transition: 0.1s;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const StickContainer1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 24px;
  div {
    transform: rotate(45deg);
  }
`;

const StickContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 24px;
  div {
    transform: rotate(-45deg);
  }
`;

const Stick = styled.div`
  width: 3px;
  height: 24px;
  background: ${standardWhite};
  border-radius: 4px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  color: ${brandDarkGrey};
`;

const BackButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const ArrowCorner = styled.div`
  width: 15px;
  height: 15px;
  border-left: 3px solid ${standardWhite};
  border-bottom: 3px solid ${standardWhite};
  border-radius: 2px;
  transform: rotate(45deg);
`;

const ArrowLine = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  width: 3px;
  height: 20px;
  background: ${standardWhite};
  border-radius: 1px;
  transform: rotate(90deg);
`;

const FadeEdge = styled.div`
  height: 20px;
  width: 94%;
  position: relative;
  z-index: 2;
  margin-left: 10px;
  pointer-events: none;
`;
