import arrowDown from 'assets/images/arrow-down-white.png';
import arrowLeft from 'assets/images/arrow-left-white.png';
import arrowRight from 'assets/images/arrow-right-white.png';
import arrowUp from 'assets/images/arrow-up-white.png';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { brandBlue } from 'styles/colors';

type Props = {
  direction: string;
  cb: (dir: string) => void;
  disabled?: boolean;
};

const Arrow = (props: Props): JSX.Element => {
  let src;
  const [mouseDown, setMouseDown] = useState(false);

  //arrow keydown functionality
  /*
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (props.disabled) return;
      switch (event.key) {
        case 'ArrowUp':
          props.cb('up');
          break;
        case 'ArrowDown':
          props.cb('down');
          break;
        case 'ArrowLeft':
          props.cb('left');
          break;
        case 'ArrowRight':
          props.cb('right');
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.disabled]); */

  useEffect(() => {
    let interval: number;
    if (mouseDown && !props.disabled) {
      interval = window.setInterval(() => {
        props.cb(props.direction);
      }, 0);
    }

    return () => clearInterval(interval);
  }, [mouseDown]);

  switch (props.direction) {
    case 'up':
      src = arrowUp;
      break;
    case 'down':
      src = arrowDown;
      break;
    case 'left':
      src = arrowLeft;
      break;
    case 'right':
      src = arrowRight;
      break;
  }

  const theme = {
    backgroundColor: props.disabled ? '#e9e9e9' : brandBlue,
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <img
          src={src}
          alt="Arrow"
          onMouseDown={() => setMouseDown(true)}
          onMouseUp={() => setMouseDown(false)}
          onMouseLeave={() => setMouseDown(false)}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Arrow;

const Wrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 3px;
  padding: 3px;
`;
