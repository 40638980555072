import React from 'react';
import styled from 'styled-components';
import { brandLightGrey, brandRed } from 'styles/colors';

const Main = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${brandRed};
  cursor: pointer;
`;

const StickWrapper = styled.div`
  position: absolute;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    transform: rotate(45deg);
  }
  &:last-child {
    transform: rotate(-45deg);
  }
`;

const Stick = styled.div`
  width: 2px;
  height: 14px;
  background: #fff;
`;

const DeleteButton = (): JSX.Element => {
  return (
    <Main>
      <StickWrapper>
        <Stick />
      </StickWrapper>
      <StickWrapper>
        <Stick />
      </StickWrapper>
    </Main>
  );
};
export default DeleteButton;

export const DeleteButtonInactive = (): JSX.Element => {
  return (
    <Main style={{ background: brandLightGrey, cursor: 'unset' }}>
      <StickWrapper>
        <Stick />
      </StickWrapper>
      <StickWrapper>
        <Stick />
      </StickWrapper>
    </Main>
  );
};
