import { AlertPaginatedType, GetVesselAuditPaginatedInterface } from 'api/selectedVessel';
import { DemoAlertCache } from 'demo/caches/AlertCache';
import { CustomAlertTable } from 'demo/persistentStorage/database/tables';
import { Alerts, VesselLog } from 'store/selectedVessel/types';
import { Condition } from 'store/vesselRoutines/types';
import { AlertLog } from 'types/logs';

export type CustomAlertI = CustomAlertTable & {
  id: number;
};

class DemoAlertController {
  private static instance: DemoAlertController | null = null;

  public static getInstance(): DemoAlertController {
    if (this.instance === null) {
      this.instance = new DemoAlertController();
    }
    return this.instance;
  }

  retrieveAllAlerts = async (): Promise<Alerts[]> => {
    return DemoAlertCache.getInstance().retrieveAllAlerts();
  };

  retrieveCustomAlert = (alertId: string): CustomAlertTable | null => {
    return DemoAlertCache.getInstance().retrieveCustomAlert(alertId);
  };

  createCustomAlert = async (payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }): Promise<void> => {
    DemoAlertCache.getInstance().createCustomAlert(payload);
  };

  updateCustomAlert = async (
    alertId: string,
    payload: {
      name: string;
      message: string;
      alertLevel: string;
      notifyUsers: string[];
      matchAllConditions: boolean;
      conditions: Condition[];
      repeatOptions: string;
    }
  ): Promise<void> => {
    DemoAlertCache.getInstance().updateCustomAlert(alertId, payload);
  };

  pauseCustomAlert = async (alertId: string, paused: boolean): Promise<void> => {
    await DemoAlertCache.getInstance().pauseCustomAlert(alertId, paused);
  };

  deleteCustomAlert = (alertId: string): void => {
    DemoAlertCache.getInstance().deleteCustomAlert(alertId);
  };

  silenceAlert = async (): Promise<void> => {
    DemoAlertCache.getInstance().silenceAlert();
  };

  ackAlert = async (): Promise<void> => {
    DemoAlertCache.getInstance().ackAlert();
  };

  retrievePage = async (data: AlertPaginatedType) => {
    const logItems = await DemoAlertCache.getInstance().retrieveAlertLogs();
    const logController = new LogsController();
    const page = logController.retrievePage(logItems, data, 'triggered') as AlertLog[];
    return logController.mergeAlertLogData(page, data);
  };
}

export default DemoAlertController;

export class LogsController {
  retrievePage = (
    logs: AlertLog[] | VesselLog[],
    data: AlertPaginatedType | GetVesselAuditPaginatedInterface,
    timestampKey: 'triggered' | 'timestamp'
  ): AlertLog[] | VesselLog[] => {
    return [
      // @ts-ignore
      ...logs.filter((log: AlertLog | VesselLog) => {
        const logDateRetrieved =
          // @ts-ignore
          log[timestampKey] ?? this.subtractTimeFromDate(new Date(), 24).toISOString();
        const dateToRetrieved = data.dateTo;
        const logDate = +new Date(logDateRetrieved);
        let fromDate = +new Date(data.dateFrom);
        let toDate = +new Date(dateToRetrieved);
        if ('timeFrame' in data && data.timeFrame) {
          toDate = +new Date();
          let time = this.subtractTimeFromDate(new Date(), 1);
          switch (data.timeFrame) {
            case 1:
              fromDate = +time;
              break;
            case 4:
              time = this.subtractTimeFromDate(new Date(), 4);
              fromDate = +time;
              break;
            case 6:
              time = this.subtractTimeFromDate(new Date(), 6);
              fromDate = +time;
              break;
          }
        }
        if (!fromDate && !toDate) {
          return true;
        }
        return logDate > +new Date(fromDate) && logDate < +new Date(toDate);
      }),
    ];
  };

  mergeAlertLogData = (
    logItems: AlertLog[] | VesselLog[],
    data: AlertPaginatedType | GetVesselAuditPaginatedInterface
  ) => {
    const startIndex = data.page * data.size;
    const endIndex = data.page * data.size + data.size;
    const items =
      'status' in data && data.status
        ? (logItems as AlertLog[]).filter((logItem: AlertLog) => {
            if (data.status === 'WARNING') {
              return logItem.status === 'WARNING';
            } else if (data.status === 'CRITICAL') {
              return logItem.status === 'CRITICAL';
            }
            return false;
          })
        : logItems;

    return {
      content: items.slice(startIndex, endIndex),
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageNumber: data.page,
        pageSize: data.size,
        unpaged: false,
        paged: true,
      },
      last: false,
      totalElements: logItems.length,
      totalPages: (logItems.length - 1) / data.size,
      number: 0,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      size: 10,
      first: true,
      numberOfElements: 10,
      empty: false,
    };
  };

  subtractTimeFromDate = (objDate: Date, hours: number): Date => {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = hours * 60 * 60 * 1000;
    return new Date(numberOfMlSeconds - addMlSeconds);
  };
}
