import BridgeDeckAft from 'assets/DemoCCTVs/BridgeDeckAft.mp4';
import Engines from 'assets/DemoCCTVs/Engines.mp4';
import GeneratorPort from 'assets/DemoCCTVs/GeneratorPort.mp4';
import GeneratorStbd from 'assets/DemoCCTVs/GeneratorStbd.mp4';
import MainDeckPort from 'assets/DemoCCTVs/MainDeckPort.mp4';
import MainDeckStbd from 'assets/DemoCCTVs/MainDeckStbd.mp4';

export const getItemsWithNextId = (storageKey: string): { items: any[]; nextId: number } => {
  const currentArray = localStorage.getItem(storageKey);
  if (!currentArray) {
    return { items: [], nextId: 1 };
  }
  const parsedArray = JSON.parse(currentArray);
  if (parsedArray.length === 0) {
    return { items: [], nextId: 1 };
  }
  const lastId = parsedArray[parsedArray.length - 1].id;
  if (isNaN(lastId)) {
    return { items: parsedArray, nextId: 1 };
  }
  return { items: parsedArray, nextId: lastId + 1 };
};

export const getItems = (storageKey: string): any[] => {
  const currentArray = localStorage.getItem(storageKey);
  if (!currentArray) {
    return [];
  }
  const parsedArray = JSON.parse(currentArray);
  if (parsedArray.length === 0) {
    return [];
  }
  return parsedArray;
};

export const retrieveArray = <T>(storageKey: string): T[] => {
  const currentArray = localStorage.getItem(storageKey);
  if (!currentArray) {
    return [];
  }
  return JSON.parse(currentArray);
};

export const retrieveDefaultData = <T>(store: string): T[] => {
  switch (store) {
    case 'Vessel':
      return [require('demo/data/VesselFull.json')];
    case 'VesselUsers':
      return require('demo/data/access/Permissions.json');
    case 'VesselCameras':
      return cameras as unknown as T[];
    case 'VesselAccess':
      return require('demo/data/access/AppSettings.json');
    case 'VesselLogs':
      return [
        {
          id: 1,
          objectId: '',
          objectName: 'System',
          messageType: 'SYSTEM',
          message: 'Demo data loaded',
          timestamp: new Date().toISOString(),
          location: '188.30.79.128',
        },
      ] as unknown as T[];
    case 'VesselAlertLogs':
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const defaultAlertLogs = require('demo/data/logs/Week/AlertLogs.json');
      return updateTimestampsInDescendingOrder(defaultAlertLogs);
    default:
      return [];
  }
};

function updateTimestampsInDescendingOrder(jsonData: any[]) {
  const timestamps: string[] = [];
  for (let i = 0; i < jsonData.length; i++) {
    timestamps.push(getRandomTimestampWithinLast6Hours());
  }
  timestamps.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  jsonData.forEach((item, index) => {
    item.triggered = timestamps[index];
  });

  return jsonData;
}

function getRandomTimestampWithinLast6Hours() {
  const now = Date.now();
  const sixHoursAgo = now - 6 * 60 * 60 * 1000;
  return new Date(sixHoursAgo + Math.random() * (now - sixHoursAgo)).toISOString();
}

const defaultCamera = {
  authPassword: '',
  authUsername: '',
  flipH: false,
  flipV: false,
  id: 0,
  location: 'No Location',
  mic: false,
  port: 80,
  protocol: 'MJPEG',
  ptz: false,
  relativeZoom: false,
  requiresAuth: false,
  streamPath: '.',
  streamType: 'NORMAL',
  zoom: false,
};

const cameras = [
  {
    ...defaultCamera,
    ipAddress: BridgeDeckAft,
    location: 'No Location',
    name: 'Bridge Deck Aft',
    id: 0,
  },
  {
    ...defaultCamera,
    ipAddress: Engines,
    location: 'No Location',
    name: 'Engines',
    id: 1,
  },
  {
    ...defaultCamera,
    ipAddress: GeneratorPort,
    location: 'No Location',
    name: 'Generator Port',
    id: 2,
  },
  {
    ...defaultCamera,
    ipAddress: GeneratorStbd,
    location: 'No Location',
    name: 'Generator Stbd',
    id: 3,
  },
  {
    ...defaultCamera,
    ipAddress: MainDeckPort,
    location: 'No Location',
    name: 'Main Deck Port',
    id: 4,
  },
  {
    ...defaultCamera,
    ipAddress: MainDeckStbd,
    location: 'No Location',
    name: 'Main Deck Stbd',
    id: 5,
  },
];
