import AdvancedDevice from 'demo/classes/AdvancedDevice';
import DemoCamerasController from 'demo/controllers/camerasController';
import DemoDatabase from 'demo/persistentStorage';
import {
  SimpleDeviceMetaDataTable,
  SimpleDeviceTable,
} from 'demo/persistentStorage/database/tables';
import { demoDevicesController } from 'demo/provider';
import {
  constructHydraulic,
  constructMainSwitchboard,
  constructTank,
  generateLinkBridge,
  getAllSimpleDevicesStorageDb,
} from 'demo/utility';
import {
  SimpleDevice,
  SimpleDeviceFieldWrapper,
  SimpleDeviceLocationResponse,
} from 'types/simpleDevices';

export async function flatDevices(): Promise<SimpleDevice[]> {
  return await getAllSimpleDevicesStorageDb();
}

export async function retrieveSimpleDevicesBySystem(
  system: string
): Promise<{ devices: SimpleDevice[]; locations: SimpleDeviceLocationResponse[] }> {
  if (system === 'LINK') {
    const linkBridgeDevice = demoDevicesController.retrieveDeviceInfo('1');
    if (linkBridgeDevice) {
      return { devices: [generateLinkBridge(linkBridgeDevice)], locations: [] };
    }
  }
  if (system === 'CAMERA') {
    const cameras = await DemoCamerasController.getInstance().retrieveCameras();
    return {
      devices: cameras.map(camera => {
        return {
          location: camera.location,
          name: camera.name,
          id: camera.id,
          warningState: 'NORMAL',
          manufacturer: 'null',
          model: 'null',
          serial: 'null',
          system: 'CAMERA',
          subSystem: 'CCTV',
          controllable: false,
          online: true,
          displayComponent: 'camera',
          fields: [],
          selectable: false,
          state: 'NORMAL',
          templateName: 'Camera',
          metaData: { capacity: '' },
          friendlyName: camera.name,
        } as SimpleDevice;
      }),
      locations: [],
    };
  }
  let locations: SimpleDeviceLocationResponse[] = [];
  const devices = (await DemoDatabase.getInstance().SimpleDevice.where<SimpleDeviceTable>(
    'systemIndex',
    system
  )) as unknown as SimpleDevice[];
  for (let i = 0; i < devices.length; i++) {
    let locationActive = false;
    devices[i].fields = [];
    devices[i].online = true;
    devices[i].warningState = 'NORMAL';
    const fields = (await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.where(
      'deviceIdIndex',
      devices[i].id
    )) as SimpleDeviceFieldWrapper[];
    const uniqueDevices = new Set<AdvancedDevice>();
    for (let j = 0; j < fields.length; j++) {
      demoDevicesController.devices.forEach(advancedDevice => {
        const vhFound = advancedDevice.vhs.find(vh => vh.id === Number(fields[j].dpvhId));
        if (!vhFound) {
          return;
        }
        if (!uniqueDevices.has(advancedDevice)) {
          uniqueDevices.add(advancedDevice);
          advancedDevice.randomUpdate();
        }
        const state = vhFound.alertConfig !== null ? vhFound.alertConfig.state : 'NORMAL';
        if (state === 'CRITICAL' && devices[i].warningState !== 'CRITICAL') {
          devices[i].warningState = 'CRITICAL';
        } else if (state === 'WARNING' && devices[i].warningState === 'NORMAL') {
          devices[i].warningState = 'WARNING';
        }
        devices[i].fields.push({
          ...fields[j],
          value: vhFound.value,
          parsedValue: vhFound.getParsedValue(),
          displayValue: vhFound.retrieveDisplayValue(),
          deviceOnline: true,
          displayName: vhFound.name,
          // @ts-ignore
          dictionary: vhFound.dictionary,
          dpvhName: vhFound.name,
          gaugeLow: vhFound.gaugeLow,
          gaugeHigh: vhFound.gaugeHigh,
          warningState: state,
        });
        if (
          vhFound.value !== '0' &&
          (system === 'LIGHTING'
            ? fields[j].fieldName === 'state' || fields[j].fieldName === 'currentPosition'
            : fields[j].fieldName === 'currentPosition')
        ) {
          locationActive = true;
        }
      });
    }
    if (system === 'TANK') {
      const metaFields =
        await DemoDatabase.getInstance().SimpleDeviceMetaData.where<SimpleDeviceMetaDataTable>(
          'simpleDeviceIdIndex',
          devices[i].id
        );
      const metaFieldsUpdated = constructTank(metaFields, devices[i].fields[0] ?? undefined);
      devices[i].fields.push(...metaFieldsUpdated);
    } else if (devices[i].system === 'CUSTOM_DASHBOARDS') {
      if (devices[i].subSystem === 'MAIN_SWITCHBOARD') {
        const metaFields =
          await DemoDatabase.getInstance().SimpleDeviceMetaData.where<SimpleDeviceMetaDataTable>(
            'simpleDeviceIdIndex',
            devices[i].id
          );
        const mainSwitchboardFields = constructMainSwitchboard(metaFields);
        devices[i].fields.push(...mainSwitchboardFields);
      } else if (devices[i].subSystem === 'HYDRAULIC') {
        const metaFields =
          await DemoDatabase.getInstance().SimpleDeviceMetaData.where<SimpleDeviceMetaDataTable>(
            'simpleDeviceIdIndex',
            devices[i].id
          );
        const hydraulicFields = constructHydraulic(metaFields);
        devices[i].fields.push(...hydraulicFields);
      }
    }
    locations = retrieveLocation(system, locations, devices, locationActive, i);
  }
  return { devices, locations };
}

export const retrieveLocation = (
  system: string,
  locations: SimpleDeviceLocationResponse[],
  devices: SimpleDevice[],
  locationActive: boolean,
  index: number
): SimpleDeviceLocationResponse[] => {
  if (system === 'LIGHTING' || system === 'SHADES') {
    const locationIndexFound = locations.findIndex(
      location => location.name === devices[index].location
    );
    if (locationIndexFound !== -1) {
      locations[locationIndexFound] = {
        ...locations[locationIndexFound],
        devices: [...locations[locationIndexFound].devices, devices[index] as SimpleDevice],
        activeCount: locations[locationIndexFound].activeCount + (locationActive ? 1 : 0),
        statusValue:
          locations[locationIndexFound].activeCount + (locationActive ? 1 : 0)
            ? system === 'SHADES'
              ? 'CLOSED'
              : 'ON'
            : system === 'SHADES'
            ? 'OPEN'
            : 'OFF',
      };
    } else {
      locations.push({
        activeCount: locationActive ? 1 : 0,
        statusValue: locationActive
          ? system === 'SHADES'
            ? 'CLOSED'
            : 'ON'
          : system === 'SHADES'
          ? 'OPEN'
          : 'OFF',
        name: devices[index].location,
        devices: [devices[index] as SimpleDevice],
      });
    }
  }
  return locations;
};
