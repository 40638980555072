import { getVesselRoute } from 'api/vessels';
import anchor from 'assets/images/anchor-circle.png';
import VesselMarker from 'components/VesselMarker';
import { VesselState } from 'components/VesselMarker/types';
import GoogleMapReact, { MapOptions, Point } from 'google-map-react';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MyThunkDispatch } from 'store';
import { saveError } from 'store/local/actions';
import { VesselType } from 'store/vessels/types';
import styled from 'styled-components';
import { brandAmber, brandBlue, brandRed } from 'styles/colors';
import { CustomWindow } from 'types/globalTypes';

import { BannerContext } from '../../../../../../../context/BannerContext';

type Props = {
  latitude: string | undefined;
  longitude: string | undefined;
  gpsReporting: boolean;
  radius: number | undefined;
  centreLat: string | undefined;
  centreLon: string | undefined;
  isVesselInside: boolean;
  isVesselInsideWarning: boolean;
  geofenceEnabled: boolean;
  state: VesselState | undefined;
  vesselId: string;
  loading: boolean;
  type: VesselType;
};

type Route = {
  timestamp: string;
  latitude: string;
  longitude: string;
  speed: number;
  heading: number;
  depth: number;
  id: number;
};

declare let window: CustomWindow;

function LocationMap(props: Props): ReactElement {
  const {
    latitude,
    longitude,
    radius,
    centreLat,
    centreLon,
    isVesselInside,
    isVesselInsideWarning,
    state,
    geofenceEnabled,
    vesselId,
  } = props;
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const [circle, setCircle] = useState(null);
  const [warningCircle, setWarningCircle] = useState(null);
  const geoCentre =
    centreLat && centreLon
      ? { lat: Number(centreLat), lng: Number(centreLon) }
      : { lat: 42.747012, lng: -35.507812 };
  const [mapLoaded, setMapLoaded] = useState(false);
  const [anchorMarker, setAnchorMarker] = useState<any>(null);
  const [path, setPath] = useState(null);
  const [coords, setCoords] = useState<Route[] | null>(null);
  const dispatch: MyThunkDispatch = useDispatch();
  const [mapAdjusted, setMapAdjusted] = useState(false);
  const { setType, setMessage, reset } = useContext(BannerContext);

  useEffect(() => {
    if (isVesselInside && !isVesselInsideWarning && geofenceEnabled) {
      setType('WARNING');
      setMessage('Vessel is nearly outside the Anchor Alarm Zone');
    } else if (!isVesselInside) {
      setType(geofenceEnabled ? 'CRITICAL' : 'GENERAL');
      setMessage('Vessel is outside the Anchor Alarm Zone');
    } else {
      reset();
    }
    return () => {
      reset();
    };
  }, [isVesselInside, isVesselInsideWarning, geofenceEnabled]);
  const fetchRoute = useCallback((): void => {
    getVesselRoute(vesselId)
      .then(res => {
        res.ok && setCoords(res.data);
      })
      .catch(err => dispatch(saveError(err)));
  }, [dispatch, latitude, longitude]);

  useEffect(() => {
    fetchRoute();
  }, [fetchRoute]);

  const initializeMap = (): void => {
    setMapLoaded(true);
  };

  const loadGoogleMapsAPI = (): void => {
    if (window.google && window.google.maps) {
      initializeMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY ?? ''
      }`;
      script.onload = () => setMapLoaded(true);
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadGoogleMapsAPI();
  }, []);

  const lat = latitude;
  const lon = longitude;

  useEffect(() => {
    if (mapReference && mapsReference) {
      if (circle) {
        // @ts-ignore
        circle.setRadius(radius);
        // @ts-ignore
        circle.setCenter(geoCentre);
        // @ts-ignore
        circle.setOptions({
          strokeColor: geofenceEnabled ? brandRed : '#5e5f64',
          fillColor: geofenceEnabled
            ? isVesselInside && isVesselInsideWarning
              ? '#001a3e'
              : isVesselInside && !isVesselInsideWarning
              ? brandAmber
              : brandRed
            : '#e9e9e9',
          fillOpacity: 0.2,
          strokeOpacity: 1,
        });
      } else {
        // @ts-ignore
        const myCircle = new mapsReference.Circle({
          strokeColor: geofenceEnabled ? (isVesselInside ? brandBlue : brandRed) : brandBlue,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: geofenceEnabled
            ? isVesselInside && isVesselInsideWarning
              ? brandBlue
              : isVesselInside && !isVesselInsideWarning
              ? brandAmber
              : brandRed
            : '#e9e9e9',
          fillOpacity: 0.2,
          map: mapReference,
          center: geoCentre,
          radius: radius,
        });
        setCircle(myCircle);
      }

      if (radius) {
        const warningRadius = radius * 0.8;
        if (warningCircle) {
          // @ts-ignore
          warningCircle.setRadius(warningRadius);
          // @ts-ignore
          warningCircle.setCenter(geoCentre);
          // @ts-ignore
          warningCircle.setOptions({
            strokeColor: geofenceEnabled ? brandAmber : 'transparent',
            fillColor: 'transparent',
            strokeOpacity: 1,
            strokeWeight: 2,
          });
        } else {
          // @ts-ignore
          const myWarningCircle = new mapsReference.Circle({
            strokeColor: geofenceEnabled ? brandAmber : 'transparent',
            fillColor: 'transparent',
            map: mapReference,
            center: geoCentre,
            radius: warningRadius,
          });
          setWarningCircle(myWarningCircle);
        }
      }

      if (anchorMarker) {
        anchorMarker.setPosition(geoCentre);
        anchorMarker.setMap(geofenceEnabled ? mapReference : null);
      } else if (geofenceEnabled) {
        const anchorIcon = {
          url: anchor,
          // @ts-ignore
          scaledSize: new mapsReference.Size(32, 32),
          // @ts-ignore
          anchor: new mapsReference.Point(16, 16),
        };
        // @ts-ignore
        const marker = new mapsReference.Marker({
          position: geoCentre,
          map: mapReference,
          icon: anchorIcon,
        });
        setAnchorMarker(marker);
      }

      if (!mapAdjusted && circle && !props.loading && geofenceEnabled) {
        // @ts-ignore
        const bounds = new mapsReference.LatLngBounds();
        // @ts-ignore
        bounds.extend(circle.getBounds().getNorthEast());
        // @ts-ignore
        bounds.extend(circle.getBounds().getSouthWest());
        bounds.extend({ lat: Number(lat), lng: Number(lon) });
        const padding = { top: 50, right: 50, bottom: 50, left: 50 };
        // @ts-ignore
        mapReference.fitBounds(bounds, padding);
        setTimeout(() => {
          setMapAdjusted(true);
        }, 1000);
      }
    }
  }, [
    circle,
    geoCentre,
    mapReference,
    mapsReference,
    radius,
    isVesselInside,
    isVesselInsideWarning,
    geofenceEnabled,
    lat,
    lon,
  ]);

  useEffect(() => {
    if (mapReference && mapsReference && coords && geofenceEnabled) {
      const newCoords = coords
        .map(location => {
          const { latitude, longitude } = location;
          if (isFinite(Number(latitude)) && isFinite(Number(longitude))) {
            return { lat: Number(latitude), lng: Number(longitude) };
          } else {
            return null;
          }
        })
        .filter(coord => coord !== null);
      if (newCoords.length > 0) {
        if (path) {
          // @ts-ignore
          path.setPath(newCoords);
        } else {
          // @ts-ignore
          const newPath = new google.maps.Polyline({
            path: newCoords,
            geodesic: true,
            strokeColor: brandBlue,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          newPath.setMap(mapReference);
          setPath(newPath);
        }
      }
    }
  }, [mapReference, mapsReference, coords, geofenceEnabled, path]);

  useEffect(() => {
    if (!geofenceEnabled) {
      setCoords(null);
      if (path) {
        // @ts-ignore
        path.setMap(null);
        setPath(null);
      }
    }
  }, [geofenceEnabled]);

  const zoom = 14;

  const getMapOptions = (): MapOptions => {
    return {
      disableDefaultUI: true,
      mapTypeControl: false,
      streetViewControl: false,
      restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        strictBounds: true,
      },
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
      zoomControl: true,
      controlSize: 24,
    };
  };

  return (
    <Main>
      {mapLoaded ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? '' }}
          center={geoCentre}
          defaultZoom={zoom}
          options={getMapOptions}
          onZoomAnimationStart={() => {
            if (lat && !isNaN(Number(lat)) && lon && !isNaN(Number(lon)) && mapAdjusted) {
              // @ts-ignore
              mapReference?.panTo({ lat: Number(lat), lng: Number(lon) });
            }
          }}
          onZoomAnimationEnd={() => {
            if (lat && !isNaN(Number(lat)) && lon && !isNaN(Number(lon)) && mapAdjusted) {
              // @ts-ignore
              mapReference?.panTo({ lat: Number(lat), lng: Number(lon) });
            }
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            setMapReference(map);
            setMapsReference(maps);
            const imageMapType = new maps.ImageMapType({
              getTileUrl: function (coord: Point, zoom: number) {
                if (zoom < 10) {
                  return '';
                } else return `https://t1.openseamap.org/seamark/${zoom}/${coord.x}/${coord.y}.png`;
              },
              tileSize: new maps.Size(256, 256),
            });
            map.overlayMapTypes.push(imageMapType);
          }}>
          {lat && lon && (
            <VesselMarker type={props.type} lat={Number(lat)} lng={Number(lon)} state={state} />
          )}
        </GoogleMapReact>
      ) : (
        <MapOverlay>Loading map...</MapOverlay>
      )}
    </Main>
  );
}

export default LocationMap;

const Main = styled.div`
  display: flex;
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
  max-height: 80vh;
  position: relative;
`;

const BannerWrapper = styled.div`
  position: absolute;
  z-index: 9;
  width: calc(100% - 20px);
  margin: 0 10px;
`;

const MapOverlay = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #8ab4f8;
  font-size: 20px;
  color: #fff;
  text-align: center;
`;
