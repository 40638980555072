import Button from 'components/Button';
import Input from 'components/Input';
import React from 'react';
import styled from 'styled-components';

import Arrow from './Arrow';

type Props = {
  cb: (dir: string) => void;
  lat: string;
  lon: string;
  centreLat: string;
  centreLong: string;
  recentreCb: () => void;
  geofenceEnabled: boolean;
  stateUpdate: () => void;
  updateDelay: (newVal: number) => void;
  updateRadius: (newVal: number) => void;
  delay: number;
  radius: number;
  dispatchPending: boolean;
  loading: boolean;
  disabled?: boolean;
};

const Controller = (props: Props): JSX.Element => {
  const {
    centreLat,
    centreLong,
    lat,
    lon,
    cb,
    recentreCb,
    geofenceEnabled,
    stateUpdate,
    updateDelay,
    updateRadius,
    radius,
    delay,
    loading,
    disabled,
  } = props;

  function numberValueCheck(
    e: React.ChangeEvent<HTMLInputElement>,
    currentValue: number,
    additionalCheck?: boolean
  ): number {
    const regex = /^-?\d*\.?\d*$/;
    const newInput = e.target.value;
    let isValidInput = regex.test(newInput);
    if (additionalCheck !== undefined) {
      isValidInput = regex.test(newInput) && additionalCheck;
    }
    return isValidInput || newInput === '' ? Number(newInput) : currentValue;
  }

  return (
    <ControllerWrapper>
      <TopRow>
        <Button
          loading={loading}
          disabled={props.dispatchPending || disabled}
          defaultChecked={geofenceEnabled}
          variant={geofenceEnabled ? 'error' : 'info'}
          onClick={stateUpdate}>
          {geofenceEnabled ? 'Deactivate Anchor Alarm' : 'Activate Anchor Alarm'}
        </Button>
        <InputsWrapper>
          <Input
            placeholder={`Radius ${radius < 10 ? '>= 10m' : radius > 1000 ? '<= 1000m' : '(m)'}`}
            width={'calc(50% - 10px)'}
            value={radius}
            data={radius.toString()}
            disabled={disabled}
            onChange={e => {
              const newValue = numberValueCheck(e, radius);
              updateRadius(newValue);
            }}
          />
          <Input
            placeholder={'Alert Delay'}
            width={'50%'}
            value={delay}
            data={delay.toString()}
            disabled={disabled}
            onChange={e => {
              const newValue = numberValueCheck(e, delay);
              updateDelay(newValue);
            }}
          />
        </InputsWrapper>
      </TopRow>
      <Arrow disabled={!geofenceEnabled || disabled} direction={'left'} cb={cb} />
      <Arrow disabled={!geofenceEnabled || disabled} direction={'up'} cb={cb} />
      <Arrow disabled={!geofenceEnabled || disabled} direction={'down'} cb={cb} />
      <Arrow disabled={!geofenceEnabled || disabled} direction={'right'} cb={cb} />
      <ButtonWrap>
        <Button
          style={{ flexGrow: 1 }}
          onClick={recentreCb}
          disabled={(lat === centreLat && lon === centreLong) || !geofenceEnabled || disabled}>
          Re-Centre
        </Button>
      </ButtonWrap>
    </ControllerWrapper>
  );
};

export default Controller;

const ControllerWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  grid-template-columns: auto auto auto auto 1fr;
  grid-gap: 5px;
  justify-content: start;
  align-content: center;
  align-items: center;
  width: 370px;
`;

const ButtonWrap = styled.div`
  margin-left: 5px;
  display: flex;
`;

const TopRow = styled.div`
  grid-row: 1;
  grid-column: 1/-1;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;
