import 'tachyons';

import React, { lazy, ReactElement, Suspense, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import styled from 'styled-components';

import { version } from '../package.json';
import styles from './App.module.scss';
import { Splash } from './components/Splash';
import { ToastWrapper } from './components/Toast';
import UnreachableComponent from './components/UnreachableComponent';
import { LocationsContextProvider } from './context/LocationsContext';
import { generateAdvancedDevicesRecords, generateSimpleDevicesForDb } from './demo/dataImporter';
import DemoDatabase from './demo/persistentStorage';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
  databaseResponse,
  StoreConfigs,
} from './demo/persistentStorage/database/tables';
import { demoDevicesController } from './demo/provider';
import { clearDemoData } from './demo/utility';
import InfoBanner from './views/DashboardRouter/components/InfoBanner';
import MobileLandingView from './views/MobileLandingView';
import TermsView from './views/TermsView';

const CompleteRegistrationView = lazy(() => import('views/CompleteRegistrationViewNew'));
const CreateAccountView = lazy(() => import('views/CreateAccountView'));
const Dashboard = lazy(() => import('views/DashboardRouter'));
const Testing = lazy(() => import('views/Testing'));
const LoginView = lazy(() => import('views/LoginView'));
const PasswordResetRequestView = lazy(() => import('views/PasswordResetRequestView'));
const PasswordResetView = lazy(() => import('views/PasswordResetView'));
const NotFoundRoute = lazy(() => import('components/NotFoundRoute'));
const VesselsMap = lazy(() => import('views/GlobalDashboard/components/VesselsMap'));

function App(): ReactElement {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('version') !== version) {
      'caches' in window && caches.keys().then(names => names.forEach(name => caches.delete(name)));
      clearDemoData();
      (async function () {
        const db = await DemoDatabase.initialize(StoreConfigs);
        await db.clearDatabase();
      })();
      setTimeout(() => {
        localStorage.setItem('version', version);
        window.location.reload();
      }, 1000);
    }
    (async function () {
      const db = await DemoDatabase.initialize(StoreConfigs);
      const count = await db.AdvancedDevice.count();
      if (count === 0) {
        const { devices, vhs } = generateAdvancedDevicesRecords();
        const deviceIds = await db.AdvancedDevice.addBatch(devices);
        const vhIds = await db.AdvancedDevicePropertyVh.addBatch(vhs);
        if (!deviceIds || deviceIds.length === 0 || !vhIds || vhIds.length === 0) {
          return;
        }
        const newDevices = devices as databaseResponse<AdvancedDeviceTable>[];
        for (let i = 0; i < devices.length; i++) {
          newDevices[i].id = deviceIds[i] as number;
        }
        const newVhs = vhs as databaseResponse<AdvancedDevicesPropertyVh>[];
        for (let i = 0; i < vhs.length; i++) {
          newVhs[i].id = vhIds[i] as number;
        }
        const { simpleDevices, fields, metaFields } = generateSimpleDevicesForDb(
          newDevices,
          newVhs
        );
        await db.SimpleDevice.addBatch(simpleDevices);
        await db.SimpleDevicesFieldWrapper.addBatch(fields);
        await db.SimpleDeviceMetaData.addBatch(metaFields);
        demoDevicesController.databaseInit();
      } else {
        demoDevicesController.databaseInit();
      }
    })();
  }, []);

  return (
    <div className={styles.app}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Splash />}>
            <UnreachableComponent />
            <LocationsContextProvider>
              <Routes>
                <Route path="/" element={<LoginView />} />
                <Route path={'/mobile-app'} element={<MobileLandingView />} />
                <Route path="/create-account" element={<CreateAccountView />} />
                <Route
                  path="/terms"
                  element={
                    <TermsWrapper>
                      <InfoBanner />
                      <TermsView appMode={true} />
                    </TermsWrapper>
                  }
                />
                <Route
                  path="/terms/:tabCode"
                  element={
                    <TermsWrapper>
                      <InfoBanner />
                      <TermsView appMode={true} />
                    </TermsWrapper>
                  }
                />
                <Route
                  path="/complete-registration/:verificationCode"
                  element={<CompleteRegistrationView />}
                />
                <Route path="/password-reset-request" element={<PasswordResetRequestView />} />
                <Route path="/password-reset" element={<PasswordResetView />} />
                {/* Routes below checks for session key */}
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path={'/dashboard/map-fullscreen'} element={<VesselsMap />} />
                <Route path="*" element={<NotFoundRoute />} />
                <Route path={'/testing'} element={<Testing />} />
              </Routes>
            </LocationsContextProvider>
          </Suspense>
        </PersistGate>
      </Provider>
      <ToastWrapper />
    </div>
  );
}

export default hot(module)(App);

const TermsWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 2.5%;
`;
