import React, { useEffect, useState } from 'react';
import { VesselType } from 'store/vessels/types';

import containerVessel from '../../assets/images/icons/vessel/CONTAINER_VESSEL.svg';
import defaultVessel from '../../assets/images/icons/vessel/DEFAULT_VESSEL.svg';
import ferry from '../../assets/images/icons/vessel/FERRY.svg';
import pilotVessel from '../../assets/images/icons/vessel/PILOT_VESSEL.svg';
import sailingYacht from '../../assets/images/icons/vessel/SAILING_YACHT.svg';
import speedBoat from '../../assets/images/icons/vessel/SPEEDBOAT_TENDER.svg';
import tugVessel from '../../assets/images/icons/vessel/TUG_VESSEL.svg';
import GeofencePin from './Geofence';
type Props = {
  vesselType?: VesselType;
  background: string;
  geofence?: boolean;
};

const DynamicPin = ({ vesselType, background, geofence }: Props) => {
  const [vesselUrl, setVesselUrl] = useState<string>(defaultVessel);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let newUrl = '';
    switch (vesselType) {
      case 'CONTAINER_VESSEL':
        newUrl = containerVessel;
        break;
      case 'FERRY':
        newUrl = ferry;
        break;
      case 'MOTOR_YACHT':
      case 'MOTOR_CAT':
        newUrl = defaultVessel;
        break;
      case 'PILOT_VESSEL':
        newUrl = pilotVessel;
        break;
      case 'SAILING_CAT':
      case 'SAILING_YACHT':
        newUrl = sailingYacht;
        break;
      case 'SPEEDBOAT_TENDER':
        newUrl = speedBoat;
        break;
      case 'TUG_VESSEL':
        newUrl = tugVessel;
        break;
      default:
        newUrl = defaultVessel;
    }
    setVesselUrl(newUrl);
    setLoading(false);
  }, [vesselType]);

  return !geofence ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0,512) scale(0.098125,-0.098125)" fill={background} stroke="none">
        <path
          d="M2295 5109 c-954 -89 -1505 -606 -1631 -1530 -24 -175 -24 -519 -1
  -654 77 -438 265 -867 615 -1400 208 -317 493 -684 776 -1000 202 -225 487
  -515 506 -515 11 0 98 83 213 203 937 975 1507 1870 1666 2617 79 374 37 873
  -107 1253 -139 367 -389 644 -737 817 -206 103 -417 165 -680 200 -112 15
  -500 21 -620 9z"
        />
      </g>
      {vesselUrl && <image href={vesselUrl} x="25%" y="15%" height="250px" width="250px" />}
    </svg>
  ) : (
    <GeofencePin background={background} vesselUrl={loading ? '' : vesselUrl} />
  );
};

export default DynamicPin;
