import DemoDocumentsController from 'demo/controllers/documentsController';
import DemoGeofenceController from 'demo/controllers/geofenceController';
import DemoUserController from 'demo/controllers/userController';
import DemoVesselController from 'demo/controllers/vesselController';

import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getBasicVessels(): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(await DemoVesselController.getInstance().retrieveVessels());
}

export async function getAllModels(): Promise<MyResponse> {
  return requestMiddleware([]);
}

export async function getAllDocumentCategories(): Promise<MyResponse> {
  return requestMiddleware(DemoDocumentsController.getInstance().documentCategories);
}

export async function getVesselsByModel(vesselModelId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vesselModel/${vesselModelId}/vessels`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postAcceptInvite(vesselId: string, state: boolean): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/acceptInvite/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getPendingInvitations(): Promise<MyResponse> {
  return requestMiddleware([]);
}

export async function getVesselModel(vesselModelId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vesselModel/${vesselModelId}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselRoute(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(DemoGeofenceController.getInstance().geofenceRoute);
}

export async function getVesselLocation(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(DemoVesselController.getInstance().vesselLocation);
}

export async function postCreateVessel(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/create',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateVesselFormData(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/createWithImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function postUpdateVessel(data: any): Promise<MyResponse> {
  DemoVesselController.getInstance().updateVessel(data);
  return requestMiddleware({});
}

export async function postUploadVesselImage(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/vesselImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function postSendVesselPair(
  pairingCode: string,
  vesselId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/pair?pairingCode=${pairingCode}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postGrantUserVesselAccess(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${data.vesselId}/grant/${data.id}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postAddUserToVessel(data: any): Promise<MyResponse> {
  DemoUserController.getInstance().addUser(data.email);
  return requestMiddleware({});
}

export async function getAllUserTypes(): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(DemoUserController.getInstance().userTypes);
}

export async function postWakeupLink(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/wakeup`,
    method: 'POST',
  };
  return requestMiddleware(config);
}
