import { storeHandler } from 'demo/persistentStorage/database/Store/storeHandler';
import { StoreConfig, StoreHandlers } from 'demo/persistentStorage/database/tables';
import { LocalStorageStoreHandler } from 'demo/persistentStorage/localStorage/Store/StoreHandler';

const isIndexedDBSupported = (): boolean => {
  return !!(
    window.indexedDB ||
    // @ts-ignore
    window.mozIndexedDB ||
    // @ts-ignore
    window.webkitIndexedDB ||
    // @ts-ignore
    window.msIndexedDB
  );
};

class DemoDatabase<TStores extends Record<string, StoreConfig>> {
  private static instance: DemoDatabase<any> | null = null;
  db: IDBDatabase | null = null;
  version = 1;
  databaseName = 'link';
  generating = false;
  storeConfigs: TStores;
  private dbReady: Promise<void>;

  constructor(storeConfigs: TStores) {
    this.storeConfigs = storeConfigs;
    this.dbReady = this.init();
    return new Proxy(this as unknown as DemoDatabase<TStores> & StoreHandlers<TStores>, {
      get: (target, storeName: string) => {
        if (storeName in target) {
          return (target as any)[storeName];
        }
        if (storeName in storeConfigs) {
          const config = storeConfigs[storeName];
          if (config.useLocalStorage || !isIndexedDBSupported()) {
            return new LocalStorageStoreHandler(storeName, config);
          } else {
            return new storeHandler(target.db!, storeName);
          }
        }
        console.error(`Store ${storeName} not found in storeConfigs`);
      },
    }) as DemoDatabase<TStores> & StoreHandlers<TStores>;

    if (DemoDatabase.instance) {
      throw new Error('Use DemoDatabase.getInstance() to get the singleton instance');
    }
  }

  public static async initialize<TStores extends Record<string, StoreConfig>>(
    storeConfigs: TStores
  ): Promise<DemoDatabase<TStores> & StoreHandlers<TStores>> {
    if (!DemoDatabase.instance) {
      DemoDatabase.instance = new DemoDatabase(storeConfigs);
      await DemoDatabase.instance.dbReady;
    }
    return DemoDatabase.instance as DemoDatabase<TStores> & StoreHandlers<TStores>;
  }

  static getInstance<TStores extends Record<string, StoreConfig>>(): DemoDatabase<TStores> &
    StoreHandlers<TStores> {
    if (DemoDatabase.instance === null) {
      console.error('DemoDatabase has not been initialized yet!');
      // @ts-ignore
      return null;
    }
    return DemoDatabase.instance as DemoDatabase<TStores> & StoreHandlers<TStores>;
  }

  private async init(): Promise<void> {
    if (isIndexedDBSupported()) {
      return await this.initIndexedDB();
    } else {
      return Promise.resolve();
    }
  }

  private initIndexedDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.databaseName, this.version);
      request.onupgradeneeded = event => {
        const db = (event.target as IDBOpenDBRequest).result;
        Object.values(this.storeConfigs).forEach(config => {
          if (config.useLocalStorage) {
            //
          } else {
            if (!db.objectStoreNames.contains(config.name)) {
              const objectStore = db.createObjectStore(config.name, config.options);
              if (config.indexes) {
                config.indexes.forEach(index => {
                  objectStore.createIndex(index.name, index.keyPath, { unique: index.unique });
                });
              }
            }
          }
        });
      };

      request.onblocked = function (event) {
        console.log('Database upgrade is blocked. Closing old connections.');
      };

      request.onsuccess = event => {
        this.db = (event.target as IDBOpenDBRequest).result;
        const idbdb = request.result;
        if (idbdb.objectStoreNames.length === 0) {
          this.version += 1;
          return resolve(this.initIndexedDB()); // Try again with new version
        }
        resolve();
      };

      request.onerror = event => {
        console.error('Database error:', (event.target as IDBOpenDBRequest).error?.message);
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  async clearDatabase(): Promise<boolean> {
    if (isIndexedDBSupported()) {
      return this.clearIndexedDB();
    } else {
      return Promise.resolve(true);
    }
  }

  private clearIndexedDB(): Promise<boolean> {
    return new Promise(resolve => {
      this.db?.close();
      const req = indexedDB.deleteDatabase(this.databaseName);
      req.onsuccess = () => resolve(true);
      req.onerror = () => resolve(false);
      req.onblocked = function () {
        console.log('could not delete database due to the operation being blocked');
      };
    });
  }
}

export default DemoDatabase;
