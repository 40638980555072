import React, { useRef, useState } from 'react';
import { Search } from 'react-feather';
import styled, { css, keyframes } from 'styled-components';

import { smallBreakpointPx } from '../../styles/breakpoints';
import { brandBlue, brandDarkGrey, standardWhite } from '../../styles/colors';
import ContentLoader from '../ContentLoader';

type Props = {
  small?: boolean;
  loading?: boolean;
  customWidth?: string;
  animated?: boolean;
} & React.ComponentProps<'input'>;

//TODO first load of admin shows the animation of searchbar for a second

const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  ({ small, loading, customWidth, animated, ...inputProps }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field

    const toggleVisibility = () => {
      setIsVisible(prev => !prev);
      if (!isVisible && inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <SearchInputWrapper small={small} customWidth={customWidth}>
        <SearchInputField
          small={small}
          animated={animated}
          isVisible={isVisible}
          {...inputProps}
          ref={ref ? ref : inputRef}
        />
        <SearchInputIcon
          dimension={animated ? '42px' : '40px'}
          loading={loading}
          onClick={toggleVisibility}>
          {loading ? (
            <ContentLoader button />
          ) : (
            <Search style={{ color: '#fff', height: animated ? '24px' : '20px' }} />
          )}
        </SearchInputIcon>
      </SearchInputWrapper>
    );
  }
);

export default SearchInput;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const SearchInputWrapper = styled.div<{ small?: boolean; customWidth?: string }>`
  display: flex;
  align-items: center;
  width: ${props => props.customWidth ?? 'unset'};
  overflow: hidden;

  @media screen and (max-width: ${smallBreakpointPx}) {
    width: 100%;
  }

  ${props =>
    props.small &&
    css`
      width: 100%;
    `}
`;

const SearchInputField = styled.input<{ small?: boolean; isVisible: boolean; animated?: boolean }>`
  border: 2px solid ${brandDarkGrey};
  min-width: 250px;
  width: 100%;
  max-width: 400px;
  height: 40px;
  color: ${brandDarkGrey};
  background-color: ${standardWhite};
  padding: 8px;
  border-radius: ${props => (props.animated ? '6px 6px 6px 6px' : '4px 0 0 4px')};
  border-color: ${brandBlue};
  margin: 0 !important;
  display: block;
  visibility: visible;
  opacity: 1;

  animation: ${props =>
    props.animated &&
    css`
      ${props.isVisible ? slideIn : slideOut} 0.2s forwards,
      ${props.isVisible ? 'none' : 'visibility 0s 0.2s forwards'}
    `};

  &:focus {
    outline: 0;
  }

  ${props =>
    props.small &&
    css`
      height: 35px;
      min-width: 50px;
      font-size: 11px;
    `}
`;

const SearchInputIcon = styled.div<{ loading?: boolean; onClick: () => void; dimension: string }>`
  height: ${props => props.dimension};
  min-width: ${props => props.dimension};
  max-width: ${props => props.dimension};
  background: ${brandBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  z-index: 10;
`;
