import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePaginate = (data: any, elementsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const indexOfLastItem = currentPage * elementsPerPage;
  const indexOfFirstItem = indexOfLastItem - elementsPerPage;
  const [toDisplay, setToDisplay] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(1);
  }, [location]);

  useEffect(() => {
    if (elementsPerPage > 0) {
      setPages(Math.ceil(data.length / elementsPerPage));
      setToDisplay(data.slice(0, elementsPerPage));
    } else {
      setToDisplay(data);
    }
  }, [data, elementsPerPage]);

  useEffect(() => {
    if (elementsPerPage > 0) {
      setPages(Math.ceil(data.length / elementsPerPage));
      const maxPage = Math.max(1, Math.ceil(data.length / elementsPerPage));
      setCurrentPage(Math.min(currentPage, maxPage));
      setToDisplay(data.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [currentPage, elementsPerPage, data]);

  const updateCurrentPage = (pageNum: number) => {
    setCurrentPage(pageNum);
  };

  return [currentPage, pages, updateCurrentPage, toDisplay, setToDisplay] as const;
};

export default usePaginate;
