import { CameraDTO } from '../../types/security';
import DemoDatabase from '../persistentStorage';
import { createNewItemWithCustomId } from '../utility';

class DemoCamerasController {
  private static instance: DemoCamerasController | null = null;
  private _cameras: CameraDTO[] = [];

  public static getInstance(): DemoCamerasController {
    if (this.instance === null) {
      this.instance = new DemoCamerasController();
    }
    return this.instance;
  }

  get cameras(): CameraDTO[] {
    return this._cameras;
  }

  retrieveCameras = async () => {
    if (this._cameras.length === 0) {
      this._cameras = await DemoDatabase.getInstance().VesselCameras.getAllWithDefault();
    }
    return this._cameras;
  };

  addCamera = (data: CameraDTO): void => {
    this._cameras = createNewItemWithCustomId(this._cameras, data, 'id');
    DemoDatabase.getInstance().VesselCameras.add(this._cameras[this._cameras.length - 1]);
  };

  editCamera = (id: number, data: CameraDTO): void => {
    const cameraIndex = this._cameras.findIndex(camera => camera.id === id);
    if (cameraIndex === -1) {
      return;
    }
    this._cameras[cameraIndex] = data;
    DemoDatabase.getInstance().VesselCameras.update(id, this._cameras[cameraIndex]);
  };

  deleteCamera = (id: number): void => {
    this._cameras = this._cameras.filter(camera => camera.id !== id);
    DemoDatabase.getInstance().VesselCameras.delete(id);
  };

  getCameraUrl = (id: number): string => {
    const cameraFound = this._cameras.find(camera => camera.id === id);
    if (cameraFound) {
      return cameraFound.ipAddress;
    }
    return '';
  };
}

export default DemoCamerasController;
