import FlexContainer from 'components/FlexContainer';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { brandBlue } from '../../styles/colors';
import styles from './Switch.module.scss';

type Props = {
  switchLabel?: string;
  switchEndLabel?: string;
  disabled?: boolean;
  checked?: boolean;
  loading?: boolean;
} & React.ComponentProps<'input'>;

const Switch = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { switchLabel, switchEndLabel, loading, disabled, ...switchInputProps } = props;

  const theme = {
    disabled: disabled ? '#e9e9e9' : '#fff',
    blue: brandBlue,
  };

  return (
    <FlexContainer alignItems="center" justifyContent="center">
      <ThemeProvider theme={theme}>
        {switchLabel && <SwitchLabel>{switchLabel}</SwitchLabel>}
        <SwitchWrap>
          <input
            {...switchInputProps}
            checked={loading !== undefined && loading ? !props.checked : props.checked}
            onChange={e => {
              if (switchInputProps && switchInputProps.onChange && !loading) {
                switchInputProps.onChange(e);
              }
            }}
            type="checkbox"
            ref={ref}
            disabled={disabled}
          />
          <Slider />
        </SwitchWrap>
        {switchEndLabel && (
          <SwitchLabel className={styles.switchLabel}>{switchEndLabel}</SwitchLabel>
        )}
      </ThemeProvider>
    </FlexContainer>
  );
});

export default Switch;

const SwitchLabel = styled.span`
  margin: 0 5px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 34px;
  border: solid 2px ${props => props.theme.blue};

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    transition: 0.4s;
    border-radius: 34px;
    background-color: ${props => props.theme.blue};
  }
`;

const SwitchWrap = styled.label`
  position: relative;
  display: inline-block;
  height: 24px;
  width: 44px;
  margin: 10px 0;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 34px;
  background-color: white;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
    background-color: white;

    &:checked {
      & + ${Slider} {
        background-color: ${props => props.theme.blue};
      }
    }

    &:disabled {
      &:checked {
        & + ${Slider} {
          background-color: ${props => props.theme.disabled} !important;
        }
        & + ${Slider}:before {
          background-color: white;
        }
      }
      & + ${Slider}:before {
        background-color: ${props => props.theme.disabled};
      }
      & + ${Slider} {
        cursor: default;
        background-color: transparent;
        border-radius: 34px;
        border: solid 2px ${props => props.theme.disabled};
      }
    }

    &:checked {
      & + ${Slider}:before {
        transform: translateX(20px);
        background-color: ${props => props.theme.disabled};
      }
    }
  }
`;
