import { DemoSettingsCache } from 'demo/caches/simpleDevices/SettingsCache';
import DemoVesselController from 'demo/controllers/vesselController';
import DemoDatabase from 'demo/persistentStorage';
import {
  databaseResponse,
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceMetaDataTable,
} from 'demo/persistentStorage/database/tables';
import { demoDevicesController } from 'demo/provider';
import { NewSimpleDevice } from 'store/simpleDevice/types';
import { SimpleDevice, SimpleDeviceLocationResponse } from 'types/simpleDevices';

import DashboardController, { DashboardResponse } from './DashboardController';
import { flatDevices, retrieveSimpleDevicesBySystem } from './SimpleDeviceResponse';
import { generateSimpleDevices } from './simpleDevicesUtils';

type DeviceType = { devices: SimpleDevice[] };
type LocationType = { locations: SimpleDeviceLocationResponse[] };

class DemoSimpleDevicesController {
  retrieveSettingsResponse = async () => {
    return DemoSettingsCache.getInstance().retrieveSettingsResponse();
  };

  includeInGauges = (devices: SimpleDevice[]): boolean => {
    return devices.some(device => device.fields.some(field => field.includeInGauges));
  };

  retrieveAllDevicesFlat = async (): Promise<SimpleDevice[]> => {
    return await flatDevices();
  };

  retrieveDevice = async (type: string): Promise<DeviceType | LocationType> => {
    const response = await retrieveSimpleDevicesBySystem(type);
    if (type === 'LIGHTING' || type === 'SHADES') {
      return {
        locations: response.locations,
      } as LocationType;
    } else {
      return {
        devices: response.devices,
      };
    }
  };

  retrieveGauges = async (type: string): Promise<DashboardResponse> => {
    let gaugeDevices: DeviceType = { devices: [] };
    switch (type) {
      case 'ENGINE':
        const maritimeGaugeDevices = (await this.retrieveDevice('MARITIME')) as DeviceType;
        const engineGaugeDevices = (await this.retrieveDevice('ENGINE')) as DeviceType;
        gaugeDevices = {
          devices: [...maritimeGaugeDevices.devices, ...engineGaugeDevices.devices],
        };
        return DashboardController.getInstance().engineDashboard(gaugeDevices.devices);
      case 'TANK':
        gaugeDevices = (await this.retrieveDevice('TANK')) as DeviceType;
        return DashboardController.getInstance().tankDashboard(gaugeDevices.devices);
      case 'GENERATOR':
        gaugeDevices = (await this.retrieveDevice('POWER')) as DeviceType;
        return DashboardController.getInstance().generatorDashboard(gaugeDevices.devices);
      case 'BATTERY':
        gaugeDevices = (await this.retrieveDevice('POWER')) as DeviceType;
        return DashboardController.getInstance().batteryDashboard(gaugeDevices.devices);
      case 'MAIN_SWITCHBOARD':
        gaugeDevices = (await this.retrieveDevice('CUSTOM_DASHBOARDS')) as DeviceType;
        return DashboardController.getInstance().mainSwitchboard(gaugeDevices.devices);
      case 'HYDRAULIC':
        gaugeDevices = (await this.retrieveDevice('CUSTOM_DASHBOARDS')) as DeviceType;
        return DashboardController.getInstance().hydraulicDashboard(gaugeDevices.devices);
    }
    return { items: [] };
  };

  setIncludeInGauges = async (fieldId: number, include: boolean) => {
    await this.updateField({ includeInGauges: include }, fieldId);
  };

  updateField = async (
    newField: Partial<SimpleDeviceFieldWrapperTable>,
    fieldId: number
  ): Promise<void> => {
    const fields =
      await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.getById<SimpleDeviceFieldWrapperTable>(
        fieldId,
        'id'
      );
    if (fields.length === 0) {
      return;
    }
    await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.updatePartial(fieldId, newField);
  };

  updateSimpleDeviceData = async (
    id: number,
    name: string,
    manufacturer: string,
    model: string,
    serial: string,
    location: string
  ): Promise<void> => {
    await DemoDatabase.getInstance()
      .SimpleDevice.updatePartial(id, {
        name,
        manufacturer,
        model,
        serial,
        location,
      })
      .catch(e => console.log(e));
  };

  sendCommand = async (deviceProperty: number, value: number | string): Promise<void> => {
    await demoDevicesController.updateDevicePropertyValue(Number(deviceProperty), value);
  };

  updateLocation = async (location: string, state: string, system: string): Promise<void> => {
    const devices = await this.retrieveDevice(system);
    const locationFound = (devices as { locations: SimpleDeviceLocationResponse[] }).locations.find(
      locationItem => locationItem.name === location
    );
    if (!locationFound) {
      return;
    }
    for (const device of locationFound.devices) {
      for (const field of device.fields) {
        await demoDevicesController.updateDevicePropertyValue(
          Number(field.dpvhId),
          state === 'off' || state === 'open' ? '0' : field.fieldName === 'state' ? '1' : '100'
        );
      }
    }
  };

  createSimpleDevice = (device: NewSimpleDevice): void => {
    generateSimpleDevices(device.name, device.templateId, device.location);
    DemoVesselController.getInstance().createNewLog('Simple Device Created', 'USER');
  };

  setMetadata = async (id: number, key: string, value: string): Promise<void> => {
    const fields = await DemoDatabase.getInstance().SimpleDeviceMetaData.getById<
      databaseResponse<SimpleDeviceMetaDataTable>
    >(id, 'simpleDeviceId');
    const metaField = fields.find(field => field.key === key.toLowerCase());
    if (!metaField) {
      await DemoDatabase.getInstance().SimpleDeviceMetaData.add({
        key: key,
        value: value,
        simpleDeviceId: id,
      });
      return;
    }
    await DemoDatabase.getInstance().SimpleDeviceMetaData.update(metaField.id, {
      ...metaField,
      value: value,
    });
  };

  unassignField = async (fieldId: number): Promise<void> => {
    const fields =
      await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.getById<SimpleDeviceFieldWrapperTable>(
        fieldId,
        'id'
      );
    if (fields.length === 0) {
      return;
    }
    await DemoDatabase.getInstance().SimpleDevicesFieldWrapper.update<SimpleDeviceFieldWrapperTable>(
      fieldId,
      { ...fields[0], dpvhId: '' }
    );
  };

  deleteSimpleDevice = async (id: number): Promise<void> => {
    await DemoDatabase.getInstance()
      .SimpleDeviceMetaData.deleteWhere('simpleDeviceIdIndex', id)
      .catch(e => console.log(e));
    await DemoDatabase.getInstance()
      .SimpleDevicesFieldWrapper.deleteWhere('deviceIdIndex', id)
      .catch(e => console.log(e));
    await DemoDatabase.getInstance()
      .SimpleDevice.delete(id)
      .catch(e => console.log(e));
  };
}

export default DemoSimpleDevicesController;
