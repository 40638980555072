import {
  getVesselNotifyDirectory,
  postSetGeofenceNotificationPref,
  postSetGeoFenceParams,
  postSetGeoFenceState,
} from 'api/selectedVessel';
import { getVesselLocation } from 'api/vessels';
import Button from 'components/Button';
import ContentLoader from 'components/ContentLoader';
import MapUnavailable from 'components/MapUnavailable';
import Modal from 'components/Modal';
import NewTable from 'components/NewTable';
import { HeaderRowItem } from 'components/NewTable/Types';
import Switch from 'components/Switch';
import toast from 'components/Toast';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyThunkDispatch } from 'store';
import { saveError, updateGeofence } from 'store/local/actions';
import { RootState } from 'store/rootReducer';
import { fetchGeofenceParams, fetchVesselForId } from 'store/selectedVessel/asyncActions';
import { VesselDirectoryResponse } from 'store/selectedVessel/types';
import styled from 'styled-components';
import { brandBlue } from 'styles/colors';
import { WarningInfo } from 'styles/containers';

import { PermissionsContext } from '../../../../permissionsContext';
import styles from '../../VesselDashboardSecurity.module.scss';
import Controller from './Controller';
import LocationMap from './LocationMap';

type Props = {
  vesselId: string;
  handleAlert: (success: boolean, msg?: string) => void;
};

export function isVesselInside(
  vesselLat: string | undefined,
  vesselLong: string | undefined,
  centreLat: string | undefined,
  centreLong: string | undefined,
  radius: number | undefined,
  radiusPercentage = 1
): boolean {
  const vLat = (Number(vesselLat) / 180.0) * Math.PI;
  const vLong = (Number(vesselLong) / 180.0) * Math.PI;
  const cLat = (Number(centreLat) / 180.0) * Math.PI;
  const cLong = (Number(centreLong) / 180.0) * Math.PI;
  const r = radius ? radius * radiusPercentage : 0;
  const R = 6372800; // m
  const dLat = vLat - cLat;
  const dLon = vLong - cLong;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(cLat) * Math.cos(vLat);
  const c = 2 * Math.asin(Math.sqrt(a));
  const dist = R * c;
  return dist < r;
}

export default function GeofenceExp(props: Props): ReactElement {
  const { vesselId, handleAlert } = props;
  const dispatch: MyThunkDispatch = useDispatch();
  const { geoFenceParams, selectedVessel, geoLoading } = useSelector(
    (state: RootState) => state.selectedVessel
  );
  const { allowLocationServices } = useContext(PermissionsContext);

  const [centreLat, setCentreLat] = useState<string>('');
  const [centreLong, setCentreLong] = useState<string>('');
  const [radius, setRadius] = useState<number>(200);
  const [delay, setDelay] = useState<number>(5);
  const [geofenceEnabled, setGeoFenceEnabled] = useState<boolean>(false);
  const [isVesselInsideGeofence, setIsVesselInsideGeofence] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [isVesselInsideWarningFence, setIsVesselInsideWarningFence] = useState(false);
  const [disableDeactivateButton, setDisableDeactivateButton] = useState(false);
  const disableDeactivateButtonRef = useRef(disableDeactivateButton);
  const [lat, setLat] = useState(
    selectedVessel && selectedVessel.latitude ? selectedVessel.latitude : '0.00'
  );
  const [lon, setLon] = useState(
    selectedVessel && selectedVessel.longitude ? selectedVessel.longitude : '0.00'
  );
  const [loading, setLoading] = useState<boolean>(false);
  const centreLatRef = useRef(centreLat);
  const centreLongRef = useRef(centreLong);
  const disabled =
    (lat === '0.00' && lon === '0.00') ||
    !lat ||
    !lon ||
    !selectedVessel?.gpsReporting ||
    (!selectedVessel.online && !geofenceEnabled);

  const [directory, setDirectory] = useState<VesselDirectoryResponse[]>([]);
  function getUsers(): void {
    getVesselNotifyDirectory(vesselId)
      .then(res => res.ok && setDirectory(res.data))
      .catch(err => dispatch(saveError(err)));
  }

  useEffect(() => {
    dispatch(fetchGeofenceParams(vesselId));
    getUsers();
  }, [dispatch, vesselId]);

  useEffect(() => {
    if (!disabled && geofenceEnabled) {
      dispatch(
        updateGeofence({
          radius,
          lat: centreLat.toString(),
          lon: centreLong.toString(),
          delay,
          vesselId: vesselId,
        })
      );
    }
  }, [radius, delay, disabled]);

  const fetchRoute = useCallback((): void => {
    getVesselLocation(vesselId)
      .then(res => {
        if (res.ok) {
          setLon(res.data.longitude);
          setLat(res.data.latitude);
        }
      })
      .catch(err => dispatch(saveError(err)));
  }, [dispatch]);

  useEffect(() => {
    const routeInterval = setInterval(fetchRoute, 5000);
    const vesselInterval = setInterval(() => {
      dispatch(fetchVesselForId(vesselId));
    }, 5000);
    return () => {
      clearInterval(routeInterval);
      clearInterval(vesselInterval);
    };
  }, [fetchRoute]);

  useEffect(() => {
    disableDeactivateButtonRef.current = disableDeactivateButton;
  }, [disableDeactivateButton]);

  useEffect(() => {
    const geofencePos = setInterval(() => {
      if (!disableDeactivateButtonRef.current) {
        dispatch(fetchGeofenceParams(vesselId));
      }
    }, 10000);
    if (disableDeactivateButtonRef.current) {
      clearInterval(geofencePos);
    }
    return () => clearInterval(geofencePos);
  }, []);

  useEffect(() => {
    if (geoFenceParams) {
      setGeoFenceEnabled(geoFenceParams.enabled);
      setRadius(geoFenceParams.radius);
      setDelay(geoFenceParams.delay);
      setCentreLong(geoFenceParams.lon ?? '0.0');
      setCentreLat(geoFenceParams.lat ?? '0.0');
    }
  }, [geoFenceParams]);

  useEffect(() => {
    setIsVesselInsideGeofence(isVesselInside(lat, lon, centreLat, centreLong, radius));
    setIsVesselInsideWarningFence(isVesselInside(lat, lon, centreLat, centreLong, radius, 0.8));
  }, [centreLat, centreLong, lat, lon, radius]);

  const setUpTable = useMemo<HeaderRowItem[]>(
    () => [
      {
        text: `Vessel Users ${
          directory && '(' + directory.filter(user => !user.corporate).length + ')'
        } `,
        accessor: 'name',
      },
      {
        text: 'Notify?',
        accessor: 'permission',
        columnSizing: { default: { min: '80px', max: '80px' } },
        cb: (data: VesselDirectoryResponse) => {
          return (
            <Switch
              disabled={disabled}
              defaultChecked={data.permission}
              onClick={() => {
                postSetGeofenceNotificationPref(vesselId, data.id, !data.permission).then(res => {
                  if (res.data.status) {
                    getUsers();
                    handleAlert(true, 'Notifications Updated');
                  }
                });
              }}
            />
          );
        },
      },
    ],
    [directory, dispatch, handleAlert, vesselId]
  );

  function isUpdated(): boolean {
    if (geoFenceParams) {
      if (
        geoFenceParams.lat !== centreLat ||
        geoFenceParams.lon !== centreLong ||
        geoFenceParams.radius !== radius ||
        geoFenceParams.delay !== delay
      )
        return true;
      else return false;
    } else return false;
  }

  function handleUseCurrentLocation(): void {
    if (selectedVessel) {
      handleGeofenceUpdate('centre');
    }
  }

  function showConfirmModal(state: boolean): void {
    setConfirmModal(state);
  }

  function handleStateUpdate(): void {
    setLoading(true);
    postSetGeoFenceState(vesselId, !geofenceEnabled)
      .then(resp => {
        if (resp.data.status) {
          toast.success(geofenceEnabled ? 'Anchor Alarm Deactivated' : 'Anchor Alarm Activated', {
            duration: 5000,
          });
          dispatch(fetchGeofenceParams(vesselId));
          dispatch(fetchVesselForId(vesselId));
          setConfirmModal(false);
        }
      })
      .catch(err => dispatch(saveError(err)))
      .finally(() => setLoading(false));
  }

  function handleParametersUpdate(): void {
    postSetGeoFenceParams(vesselId, radius, centreLat, centreLong, delay).then(resp => {
      if (resp.data.status) {
        handleAlert(true, 'Parameters Updated');
        dispatch(fetchGeofenceParams(vesselId));
        dispatch(fetchVesselForId(vesselId));
      }
    });
  }

  useEffect(() => {
    centreLatRef.current = centreLat;
    centreLongRef.current = centreLong;
  }, [centreLat, centreLong]);

  const timeoutIdRef = useRef<number | null>(null);

  function handleGeofenceUpdate(dir: string): void {
    let latitude: number = parseFloat(centreLatRef.current);
    let longitude: number = parseFloat(centreLongRef.current);
    if (isNaN(latitude) || isNaN(longitude)) {
      return;
    }
    const step = 0.00003;
    switch (dir) {
      case 'up':
        latitude += step;
        break;
      case 'down':
        latitude -= step;
        break;
      case 'left':
        longitude -= step;
        break;
      case 'right':
        longitude += step;
        break;
      case 'centre':
        if (selectedVessel) {
          longitude = Number(selectedVessel.longitude);
          latitude = Number(selectedVessel.latitude);
        }
        break;
      default:
        return;
    }

    setCentreLong(longitude.toString());
    setCentreLat(latitude.toString());

    if (geofenceEnabled) {
      if (!disableDeactivateButton) {
        setDisableDeactivateButton(true);
      }

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      timeoutIdRef.current = window.setTimeout(() => {
        setDisableDeactivateButton(false);
        timeoutIdRef.current = null;
      }, 2000);

      dispatch(
        updateGeofence({
          radius,
          lat: latitude.toString(),
          lon: longitude.toString(),
          delay,
          vesselId: vesselId,
        })
      );
    }
  }

  const updateDelay = (newVal: number): void => {
    setDelay(newVal);
  };

  const updateRadius = (newVal: number): void => {
    setRadius(newVal);
  };

  return (
    <>
      {selectedVessel ? (
        <Main>
          {allowLocationServices ? (
            <section className={styles.sectionNotes}>
              <ContentWrapper>
                <DataWrapper>
                  <Controller
                    dispatchPending={disableDeactivateButton}
                    updateRadius={updateRadius}
                    cb={handleGeofenceUpdate}
                    centreLat={centreLat}
                    centreLong={centreLong}
                    recentreCb={handleUseCurrentLocation}
                    stateUpdate={handleStateUpdate}
                    geofenceEnabled={geofenceEnabled}
                    updateDelay={updateDelay}
                    lat={lat}
                    lon={lon}
                    radius={radius}
                    delay={delay}
                    loading={loading}
                    disabled={disabled}
                  />
                  {/*<Row>*/}
                  {/*  /!*<Button disabled={!isUpdated() || !geofenceEnabled} onClick={handleResetValues}>*!/*/}
                  {/*  /!*  Reset Values*!/*/}
                  {/*  /!*</Button>*!/*/}
                  {/*</Row>*/}
                  {directory && (
                    <TableWrapper>
                      <InnerTableWrapper>
                        <NewTable
                          showSearch={false}
                          data={directory.filter(user => !user.corporate)}
                          headerRow={setUpTable}
                          dataType={'geofence'}
                          paginate={false}
                        />
                      </InnerTableWrapper>
                    </TableWrapper>
                  )}
                </DataWrapper>
                <MapWrapper>
                  {disabled ? (
                    <MapUnavailable
                      msg={
                        !selectedVessel?.gpsReporting
                          ? 'GPS reporting turned off.\n Please enable GPS reporting in the Vessel Dashboard to use Anchor Watch'
                          : 'Location is currently unavailable.\n Please check your LINKbridge has access to GPS.'
                      }
                    />
                  ) : (
                    <>
                      <LocationMap
                        type={selectedVessel.vesselType}
                        gpsReporting={selectedVessel?.gpsReporting}
                        loading={geoLoading}
                        latitude={lat}
                        longitude={lon}
                        radius={radius}
                        centreLat={centreLat}
                        centreLon={centreLong}
                        isVesselInside={isVesselInsideGeofence}
                        isVesselInsideWarning={isVesselInsideWarningFence}
                        geofenceEnabled={geofenceEnabled}
                        state={selectedVessel.state}
                        vesselId={vesselId}
                      />
                    </>
                  )}
                </MapWrapper>
              </ContentWrapper>
            </section>
          ) : (
            <div>
              You do not currently have permission to view location based security settings on this
              vessel.
            </div>
          )}
          {confirmModal && (
            <Modal handleClose={showConfirmModal} title={'Activate Anchor Alarm'}>
              <ModalContent>
                <WarningInfo>Vessel is outside the Anchor Alarm Zone</WarningInfo>
                <Button onClick={isUpdated() ? handleParametersUpdate : handleStateUpdate}>
                  Ignore Message and Activate
                </Button>
              </ModalContent>
            </Modal>
          )}
        </Main>
      ) : (
        <ContentLoader fillMode={true} />
      )}
    </>
  );
}

const Main = styled.div`
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: 10px;
  display: grid;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row;
  min-height: 60vh;
  max-height: 62vh;
`;

const DataWrapper = styled.div`
  padding-right: 20px;
  flex-direction: column;
  width: 380px;
  justify-content: space-between;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  button {
    width: 100%;
    margin: 5px 0 !important;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0 0 0;
  width: 370px;
  align-self: flex-start;
  flex: 1;

  scrollbar-color: ${brandBlue} rgb(230, 233, 236);
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgb(230, 233, 236);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${brandBlue};
  }
  table {
    margin-bottom: 0;
  }
`;

const InnerTableWrapper = styled.div`
  width: 370px;
`;

// const Row = styled.div`
//   display: flex;
//   flex-flow: row;
//   justify-content: space-between;
//   button {
//     width: 170px;
//   }
// `;

const ModalContent = styled.div`
  button {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;
