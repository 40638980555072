import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import apple from '../../assets/images/applebadge2.svg';
import google from '../../assets/images/google-play-badge(3).png';
import mobileImg from '../../assets/images/mobile-app-phone.png';
import Button from '../../components/Button';
import { brandBlue } from '../../styles/colors';

const MobileLandingView = () => {
  const userAgent = window.navigator.userAgent;
  const isAndroid = /Android/i.test(userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  const nav = useNavigate();

  const handleWebsiteNav = () => {
    nav('/');
  };

  const openAppOrStore = (fallback: string): void => {
    if (isAndroid) {
      window.location.href =
        'intent://linkconnected#Intent;package=com.linkmobileapp;scheme=mobile;end';
      setTimeout(() => {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.linkmobileapp&hl=en&gl=US&pli=1';
      }, 500);
    } else if (isIOS) {
      window.location.href = 'linkmobile://';
      setTimeout(() => {
        window.location.href =
          'https://apps.apple.com/us/app/link-smart-vessel-control/id1563014189';
      }, 500);
    } else {
      window.location.href = fallback;
    }
  };

  return (
    <Wrapper>
      <Background>
        <Content>
          <Description>
            <h1>Your Vessel, Always Connected</h1>
            <p>
              LINK represents the future of truly connected yachting. With groundbreaking
              monitoring, control, and diagnostics, LINK allows you to oversee your vessel no matter
              where you are in the world.
            </p>
            <p>
              Developed by the global leaders in smart control and integration technology, LINK sets
              the standard for marine remote monitoring and smart solutions.
            </p>
            <DownloadButtons>
              <PlayButton
                onClick={() =>
                  openAppOrStore(
                    'https://play.google.com/store/apps/details?id=com.linkmobileapp&hl=en&gl=US&pli=1'
                  )
                }
                target="_blank"
                rel="noreferrer">
                <img src={google} />
              </PlayButton>
              <AppleButton
                onClick={() =>
                  openAppOrStore(
                    'https://apps.apple.com/us/app/link-smart-vessel-control/id1563014189'
                  )
                }
                target="_blank"
                rel="noreferrer">
                <img src={apple} />
              </AppleButton>
              <Button
                onClick={handleWebsiteNav}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  gridColumn: '1/-1',
                  border: 'solid 1px #989a9c',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  height: '44px',
                }}
                variant={'custom'}>
                Return to Home
              </Button>
            </DownloadButtons>
          </Description>
          <ImageWrapper>
            <img src={mobileImg} alt="Mobile App" />
          </ImageWrapper>
        </Content>
      </Background>
    </Wrapper>
  );
};

export default MobileLandingView;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: ${brandBlue};
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  z-index: 1;
  height: 100%;
  justify-content: center;
  justify-items: center;
  padding-left: 10%;
  padding-right: 10%;
  @media screen and (max-width: 800px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  h1 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 400px;
    height: auto;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const DownloadButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
`;
const PlayButton = styled.a`
  cursor: pointer;
  img {
    height: 66px;
    width: 100%;
    min-width: 140px;
    box-sizing: border-box;
  }
`;

const AppleButton = styled.a`
  cursor: pointer;
  img {
    height: 44px;
    width: 100%;
    min-width: 130px;
    box-sizing: border-box;
  }
`;
