import demoVesselImg from 'assets/images/my-demo-yacht.jpg';
import { SimpleDeviceResponse } from 'demo/controllers/devices/simpleDevices/simpleDevicesUtils';
import DemoUserController from 'demo/controllers/userController';
import DemoVesselController from 'demo/controllers/vesselController';
import { demoDevicesController } from 'demo/provider';
import { ValueHolder, VesselDevice } from 'store/selectedDevice/types';
import { SearchResponse } from 'views/DashboardRouter/components/GlobalSearch/types';

import { SimpleDevice, SimpleDeviceFieldWrapper } from '../types/simpleDevices';
import DemoDatabase from './persistentStorage';
import {
  AdvancedDevicesPropertyVh,
  databaseResponse,
  SimpleDeviceFieldWrapperTable,
  SimpleDeviceMetaDataTable,
  SimpleDeviceTable,
} from './persistentStorage/database/tables';
import { StorageAction, StorageKey } from './types';

export const systems = [
  'LIGHTING',
  'CLIMATE',
  'SHADES',
  'POWER',
  'MARITIME',
  'ENGINE',
  'BILGE',
  'TANK',
  'MECHANICAL',
  'LINK',
];

export function storageUtil(
  key: StorageKey,
  action: StorageAction,
  value?: string
): string | null | void {
  switch (action) {
    case 'GET':
      return localStorage.getItem(key);
    case 'POST':
      if (value) {
        localStorage.setItem(key, value);
      }
      break;
    case 'DELETE':
      localStorage.removeItem(key);
      break;
  }
}

export const clearDemoData = (): void => {
  DemoVesselController.getInstance().clearLogs();
  //Remove all demo localstorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('demo-')) {
      localStorage.removeItem(key);
      i--; // Decrement index since localStorage length decreases after removal
    }
  }
};

export const getAllSimpleDevicesStorageDb = async (): Promise<SimpleDevice[]> => {
  const db = DemoDatabase.getInstance();
  const devices = await db.SimpleDevice.getAll<databaseResponse<SimpleDeviceTable>>();
  const fields = await db.SimpleDevicesFieldWrapper.getAll<
    databaseResponse<SimpleDeviceFieldWrapperTable>
  >();
  const properties = await db.AdvancedDevicePropertyVh.getAll<
    databaseResponse<AdvancedDevicesPropertyVh>
  >();
  const metaData = await db.SimpleDeviceMetaData.getAll<
    databaseResponse<SimpleDeviceMetaDataTable>
  >();
  for (let i = 0; i < devices.length; i++) {
    const filteredFields: SimpleDeviceFieldWrapper[] = [];
    for (let j = 0; j < fields.length; j++) {
      if (fields[j].deviceId !== devices[i].id) {
        continue;
      }
      const property = properties.find(property => property.id === parseInt(fields[j].dpvhId));
      if (!property) {
        filteredFields.push({ ...fields[j], dpvhName: '' });
        continue;
      }
      const updatedField = {
        ...fields[j],
        value: property.value,
        dpvhName: property.name,
        displayName: property.name,
        propertyId: property.id,
        instance: property.instance,
        dictionary: property.dictionary ?? {},
      };
      filteredFields.push(updatedField);
    }
    if (devices[i].system === 'TANK') {
      const tankDeviceFields = constructTank(
        metaData.filter(meta => meta.simpleDeviceId === devices[i].id),
        filteredFields.find(field => field.fieldName === 'fluidLevel')
      );
      filteredFields.push(...tankDeviceFields);
    } else if (devices[i].system === 'CUSTOM_DASHBOARDS') {
      if (devices[i].subSystem === 'MAIN_SWITCHBOARD') {
        const mainSwitchboardFields = constructMainSwitchboard(
          metaData.filter(meta => meta.simpleDeviceId === devices[i].id)
        );
        filteredFields.push(...mainSwitchboardFields);
      } else if (devices[i].subSystem === 'HYDRAULIC') {
        const hydraulicFields = constructHydraulic(
          metaData.filter(meta => meta.simpleDeviceId === devices[i].id)
        );
        filteredFields.push(...hydraulicFields);
      }
    }
    (devices[i] as SimpleDevice).fields = filteredFields;
  }
  return devices as SimpleDevice[];
};

export const constructTank = (
  metaData: SimpleDeviceMetaDataTable[],
  fluidLevel: SimpleDeviceFieldWrapper | undefined
): SimpleDeviceFieldWrapper[] => {
  const response: SimpleDeviceFieldWrapper[] = [];
  let capacity: { key: string; value: string } | undefined = metaData.find(
    meta => meta.key === 'capacity'
  );
  if (!capacity) {
    capacity = { key: 'capacity', value: '0.00' };
  }
  response.push(
    SimpleDeviceResponse({
      fieldName: 'Capacity',
      fieldType: 'G_NUMBER',
      value: capacity.value,
      parsedValue: capacity.value + 'L',
      unit: 'L',
      metaField: true,
      dpvhName: capacity.value + 'L',
    })
  );
  let system: { key: string; value: string } | undefined = metaData.find(
    meta => meta.key === 'system'
  );
  if (!system) {
    system = { key: 'system', value: 'Ungrouped' };
  }
  response.push(
    SimpleDeviceResponse({
      fieldName: 'System',
      fieldType: 'G_NUMBER',
      value: system.value,
      parsedValue: system.value,
      unit: '',
      metaField: true,
      dpvhName: system.value + 'L',
    })
  );
  let volume: { key: string; value: string } | undefined = metaData.find(
    meta => meta.key === 'volume'
  );
  if (!volume) {
    volume = { key: 'volume', value: '0' };
  }
  if (fluidLevel) {
    let formattedValue = '';
    try {
      const capVal = Number(capacity.value);
      const percVal = Number(fluidLevel.value);
      const calcVal = percVal * 0.01 * capVal;
      formattedValue = Math.round(calcVal).toString();
    } catch (e) {
      formattedValue = '0';
    }
    response.push(
      SimpleDeviceResponse({
        fieldName: 'Volume',
        fieldType: 'G_NUMBER',
        value: formattedValue,
        parsedValue: formattedValue + 'L',
        unit: 'L',
        metaField: true,
        dpvhName: formattedValue + 'L',
      })
    );
  } else {
    response.push(
      SimpleDeviceResponse({
        fieldName: 'Volume',
        fieldType: 'G_NUMBER',
        value: volume.value,
        parsedValue: volume.value + 'L',
        unit: 'L',
        metaField: true,
        dpvhName: volume.value + 'L',
      })
    );
  }
  return response;
};

export const constructMainSwitchboard = (
  metaData: SimpleDeviceMetaDataTable[]
): SimpleDeviceFieldWrapper[] => {
  const response: SimpleDeviceFieldWrapper[] = [];
  for (let i = 1; i <= 3; i++) {
    const deviceName = 'Power Source ' + i + ': Name';
    response.push(constructFieldFromMetaDataKey(deviceName, 'CD_SOURCE_NAME', metaData));
    for (let j = 1; j <= 6; j++) {
      const gaugeName = 'Power Source ' + i + ': Gauge ' + j + ' Name';
      response.push(constructFieldFromMetaDataKey(gaugeName, 'CD_GAUGE_NAME', metaData));
    }
    for (let j = 1; j <= 5; j++) {
      const buttonName = 'Power Source ' + i + ': Button ' + j + ' Name';
      response.push(constructFieldFromMetaDataKey(buttonName, 'CD_BUTTON_NAME', metaData));
    }
  }
  return response;
};

export const constructHydraulic = (
  metaData: SimpleDeviceMetaDataTable[]
): SimpleDeviceFieldWrapper[] => {
  const sourceNameRaw = 'Source ${index} name';
  const modeNameRaw = 'Mode ${index} name';
  const controlNameRaw = 'Control ${index} name';
  const controlButton1NameRaw = 'Control ${index} button1 name';
  const controlButton2NameRaw = 'Control ${index} button2 name';
  const response: SimpleDeviceFieldWrapper[] = [];
  for (let i = 1; i < 6; i++) {
    // Sources
    const sourceNameKey = sourceNameRaw.replace('${index}', i.toString());
    response.push(constructFieldFromMetaDataKey(sourceNameKey, 'HD_SOURCE_NAME', metaData));
    // Operating modes
    const modeNameKey = modeNameRaw.replace('${index}', i.toString());
    response.push(constructFieldFromMetaDataKey(modeNameKey, 'HD_MODE_NAME', metaData));
  }
  for (let i = 1; i < 21; i++) {
    const controlNameKey = controlNameRaw.replace('${index}', i.toString());
    const controlButton1NameKey = controlButton1NameRaw.replace('${index}', i.toString());
    const controlButton2NameKey = controlButton2NameRaw.replace('${index}', i.toString());
    response.push(constructFieldFromMetaDataKey(controlNameKey, 'HD_CONTROL_NAME', metaData));
    response.push(
      constructFieldFromMetaDataKey(controlButton1NameKey, 'HD_CONTROL_BUTTON1_NAME', metaData)
    );
    response.push(
      constructFieldFromMetaDataKey(controlButton2NameKey, 'HD_CONTROL_BUTTON2_NAME', metaData)
    );
  }
  return response;
};

const constructFieldFromMetaDataKey = (
  key: string,
  fieldType: string,
  metaDataList: SimpleDeviceMetaDataTable[]
): SimpleDeviceFieldWrapper => {
  let metaData: { key: string; value: string } | undefined = metaDataList.find(
    meta => meta.key === key
  );
  if (!metaData) {
    metaData = { key: key, value: '' };
  }

  return SimpleDeviceResponse({
    fieldName: key,
    fieldType: fieldType,
    value: metaData.value,
    parsedValue: metaData.value,
    unit: '',
    metaField: true,
    dpvhName: metaData.value,
    id: getRandomInt(0, 999999), //Random id for the table. If this causes issues use timestamp.
    includeInGauges: true,
  });
};

export const getRandom = (min: number, max: number, decimalPlaces: number): number => {
  const rand = Math.random() * (max - min) + min;
  const power = Math.pow(10, decimalPlaces);
  return Math.floor(rand * power) / power;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const createNewItemWithId = <Type>(
  dataArr: Type[],
  data: Type,
  idKey: keyof Type
): Type[] => {
  if (dataArr.length > 0) {
    const lastItem = dataArr.length - 1;
    const lastId: number = Number(dataArr[lastItem][idKey]) ?? 0;
    let itemWithNewId = { ...data, id: lastId + 1 };
    if (!isNaN(Number(dataArr[lastItem][idKey]))) {
      itemWithNewId = {
        ...itemWithNewId,
        userId: Number(dataArr[lastItem][idKey]) + 1,
      };
    } else {
      itemWithNewId = { ...itemWithNewId, userId: 1 };
    }
    return [...dataArr, itemWithNewId] as Type[];
  } else {
    return [...dataArr, { ...data, id: 0 }] as Type[];
  }
};

export const createNewItemWithCustomId = <Type>(
  dataArr: Type[],
  data: Type,
  idKey: keyof Type,
  stringId?: boolean
): Type[] => {
  if (dataArr.length > 0) {
    const lastItem = dataArr.length - 1;
    const lastId: number = Number(dataArr[lastItem][idKey]) ?? 0;
    let itemWithNewId = { ...data, id: lastId + 1 };
    if (!isNaN(Number(dataArr[lastItem][idKey]))) {
      const newId = Number(dataArr[lastItem][idKey]) + 1;
      itemWithNewId = {
        ...itemWithNewId,
        [idKey]: stringId ? newId.toString() : newId,
      };
    } else {
      itemWithNewId = { ...itemWithNewId, [idKey]: stringId ? '1' : 1 };
    }
    return [...dataArr, itemWithNewId] as Type[];
  } else {
    return [...dataArr, { ...data, [idKey]: stringId ? '1' : 1 }] as Type[];
  }
};

export const generateLinkBridge = (linkBridgeDevice: VesselDevice): SimpleDevice => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const fields = require('demo/data/simpleDevices/LinkProps.json');
  return {
    id: 0,
    location: '',
    manufacturer: '',
    model: '',
    serial: '',
    state: 'NORMAL',
    templateName: 'link',
    warningState: 'NORMAL',
    name: 'LINKbridge',
    controllable: true,
    online: true,
    system: 'LINK',
    subSystem: 'LINKbridge',
    displayComponent: 'linkBridge',
    metaData: { capacity: '' },
    friendlyName: 'LINKbridge',
    fields: fields.map(
      (field: { fieldName: string; fieldType: string; propUid: string; instance: string }) => {
        const valueHolderFound = linkBridgeDevice.valueHolders.find(
          valueHolder => valueHolder.propertyUid === field.propUid
        );
        if (!valueHolderFound) {
          return {};
        }
        return {
          fieldName: field.fieldName,
          fieldType: field.fieldName,
          value: valueHolderFound.value,
          unit: valueHolderFound.unit,
          parsedValue: getParsedValue(valueHolderFound),
          dpvhId: valueHolderFound.id,
          dpvhName: valueHolderFound.name,
          displayName: valueHolderFound.userDefinedName ?? valueHolderFound.propertyName,
          criticalLevelHigh: valueHolderFound.criticalLevelHigh,
          criticalLevelLow: valueHolderFound.criticalLevelLow,
          warnLevelLow: valueHolderFound.warnLevelLow,
          warnLevelHigh: valueHolderFound.warnLevelHigh,
          loggable: valueHolderFound.loggingEnabled,
          controllable: valueHolderFound.controllable,
          dictionary: valueHolderFound.dictionary,
          gaugeLow: valueHolderFound.gaugeLow,
          gaugeHigh: valueHolderFound.gaugeHigh,
          warningState: valueHolderFound.state,
          deviceOnline: true,
          displayValue: null,
        };
      }
    ),
  };
};

export const getParsedValue = (valueHolder: Partial<ValueHolder>): string => {
  let parseValue;
  if (
    valueHolder.dictionary &&
    Object.keys(valueHolder.dictionary).length > 0 &&
    valueHolder.value
  ) {
    // @ts-ignore
    parseValue = valueHolder.dictionary[valueHolder.value];
  } else {
    parseValue = (valueHolder.value ? valueHolder.value : '') + valueHolder.unit;
  }
  return parseValue;
};

export const demoVesselImgCheck = (vesselName: string, imgUrl: string): string => {
  return vesselName === 'MY Demo Yacht' ? demoVesselImg : imgUrl;
};

const filterByName = <Type>(array: Type[], searchTerm: string, key: keyof Type): Type[] => {
  return array.filter(item => {
    if (!item) {
      return false;
    }
    if (!item[key]) {
      return false;
    }
    // @ts-ignore
    return item[key].includes(searchTerm);
  });
};

export const demoSearch = async (searchValue: string): Promise<SearchResponse> => {
  const users = await DemoUserController.getInstance().retrieveUsers();
  const vessels = await DemoVesselController.getInstance().retrieveVessels();
  const devices = demoDevicesController.devices;
  const filteredVessels = filterByName(vessels, searchValue, 'name');
  const filteredUsers = filterByName(users, searchValue, 'userDisplayName');
  const filteredDevices = filterByName(devices, searchValue, 'name');
  return {
    vessels: filteredVessels,
    models: [],
    devices: filteredDevices,
    contacts: filteredUsers,
  };
};

export const getMetaDataValue = (name: string): [string, string] => {
  if (name.length < 10) {
    return ['', ''];
  }
  const type = name.substring(0, 8);
  const value = name.substring(9, name.length);
  if (type === 'METADATA') {
    return [type, value];
  }
  return ['', ''];
};

export const updateItemInObject = <T>(obj: T, updatedObj: Partial<T>): T | null => {
  if (!obj) {
    return null;
  }
  for (const key of Object.keys(obj)) {
    if (!updatedObj[key as keyof T] && !(key in obj)) {
      continue;
    }
    if (updatedObj[key as keyof T] === obj[key as keyof T]) {
      continue;
    }
    // @ts-ignore
    obj[key as keyof T] = updatedObj[key as keyof T];
  }
  return obj;
};
