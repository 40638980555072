import DemoDatabase from 'demo/persistentStorage';
import { VesselDocument } from 'store/selectedVessel/types';
import { VesselDocumentCategory } from 'store/vessels/types';

type VesselDocumentStorage = {
  storageUsed: number;
  storageQuota: number;
  productName: string;
  manufacturer: boolean;
};

type DemoVesselDocument = VesselDocument & { size: number };

const initStorage = {
  storageUsed: 0,
  storageQuota: 400.0,
  productName: 'Premium Cloud Access',
  manufacturer: false,
};

class DemoDocumentsController {
  private static instance: DemoDocumentsController | null = null;
  private _documents: DemoVesselDocument[] = [];
  private _documentCategories: VesselDocumentCategory[] = [];
  private _documentStorage: VesselDocumentStorage = initStorage;

  public static getInstance(): DemoDocumentsController {
    if (this.instance === null) {
      this.instance = new DemoDocumentsController();
    }
    return this.instance;
  }

  get documents(): DemoVesselDocument[] {
    return this._documents;
  }

  get documentCategories(): VesselDocumentCategory[] {
    if (this._documentCategories.length === 0) {
      this._documentCategories = require('../data/DocumentCategories.json');
    }
    return this._documentCategories;
  }

  get documentStorage(): VesselDocumentStorage {
    if (this._documents.length === 0) {
      this._documentStorage = initStorage;
      return this._documentStorage;
    }
    let size = 0;
    this._documents.forEach(document => {
      size = size + document.size;
    });
    this._documentStorage = { ...this._documentStorage, storageUsed: size };
    return this._documentStorage;
  }

  retrieveDocuments = async (): Promise<DemoVesselDocument[]> => {
    if (this._documents.length === 0) {
      this._documents = await DemoDatabase.getInstance().VesselDocuments.getAllWithDefault();
    }
    return this._documents;
  };

  addDocument = (fileData: FormData): void => {
    const category = this._documentCategories.find(
      userType => userType.id === Number(fileData.get('vesselDocumentCategory'))
    );
    if (!category) {
      return;
    }
    const file = fileData.get('file') as File;
    const docName = fileData.get('vesselDocumentName')?.toString();
    const docDesc = fileData.get('vesselDocumentDescription')?.toString();
    const sizeInMB = file.size / (1024 * 1024);
    const newDoc: DemoVesselDocument = {
      id: 0,
      category: category,
      fileName: file.name,
      extension: file.type,
      uploaderName: 'Demo User',
      downloadId: '0',
      timeStamp: new Date().toISOString(),
      documentName: docName ?? '',
      documentDescription: docDesc ?? '',
      supplierDocumentation: false,
      size: sizeInMB,
    };
    if (this._documents.length > 0) {
      const lastItem = this._documents.length - 1;
      const newDocWithId = {
        ...newDoc,
        id: this._documents[lastItem].id + 1,
        downloadId: (this._documents[lastItem].id + 1).toString(),
      };
      this._documents = [...this._documents, newDocWithId];
    } else {
      this._documents = [...this._documents, newDoc];
    }
    this._documentStorage = {
      ...this._documentStorage,
      storageUsed: this._documentStorage.storageUsed + sizeInMB,
    };
    DemoDatabase.getInstance().VesselDocuments.add(this._documents[this._documents.length - 1]);
  };

  deleteDocument = (id: string): void => {
    this._documents = this._documents.filter(document => {
      if (document.downloadId !== id) {
        return true;
      } else {
        this._documentStorage = {
          ...this._documentStorage,
          storageUsed: this._documentStorage.storageUsed - document.size,
        };
        return false;
      }
    });
    DemoDatabase.getInstance().VesselDocuments.delete(id);
  };
}

export default DemoDocumentsController;
