import React, { createContext, useState } from 'react';

type BannerType = 'WARNING' | 'CRITICAL' | 'GENERAL' | 'NEUTRAL' | null;

interface BannerContextInterface {
  type: BannerType;
  message: string;
  icon: string;
  handleClick: (() => void) | null;
  setType: (type: BannerType) => void;
  setMessage: (message: string) => void;
  setIcon: (icon: string) => void;
  setClickHandler: (handler: () => void) => void;
  reset: () => void;
}

const defaultValues: BannerContextInterface = {
  type: null,
  message: '',
  icon: '',
  handleClick: null,
  setType: () => {
    return;
  },
  setMessage: () => {
    return;
  },
  setIcon: () => {
    return;
  },
  setClickHandler: () => {
    return;
  },
  reset: () => {
    return;
  },
};

const BannerContext = createContext<BannerContextInterface>(defaultValues);

const BannerContextProvider: React.FC = ({ children }) => {
  const [type, setType] = useState<BannerType>(defaultValues.type);
  const [message, setMessage] = useState<string>(defaultValues.message);
  const [icon, setIcon] = useState<string>(defaultValues.icon);
  const [handleClick, setHandleClick] = useState<(() => void) | null>(null);

  const setClickHandler = (handler: () => void) => {
    setHandleClick(() => handler);
  };

  const reset = () => {
    setType(null);
    setMessage('');
    setIcon('');
    setHandleClick(null);
  };

  return (
    <BannerContext.Provider
      value={{
        type,
        message,
        icon,
        handleClick,
        setType,
        setMessage,
        setIcon,
        setClickHandler,
        reset,
      }}>
      {children}
    </BannerContext.Provider>
  );
};

export { BannerContext, BannerContextProvider };
