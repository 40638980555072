import React from 'react';
import styled from 'styled-components';

type Props = {
  vesselUrl?: string;
  background: string;
};

const GeofencePin = ({ vesselUrl, background }: Props) => {
  return (
    <PinContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={background}
          stroke="none">
          <path
            d="M2240 5114 c-114 -19 -159 -29 -254 -56 -359 -100 -629 -292 -808
-573 -90 -142 -184 -406 -213 -601 -20 -134 -21 -538 -1 -649 44 -240 156
-520 349 -875 115 -211 459 -692 662 -925 304 -349 553 -605 589 -605 24 0
113 87 367 360 412 443 768 939 984 1370 100 200 105 211 160 376 91 276 90
271 90 619 0 335 -1 348 -63 560 -64 218 -127 345 -247 493 -198 244 -454 391
-844 484 -76 18 -124 21 -416 23 -181 2 -341 1 -355 -1z"
          />
          <path
            d="M3379 1397 c-60 -40 -63 -104 -6 -141 13 -9 104 -32 203 -51 457 -90
766 -214 888 -357 51 -59 62 -106 38 -161 -105 -229 -715 -428 -1504 -488
-239 -18 -881 -7 -1103 20 -454 54 -804 143 -1035 262 -121 62 -187 112 -227
173 -43 63 -43 107 -4 167 101 151 412 284 895 380 109 22 207 44 217 49 45
25 53 97 14 135 -32 33 -85 33 -257 0 -688 -133 -1083 -368 -1083 -645 0 -308
472 -560 1270 -679 295 -44 424 -53 795 -58 398 -5 567 4 914 53 692 97 1157
298 1272 550 15 33 31 67 35 76 12 23 11 94 0 116 -5 9 -21 43 -36 75 -105
226 -510 417 -1106 522 -79 14 -144 25 -145 25 0 0 -16 -10 -35 -23z"
          />
        </g>
        {vesselUrl && <image href={vesselUrl} x="26%" y="10%" height="250px" width="250px" />}
      </svg>
    </PinContainer>
  );
};

export default GeofencePin;

const PinContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
`;
