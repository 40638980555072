import { SimpleDevice, SimpleDeviceFieldWrapper, TankGroup } from 'types/simpleDevices';
import {
  HydraulicData,
  MainSwitchData,
} from 'views/SimpleDevicesView/components/Gauge/GaugeReducer';

type FieldResponse = { field: SimpleDeviceFieldWrapper; name: string };
type FieldResponseNullable = { field: SimpleDeviceFieldWrapper | null; name: string };

export type DashboardResponse = {
  items: (SimpleDeviceFieldWrapper | SimpleDevice | TankGroup | MainSwitchData | HydraulicData)[];
};

class DemoDashboardController {
  static instance: DemoDashboardController | null = null;

  constructor() {
    if (DemoDashboardController.instance) {
      throw new Error('Use DemoDashboardController.getInstance() to get the singleton instance');
    }
    // Initialization code
  }
  static getInstance(): DemoDashboardController {
    if (DemoDashboardController.instance === null) {
      DemoDashboardController.instance = new DemoDashboardController();
    }
    return DemoDashboardController.instance;
  }

  basicGaugeFilter(devices: SimpleDevice[], type: string): SimpleDevice[] {
    const devicesBySubSystem = devices.filter(device => device.subSystem === type);
    const filteredDevices: SimpleDevice[] = [];
    devicesBySubSystem.forEach(device => {
      const gaugeFields: SimpleDeviceFieldWrapper[] = [];
      device.fields.forEach(field => {
        if (field.includeInGauges) {
          gaugeFields.push(field);
        }
      });
      if (gaugeFields.length > 0) {
        filteredDevices.push({ ...device, fields: gaugeFields });
      }
    });
    return filteredDevices;
  }

  engineDashboard(devices: SimpleDevice[]): DashboardResponse {
    return { items: this.basicGaugeFilter(devices, 'ENGINE') };
  }

  generatorDashboard(devices: SimpleDevice[]): DashboardResponse {
    return { items: this.basicGaugeFilter(devices, 'GENERATOR') };
  }

  batteryDashboard(devices: SimpleDevice[]): DashboardResponse {
    return { items: this.basicGaugeFilter(devices, 'BATTERY') };
  }

  mainSwitchboard(devices: SimpleDevice[]): DashboardResponse {
    const filteredDevices = this.basicGaugeFilter(devices, 'MAIN_SWITCHBOARD');
    if (filteredDevices.length === 0) {
      return { items: [] };
    }
    const fields = filteredDevices[0].fields;
    const response: MainSwitchData[] = [];
    for (let i = 1; i <= 3; i++) {
      const splitFields = fields.filter(f => f.fieldName.includes('Power Source ' + i.toString()));
      const powerSource = this.powerSourceResponse(splitFields, i);
      response.push(powerSource);
    }
    return {
      items: response,
    };
  }

  powerSourceResponse(fields: SimpleDeviceFieldWrapper[], sourceNumber: number): MainSwitchData {
    const gaugeProperties: SimpleDeviceFieldWrapper[] = [];
    const gaugeNames: SimpleDeviceFieldWrapper[] = [];
    const buttonProperties: SimpleDeviceFieldWrapper[] = [];
    const buttonNames: SimpleDeviceFieldWrapper[] = [];
    const defaultData: MainSwitchData = {
      name: '',
      warningState: 'NORMAL',
      online: true,
      isEngaged: '0',
      state: '0',
      gauges: [],
      buttons: [],
    };
    fields.forEach(field => {
      const fieldName = field.fieldName;
      const fieldState = field.warningState;
      const fieldType = field.fieldType;
      if (fieldName.includes(': Name')) {
        if (field.value != null && field.value !== '') defaultData.name = field.value;
        else defaultData.name = 'Power Source ' + sourceNumber;
      } else if (fieldName.includes(': isEngaged'))
        defaultData.isEngaged = field.value as '1' | '0';
      else if (fieldName.includes(': state')) defaultData.state = field.value as '1' | '0';
      else if (fieldType === 'CD_GAUGE_PROPERTY') gaugeProperties.push(field);
      else if (fieldType === 'CD_GAUGE_NAME') gaugeNames.push(field);
      else if (fieldType === 'CD_BUTTON_PROPERTY') buttonProperties.push(field);
      else if (fieldType === 'CD_BUTTON_NAME') buttonNames.push(field);
      if (fieldState === 'WARNING') defaultData.warningState = 'WARNING';
      else if (fieldState === 'WARNING' && defaultData.warningState !== 'WARNING')
        defaultData.warningState = 'WARNING';
    });
    gaugeProperties.forEach(gaugeProperty => {
      if (gaugeProperty.value != null && gaugeProperty.value !== '') {
        const gaugePropertyNumber =
          gaugeProperty.fieldName.length > 22 ? gaugeProperty.fieldName.charAt(22).toString() : '0';
        gaugeNames.forEach(gaugeName => {
          const gaugeNameNumber =
            gaugeName.fieldName.length > 22
              ? gaugeName.fieldName.charAt(22).toString()
              : '(Unknown)';
          let gaugePropertyName = 'Gauge ' + gaugeNameNumber;
          if (gaugeNameNumber === gaugePropertyNumber) {
            if (gaugeName.value != null && gaugeName.value !== '') {
              gaugePropertyName = gaugeName.value;
            }
            defaultData.gauges.push({ name: gaugePropertyName, field: gaugeProperty });
          }
        });
      }
    });
    buttonProperties.forEach(buttonProperty => {
      if (buttonProperty.value != null && buttonProperty.value !== '') {
        const buttonPropertyNumber =
          buttonProperty.fieldName.length > 23
            ? buttonProperty.fieldName.charAt(23).toString()
            : '0';
        buttonNames.forEach(buttonName => {
          const buttonNameNumber =
            buttonName.fieldName.length > 23 ? buttonName.fieldName.charAt(23).toString() : '?';
          let buttonPropertyName = 'Btn ' + buttonNameNumber;
          if (buttonNameNumber === buttonPropertyNumber) {
            if (buttonName.value != null && buttonName.value !== '') {
              buttonPropertyName = buttonName.value;
            }
            defaultData.buttons.push({ name: buttonPropertyName, field: buttonProperty });
          }
        });
      }
    });
    return defaultData;
  }

  hydraulicDashboard(devices: SimpleDevice[]): DashboardResponse {
    const filteredDevices = this.basicGaugeFilter(devices, 'HYDRAULIC');
    if (filteredDevices.length === 0) {
      return { items: [] };
    }
    const response: HydraulicData = {
      sources: Array(5).fill({ name: '', on: null, off: null, auto: null }),
      modes: Array(5).fill({ name: '', field: null }),
      controls: Array(20).fill({
        name: '',
        button1: { name: '', field: null },
        button2: { name: '', field: null },
      }),
      statuses: Array(50).fill(null),
      enabled: null,
    };
    const statuses: SimpleDeviceFieldWrapper[] = [];

    const modesProperties: SimpleDeviceFieldWrapper[] = [];
    const modesNames: SimpleDeviceFieldWrapper[] = [];

    const sourcesPropertiesOn: SimpleDeviceFieldWrapper[] = [];
    const sourcesPropertiesOff: SimpleDeviceFieldWrapper[] = [];
    const sourcesPropertiesAuto: SimpleDeviceFieldWrapper[] = [];
    const sourcesNames: SimpleDeviceFieldWrapper[] = [];

    const controlsPropertiesBtn1: SimpleDeviceFieldWrapper[] = [];
    const controlsNamesBtn1: SimpleDeviceFieldWrapper[] = [];
    const controlsPropertiesBtn2: SimpleDeviceFieldWrapper[] = [];
    const controlsNamesBtn2: SimpleDeviceFieldWrapper[] = [];
    const controlsNames: SimpleDeviceFieldWrapper[] = [];

    const fields = filteredDevices[0].fields;

    fields.forEach(field => {
      const fieldType = field.fieldType;

      if (fieldType === 'HD_STATUS') statuses.push(field);
      else if (fieldType === 'HD_MODE_PROPERTY') modesProperties.push(field);
      else if (fieldType === 'HD_MODE_NAME') modesNames.push(field);
      else if (fieldType === 'HD_SOURCE_PROPERTY_ON') sourcesPropertiesOn.push(field);
      else if (fieldType === 'HD_SOURCE_PROPERTY_OFF') sourcesPropertiesOff.push(field);
      else if (fieldType === 'HD_SOURCE_PROPERTY_AUTO') sourcesPropertiesAuto.push(field);
      else if (fieldType === 'HD_SOURCE_NAME') sourcesNames.push(field);
      else if (fieldType === 'HD_CONTROL_BUTTON1_PROPERTY') controlsPropertiesBtn1.push(field);
      else if (fieldType === 'HD_CONTROL_BUTTON1_NAME') controlsNamesBtn1.push(field);
      else if (fieldType === 'HD_CONTROL_BUTTON2_PROPERTY') controlsPropertiesBtn2.push(field);
      else if (fieldType === 'HD_CONTROL_BUTTON2_NAME') controlsNamesBtn2.push(field);
      else if (fieldType === 'HD_CONTROL_NAME') controlsNames.push(field);
      else if (fieldType === 'HD_CONTROL_HYDRAULICS_ENABLED') response.enabled = field;
    });
    response.statuses = statuses;
    response.modes = this.constructModes(modesNames, modesProperties);
    response.sources = this.constructSources(
      sourcesNames,
      sourcesPropertiesOn,
      sourcesPropertiesOff,
      sourcesPropertiesAuto
    );
    response.controls = this.constructControls(
      controlsNames,
      controlsPropertiesBtn1,
      controlsNamesBtn1,
      controlsPropertiesBtn2,
      controlsNamesBtn2
    );
    return {
      items: [response],
    };
  }

  constructModes(
    modesNames: SimpleDeviceFieldWrapper[],
    modesProperties: SimpleDeviceFieldWrapper[]
  ): FieldResponse[] {
    const response: FieldResponse[] = [];
    for (let i = 1; i <= 5; i++) {
      const mode: { name: string; field: SimpleDeviceFieldWrapper | null } = {
        name: '',
        field: null,
      };
      for (let j = 0; j < modesNames.length; j++) {
        const number =
          modesNames[j].fieldName.length > 5 ? modesNames[j].fieldName.charAt(5).toString() : '0';
        if (number === i.toString()) {
          mode.name = 'Mode ' + i;
          if (modesNames[j].value != null && modesNames[j].value !== '')
            mode.name = modesNames[j].value;
          break;
        }
      }
      for (let j = 0; j < modesProperties.length; j++) {
        const number =
          modesProperties[j].fieldName.length > 5
            ? modesProperties[j].fieldName.charAt(5).toString()
            : '0';
        if (number === i.toString()) {
          if (modesProperties[j].value != null && modesProperties[j].value !== '')
            mode.field = modesProperties[j];
          break;
        }
      }
      if (mode.field != null) response.push(mode as FieldResponse);
    }
    return response;
  }

  constructSources(
    sourcesNames: SimpleDeviceFieldWrapper[],
    sourcesPropertiesOn: SimpleDeviceFieldWrapper[],
    sourcesPropertiesOff: SimpleDeviceFieldWrapper[],
    sourcesPropertiesAuto: SimpleDeviceFieldWrapper[]
  ) {
    const response = [];
    for (let i = 1; i <= 5; i++) {
      const source: {
        name: string;
        on: SimpleDeviceFieldWrapper | null;
        off: SimpleDeviceFieldWrapper | null;
        auto: SimpleDeviceFieldWrapper | null;
      } = {
        name: '',
        on: null,
        off: null,
        auto: null,
      };
      for (let j = 0; j < sourcesNames.length; j++) {
        const number =
          sourcesNames[j].fieldName.length > 7 ? sourcesNames[j].fieldName.charAt(7) : '0';
        if (number === i.toString()) {
          source.name = 'Source ' + i;
          if (sourcesNames[j].value != null && sourcesNames[j].value !== '')
            source.name = sourcesNames[j].value;
          break;
        }
      }
      for (let j = 0; j < sourcesPropertiesOn.length; j++) {
        const number =
          sourcesPropertiesOn[j].fieldName.length > 7
            ? sourcesPropertiesOn[j].fieldName.charAt(7).toString()
            : '0';
        if (
          number === i.toString() &&
          sourcesPropertiesOn[j].value != null &&
          sourcesPropertiesOn[j].value !== ''
        ) {
          source.on = sourcesPropertiesOn[j];
          break;
        }
      }
      for (let j = 0; j < sourcesPropertiesOff.length; j++) {
        const number =
          sourcesPropertiesOff[j].fieldName.length > 7
            ? sourcesPropertiesOff[j].fieldName.charAt(7).toString()
            : '0';
        if (
          number === i.toString() &&
          sourcesPropertiesOff[j].value != null &&
          sourcesPropertiesOff[j].value !== ''
        ) {
          source.off = sourcesPropertiesOff[j];
          break;
        }
      }
      for (let j = 0; j < sourcesPropertiesAuto.length; j++) {
        const number =
          sourcesPropertiesAuto[j].fieldName.length > 7
            ? sourcesPropertiesAuto[j].fieldName.charAt(7).toString()
            : '0';
        if (
          number === i.toString() &&
          sourcesPropertiesAuto[j].value != null &&
          sourcesPropertiesAuto[j].value !== ''
        ) {
          source.auto = sourcesPropertiesAuto[j];
          break;
        }
      }
      if (source.on != null || source.off != null || source.auto != null) response.push(source);
    }
    return response;
  }

  constructControls(
    controlsNames: SimpleDeviceFieldWrapper[],
    controlsPropertiesBtn1: SimpleDeviceFieldWrapper[],
    controlsNamesBtn1: SimpleDeviceFieldWrapper[],
    controlsPropertiesBtn2: SimpleDeviceFieldWrapper[],
    controlsNamesBtn2: SimpleDeviceFieldWrapper[]
  ) {
    const response: {
      name: string;
      button1: FieldResponse;
      button2: FieldResponse;
    }[] = [];
    for (let i = 1; i <= 20; i++) {
      const control: {
        name: string;
        button1: FieldResponse | null;
        button2: FieldResponse | null;
      } = {
        name: '',
        button1: null,
        button2: null,
      };
      const button1: FieldResponseNullable = { field: null, name: '' };
      const button2: FieldResponseNullable = { field: null, name: '' };

      for (let j = 0; j < controlsNames.length; j++) {
        const number = controlsNames[j].fieldName.substring(8, 10).toString();
        if (number.trim() === i.toString()) {
          control.name = 'Control ' + i;
          if (controlsNames[j].value != null && controlsNames[j].value !== '')
            control.name = controlsNames[j].value;
          break;
        }
      }

      for (let j = 0; j < controlsPropertiesBtn1.length; j++) {
        const number = controlsPropertiesBtn1[j].fieldName.substring(8, 10).toString();
        if (
          number.trim() === i.toString() &&
          controlsPropertiesBtn1[j].value != null &&
          controlsPropertiesBtn1[j].value !== ''
        ) {
          const number_ = controlsPropertiesBtn1[j].fieldName.charAt(i < 10 ? 16 : 17).toString();
          if (number_ === '1') button1.field = controlsPropertiesBtn1[j];
          break;
        }
      }

      for (let j = 0; j < controlsNamesBtn1.length; j++) {
        const number = controlsNamesBtn1[j].fieldName.substring(8, 10).toString();
        if (number.trim() === i.toString()) {
          const number_ = controlsNamesBtn1[j].fieldName.charAt(i < 10 ? 16 : 17).toString();
          if (number_ === '1') {
            button1.name = 'Btn 1';
            if (controlsNamesBtn1[j].value != null && controlsNamesBtn1[j].value !== '')
              button1.name = controlsNamesBtn1[j].value;
          }
          break;
        }
      }

      for (let j = 0; j < controlsPropertiesBtn2.length; j++) {
        const number = controlsPropertiesBtn2[j].fieldName.substring(8, 10).toString();
        if (
          number.trim() === i.toString() &&
          controlsPropertiesBtn2[j].value != null &&
          controlsPropertiesBtn2[j].value !== ''
        ) {
          const number_ = controlsPropertiesBtn2[j].fieldName.charAt(i < 10 ? 16 : 17).toString();
          if (number_ === '2') button2.field = controlsPropertiesBtn2[j];
          break;
        }
      }

      for (let j = 0; j < controlsNamesBtn2.length; j++) {
        const number = controlsNamesBtn2[j].fieldName.substring(8, 10).toString();
        if (number.trim() === i.toString()) {
          const number_ = controlsNamesBtn2[j].fieldName.charAt(i < 10 ? 16 : 17).toString();
          if (number_ === '2') {
            button2.name = 'Btn 2';
            if (controlsNamesBtn2[j].value != null && controlsNamesBtn2[j].value !== '')
              button2.name = controlsNamesBtn2[j].value;
          }
          break;
        }
      }
      if (button1.field != null) control.button1 = button1 as FieldResponse;
      if (button2.field != null) control.button2 = button2 as FieldResponse;
      if (control.button1 != null || control.button2 != null)
        response.push(
          control as {
            name: string;
            button1: FieldResponse;
            button2: FieldResponse;
          }
        );
    }
    return response;
  }
  tankDashboard(devices: SimpleDevice[]): DashboardResponse {
    const tanks: TankGroup[] = [];
    devices.forEach(device => {
      let includeInGauges = false;
      let system = 'Ungrouped';
      let volume = 0;
      let capacity = 0;
      device.fields.forEach(field => {
        if (field.fieldName === 'fluidLevel') {
          includeInGauges = field.includeInGauges;
        }
        if (field.fieldName === 'System') {
          system = field.value;
        }
        if (field.fieldName === 'Capacity') {
          capacity = Number(field.value);
        }
        if (field.fieldName === 'Volume') {
          volume = Number(field.value);
        }
      });
      if (!includeInGauges) {
        return;
      }
      const foundTankSystemIndex = tanks.findIndex(tank => tank.name === system);
      if (foundTankSystemIndex !== -1 && tanks.length > 0) {
        const newItems = [...tanks[foundTankSystemIndex].items, device];
        const totalVolume = tanks[foundTankSystemIndex].totalVolume + volume;
        const totalCapacity = tanks[foundTankSystemIndex].totalCapacity + capacity;
        tanks[foundTankSystemIndex] = {
          ...tanks[foundTankSystemIndex],
          items: newItems,
          totalVolume: totalVolume,
          totalCapacity: totalCapacity,
          percentage: Math.round((totalVolume / totalCapacity) * 100),
        };
      } else {
        tanks.push({
          totalVolume: volume,
          totalCapacity: capacity,
          tankCount: 5,
          items: [device],
          warningState: 'NORMAL',
          name: system,
          capacityUnit: 'L',
          volumeUnit: 'L',
          percentage: Math.round((volume / capacity) * 100),
        });
      }
    });
    return { items: tanks };
  }
}

export default DemoDashboardController;
