import { DemoAdvancedDeviceCache } from 'demo/caches/AdvancedDeviceCache';
import DemoUserController from 'demo/controllers/userController';

import { MyResponse, RequestConfig, requestMiddleware } from './request';

export type AuditTrailPost = {
  page: number;
  size: number;
  search: string;
  sortColumn: string;
  sortDesc: boolean;
};

export async function getAllCompanies(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/admin/companies/view',
    method: 'GET',
  });
}

export async function getAllUsers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAuditTrail(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/logs',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAuditTrailPaginated(data: AuditTrailPost): Promise<MyResponse> {
  const url = '/api/admin/logs/page';
  const config: RequestConfig = {
    url,
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function getAllDocumentCategories(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/documentCategory/view',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateDocumentCategory(data: {
  name: string | undefined;
  description: string | undefined;
  id: number;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/documentCategory/add',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function getAllNavigationComponents(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/navigationComponent/view',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateNavigationComponent(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/navigationComponent/add',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function postDeleteUser(data: {
  userEmail: string;
  adminPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/deleteUser',
    method: 'DELETE',
    data: data,
  };
  return requestMiddleware(config);
}

export async function putSendInvite(data: { userEmail: string }): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/inviteUser',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function putPasswordReset(data: {
  userEmail: string;
  adminPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/changeUserPassword',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function putSuspendUser(data: {
  userEmail: string;
  adminPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/suspendUser',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function putActivateUser(data: {
  userEmail: string;
  adminPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/activateUser',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function handlePutAdminUser(
  data: {
    userEmail: string;
    adminPassword: string;
  },
  admin: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: admin ? '/api/admin/users/makeUserAdmin' : '/api/admin/users/revokeUserAdmin',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateVesselPermissions(
  data: any,
  vesselId: string
): Promise<MyResponse> {
  DemoUserController.getInstance().updatePermissions(data);
  return requestMiddleware({});
}

export async function postCreateUser(data: {
  emailAddress: string;
  companyId: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/users/createUser',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postRevokeVesselPermissions(data: any): Promise<MyResponse> {
  DemoUserController.getInstance().delete(data.id);
  return requestMiddleware({});
}

export async function postSetUserPausedState(data: any): Promise<MyResponse> {
  DemoUserController.getInstance().pause(data.id, data.state);
  return requestMiddleware({});
}

export async function postEditCompanyPermissions(
  vesselId: string,
  companyId: string,
  data: any
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/editCompany/${companyId}`,
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function postRevokeCompanyPermissions(
  vesselId: string,
  companyId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/revokeCompany/${companyId}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetCompanyPausedState(
  vesselId: string,
  companyId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/pauseCompany/${companyId}/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getAdminManufacturers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/view/manufacturers',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAdminDealers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/view/dealers',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAdminContractors(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/view/contractors',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateDealer(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/create/dealer',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postUpdateOrganisation(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/update',
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateManufacturer(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/create/manufacturer',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateContractor(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/companies/create/contractor',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function getAllUserLanguages(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/userLanguageOptions/view',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateUserLanguage(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/userLanguageOptions/add',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function getAllReportingClients(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/reportingClients/view',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateReportingClient(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/reportingClients/create',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function postUpdateReportingClient(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/reportingClients/update',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function deleteReportingClient(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/reportingClients/delete',
    method: 'DELETE',
    data,
  };
  return requestMiddleware(config);
}

export async function getAllSubscriptions(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/subscriptions/view',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export interface BackupRequest {
  includeDeviceAndDevicePropertyValueHolders: boolean;
  includeDevicePropertyValues: boolean;
  includeCamerasAndStreams: boolean;
  includeSystemExports: boolean;
  includeExternalControllers: boolean;
  includeRoutinesAndAlerts: boolean;
  includeSimpleDevicesAndFieldWrappers: boolean;
  uploadToCloud: boolean;
  deviceFilter: string[];
}

export async function getSubscriptionVouchers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/subscription/vouchers',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getSubscriptionProducts(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/products/all',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function createSubscriptionVoucher(payload: {
  period: string;
  productIds: number[];
  reason: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/subscription/voucher/create',
    method: 'POST',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function getSubscriptionVoucherCode(codeId: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/subscription/voucher/${codeId}/showCode`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export interface VesselModelAdmin {
  id?: string;
  name: string;
  vesselDraft: string;
  vesselDraftUnit: string;
  vesselBeam: string;
  vesselBeamUnit: string;
  vesselLength: string;
  vesselLengthUnit: string;
  lightDisplacement: string;
  lightDisplacementUnit: string;
  fullDisplacement: string;
  fullDisplacementUnit: string;
  yearOfDesign: string;
  vesselType: string;
  manufacturer?: {
    name: string;
  };
}

export async function postUpdateVesselModelAdmin(data: VesselModelAdmin): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/vesselModels/edit',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function postCreateVesselModelAdmin(data: VesselModelAdmin): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/vesselModels/create',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function deleteVesselModelAdmin(data: { modelId: string }): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/vesselModels/delete?modelId=${data.modelId}`,
    method: 'DELETE',
  };

  return requestMiddleware(config);
}

export async function postVesselModelImage(modelId: string, image: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/vesselModels/modelImage?modelId=${modelId}`,
    method: 'POST',
    data: image,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  return requestMiddleware(config);
}

export async function getUsageForReportingClient(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/reportingClients/id/${id}/usage`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getUpdatingProcessStatus(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/reportingClients/id/${id}/updateProgress`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postStartUpdate(id: number, adminPassword: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/reportingClients/id/${id}/softwareUpdate?adminPassword=${adminPassword}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postReboot(vesselId: string, adminPassword: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/reboot?adminPassword=${adminPassword}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function requestClientLogs(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/logs/request/vessel/${vesselId}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getCustomDictionaries(): Promise<MyResponse> {
  return requestMiddleware(DemoAdvancedDeviceCache.getInstance().getCustomDicts());
}

export async function getAdminCustomDictionaries(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/customDictionaries',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateCustomDictionary(data: {
  name: string;
  description: string;
  valuePairs: { value: string; displayValue: string }[];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/customDictionaries/create',
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function putUpdateCustomDictionary(data: {
  id: string;
  name: string;
  description: string;
  valuePairs: { value: string; displayValue: string }[];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/admin/customDictionaries/update',
    method: 'PUT',
    data,
  };
  return requestMiddleware(config);
}

export async function deleteCustomDictionary(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/customDictionaries/delete?id=${id}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}
