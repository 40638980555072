import { InitialValueHolderI } from 'demo/dataImporter';
import { AdvancedDevicesPropertyVh } from 'demo/persistentStorage/database/tables';

import { generateValueHoldersForDb } from './devicesUtils';

class GudeController {
  generateProperties = (deviceId: number): Partial<AdvancedDevicesPropertyVh>[] => {
    const devices: {
      name: string;
      bus: string;
      valueHolders: InitialValueHolderI[];
      // eslint-disable-next-line @typescript-eslint/no-var-requires
    }[] = require('demo/data/devices/AdvancedDevices.json');
    const gudeDevice = devices.find(
      device => device.name === 'Gude 12-Fold Switched and Metered PDU'
    );
    if (!gudeDevice) {
      return [];
    }
    return generateValueHoldersForDb(deviceId, gudeDevice.valueHolders, 'GUDE', 'GUDE', 0);
  };
}

export default GudeController;
