import { LocalStorageStoreHandler } from 'demo/persistentStorage/localStorage/Store/StoreHandler';
import { Condition } from 'store/vesselRoutines/types';

import { storeHandler } from '../Store/storeHandler';

export interface AdvancedDeviceTable {
  address: string;
  bus: string;
  controllable: boolean;
  gatewayAddress: string;
  uid: string;
  userDefinedName: string;
  cloudSync: boolean;
}

export interface AdvancedDevicesPropertyVh {
  // id: string;
  category: string;
  controllable: boolean;
  value: string;
  deviceGroup: string;
  deviceUid: string; //FIXME: Is this needed?
  gaugeHigh: number;
  gaugeLow: number;
  includeInSeaReady: boolean;
  instance: string;
  instantReport: boolean; //FIXME: Is this needed?
  invert: boolean;
  loggingEnabled: boolean;
  multiplier: number;
  name: string;
  propertyName: string;
  propertyUid: string;
  rangeHigh: number;
  rangeLow: number;
  unit: string;
  deviceId: string | number;
  customDictionary: string | null;
  includeInSummary: boolean;
  active: boolean;
  dictionary: Record<string, string>;
}

export interface SimpleDeviceTable {
  // id: string;
  controllable: boolean;
  displayComponent: string;
  location: string;
  manufacturer: string; //TODO: might get removed
  model: string; //TODO: might get removed
  name: string;
  subSystem: string;
  system: string;
  showInDefaultInterface: boolean;
}

export interface SimpleDeviceFieldWrapperTable {
  // id: string;
  controllable: boolean;
  fieldName: string;
  fieldOrder: number;
  fieldType: string;
  includeInGauges: boolean;
  deviceId: number;
  dpvhId: string;
}

export interface SimpleDeviceMetaDataTable {
  // id: string;
  key: string;
  value: string;
  simpleDeviceId: number;
}

export interface DeviceAlertTable {
  // id: string;
  deviceId: string;
  alertId: string;
  instant: boolean;
  warnLevelHigh: string;
  warnLevelLow: string;
  criticalLevelHigh: string;
  criticalLevelLow: string;
  state: string;
  alertDelayEnable: boolean;
  alertDelayMs: number;
  alertMessage: string;
  repeatOptions: string;
  instantAlertLevel: string;
}

export interface CustomAlertTable {
  // id: string;
  ackTimestamp: string | null;
  ackUsername: string;
  acknowledged: boolean;
  active: boolean;
  alertLevel: string;
  lastTriggered: string;
  lastTriggeredValue: string;
  matchAllConditions: boolean;
  message: string;
  name: string;
  paused: boolean;
  repeatOptions: string;
  silenceTimestamp: string | null;
  silenceUserId: string | null;
  silenceUsername: string;
  silenced: boolean;
  conditions: Condition[];
}

export type databaseResponse<T> = T & { id: number };

export const StoreConfigs = {
  AdvancedDevice: {
    name: 'AdvancedDevice',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
  },
  AdvancedDevicePropertyVh: {
    name: 'AdvancedDevicePropertyVh',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
    indexes: [{ name: 'deviceIdIndex', keyPath: 'deviceId', unique: false }],
  },
  SimpleDevice: {
    name: 'SimpleDevice',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
    indexes: [{ name: 'systemIndex', keyPath: 'system', unique: false }],
  },
  SimpleDevicesFieldWrapper: {
    name: 'SimpleDevicesFieldWrapper',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
    indexes: [{ name: 'deviceIdIndex', keyPath: 'deviceId', unique: false }],
  },
  SimpleDeviceMetaData: {
    name: 'SimpleDeviceMetaData',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
    indexes: [{ name: 'simpleDeviceIdIndex', keyPath: 'simpleDeviceId', unique: false }],
  },
  DeviceAlert: {
    name: 'DeviceAlert',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
    indexes: [{ name: 'alertIdIndex', keyPath: 'alertId', unique: false }],
  },
  CustomAlert: {
    name: 'CustomAlert',
    options: {
      keyPath: 'id',
      autoIncrement: true,
    },
  },
  Vessel: {
    name: 'Vessel',
    useLocalStorage: true,
  },
  VesselLogs: {
    name: 'VesselLogs',
    useLocalStorage: true,
  },
  VesselUsers: {
    name: 'VesselUsers',
    useLocalStorage: true,
  },
  VesselCameras: {
    name: 'VesselCameras',
    useLocalStorage: true,
  },
  VesselNotes: {
    name: 'VesselNotes',
    useLocalStorage: true,
  },
  VesselDocuments: {
    name: 'VesselDocuments',
    useLocalStorage: true,
  },
  VesselAccess: {
    name: 'VesselAccess',
    useLocalStorage: true,
  },
  VesselNotifications: {
    name: 'VesselNotifications',
    useLocalStorage: true,
  },
  VesselDeviceNotes: {
    name: 'VesselDeviceNotes',
    useLocalStorage: true,
  },
  VesselRoutine: {
    name: 'VesselRoutine',
    useLocalStorage: true,
  },
  VesselGeofence: {
    name: 'VesselGeofence',
    useLocalStorage: true,
  },
  VesselAlertLogs: {
    name: 'VesselAlertLogs',
    useLocalStorage: true,
  },
  VesselSimpleDevicesLocations: {
    name: 'VesselSimpleDevicesLocations',
    useLocalStorage: true,
  },
  DeviceCalibrations: {
    name: 'DeviceCalibrations',
    useLocalStorage: true,
  },
};

export type StoreConfig = {
  name: string;
  options?: IDBObjectStoreParameters;
  indexes?: { name: string; keyPath: string; unique: boolean }[];
  useLocalStorage?: boolean;
};

export type StoreHandlers<TStores> = {
  [K in keyof TStores]: storeHandler | LocalStorageStoreHandler;
};
