import { DemoAdvancedDeviceCache } from 'demo/caches/AdvancedDeviceCache';
import { buses } from 'demo/controllers/devices/advancedDevices/devicesUtils';
import DemoDatabase from 'demo/persistentStorage';
import {
  AdvancedDevicesPropertyVh,
  AdvancedDeviceTable,
} from 'demo/persistentStorage/database/tables';
import { getRandom, getRandomInt } from 'demo/utility';
import { VesselDevice } from 'store/selectedDevice/types';
import { Bus } from 'store/selectedVessel/types';
import { DeviceBasicResponse } from 'types/devices';

import AdvancedDeviceProperty from './AdvancedDeviceProperty';

type UpdateDeviceRequest = {
  presentAddress: string;
  gatewayAddress: string;
  manufacturer: string;
  model: string;
  deviceSerial: string;
  name: string;
  softwareVersion: string;
};

class DemoAdvancedDevice {
  name!: string;
  bus!: Bus;
  gatewayAddress!: string;
  controllable!: boolean;
  systemDevice!: boolean;
  cloudSync!: boolean;
  uid!: string;
  vhs: AdvancedDeviceProperty[] = [];
  id!: number;
  deviceNotifications!: boolean;
  online!: boolean;
  state = 'NORMAL';
  constructor(device: AdvancedDeviceTable & { id: number }) {
    Object.assign(this, {
      ...device,
      bus: buses.find(bus => bus.bus === device.bus) || {
        bus: 'CAN2',
        source: 'Can Port 2',
        supportsPropertyAdd: false,
      },
      name: device.userDefinedName,
      friendlyName: device.userDefinedName,
      systemDevice: device.userDefinedName === 'LINKbridge',
      deviceNotifications: true,
      online: true,
    });
    this.retrieveVhs();
  }

  retrieveVhs = async () => {
    DemoDatabase.getInstance()
      .AdvancedDevicePropertyVh.where<AdvancedDevicesPropertyVh>('deviceIdIndex', this.id)
      .then(res => {
        this.vhs = Array.from(res, vh => new AdvancedDeviceProperty(this, vh));
        this.highestWarningLevel();
      })
      .catch(err => console.log(err));
  };

  getSummaryText(): string {
    if (this.bus.bus === 'NETWORK') {
      return 'IP Address: ' + this.gatewayAddress + '\n MAC Address: ' + this.uid;
    }
    let finalString = '';
    this.vhs.forEach(dpvh => {
      if (
        dpvh.includeInSummary &&
        null != dpvh.getParsedValue() &&
        dpvh.getParsedValue() !== '' &&
        dpvh.getParsedValue() !== 'N/A'
      ) {
        finalString += `${dpvh.getName()}: ${dpvh.getParsedValue()}\n`;
      }
    });
    return finalString;
  }

  randomUpdate(): void {
    const minInterval = 0.1;
    const maxInterval = 0.9;
    this.vhs.forEach(vh => {
      if (vh.containsDict() || isNaN(Number(vh.value)) || vh.controllable) {
        return;
      }
      const customDictionary = vh.customDictionary;
      if (customDictionary !== null && customDictionary !== '') {
        const dictFound = DemoAdvancedDeviceCache.getInstance()
          .getCustomDicts()
          .find(customDict => customDict.name === customDictionary);
        if (dictFound && dictFound.valuePairs && dictFound.valuePairs.length > 0) {
          const randomIndex = Math.floor(Math.random() * dictFound.valuePairs.length);
          vh.value = dictFound.valuePairs[randomIndex].value;
        }
        return;
      }
      const currentVal = Number(vh.value);
      const randomSymbol = getRandomInt(1, 2);

      const randomNumber = getRandom(minInterval, maxInterval, 2);
      const max = vh.gaugeHigh !== 0 ? vh.gaugeHigh : null;
      const min = vh.gaugeLow !== 0 ? vh.gaugeLow : null;
      let value;
      const withinMin = currentVal > (min || 3);
      const withinMax = currentVal > (max || 95);
      if (withinMin) {
        if (withinMax) {
          value = +(currentVal - (max ? 0.5 : 20)).toFixed(2);
        } else {
          value = +(currentVal + (randomSymbol === 1 ? randomNumber : -randomNumber)).toFixed(2);
        }
      } else {
        value = +(currentVal + (min ? 0.5 : 20)).toFixed(2);
      }

      vh.value = value.toString();
    });
  }

  basicResponse(): DeviceBasicResponse {
    if (this.state === 'NORMAL') {
      this.highestWarningLevel();
    }
    return {
      id: this.id.toString(),
      name: this.name,
      bus: this.bus,
      summaryText: this.getSummaryText(),
      gatewayAddress: this.gatewayAddress,
      controllable: this.controllable,
      cloudSync: this.cloudSync,
      deviceState: this.state,
      valueHolders: this.vhs.map(vh => vh.name),
      systemDevice: this.systemDevice,
      deviceNotifications: this.deviceNotifications,
    };
  }

  highestWarningLevel() {
    let status = 'NORMAL';
    this.vhs.forEach(vh => {
      if (status === 'CRITICAL') {
        return;
      }
      if (!vh.alertConfig) {
        return;
      }
      const state = vh.alertConfig.getState();
      if (status === 'NORMAL' && state === 'WARNING') {
        status = 'WARNING';
      } else if (state === 'CRITICAL') {
        status = 'CRITICAL';
      }
    });
    this.state = status;
  }

  mergeUpdate(data: Partial<this>): void {
    Object.assign(this, {
      ...this,
      ...data,
    });
  }

  fullResponse(): VesselDevice {
    if (this.state === 'NORMAL') {
      this.highestWarningLevel();
    }
    return {
      id: this.id.toString(),
      name: this.name,
      friendlyName: this.name,
      bus: this.bus,
      summaryText: this.getSummaryText(),
      gatewayAddress: this.gatewayAddress,
      controllable: this.controllable,
      deviceSerial: '',
      lastSeen: new Date().toISOString(),
      manufacturer: '',
      model: '',
      modelVersion: '',
      prefix: '',
      presentAddress: '',
      softwareVersion: '',
      state: '',
      uid: '',
      userDefinedName: '',
      cloudSync: this.cloudSync,
      deviceState: this.state,
      valueHolders: this.vhs.map(vh => vh.fullResponse()),
      systemDevice: this.systemDevice,
      deviceNotifications: this.deviceNotifications,
      onlineState: this.online,
    };
  }

  deviceUpdateResponse(): UpdateDeviceRequest {
    return {
      presentAddress: '',
      gatewayAddress: this.gatewayAddress,
      manufacturer: '',
      model: '',
      deviceSerial: '',
      name: this.name,
      softwareVersion: '',
    };
  }

  databaseStruct(): AdvancedDeviceTable {
    return {
      address: '0',
      bus: this.bus.bus,
      controllable: this.controllable,
      gatewayAddress: this.gatewayAddress,
      uid: this.uid,
      userDefinedName: this.name,
      cloudSync: this.cloudSync,
    };
  }
}

export default DemoAdvancedDevice;
