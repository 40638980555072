import {
  UserNotification,
  UserNotificationEvent,
} from 'views/DashboardRouter/components/UserProfile';

import DemoDatabase from '../persistentStorage';
import { createNewItemWithCustomId } from '../utility';
import DemoVesselController from './vesselController';

class DemoNotificationsController {
  private static instance: DemoNotificationsController | null = null;
  private _notifications: UserNotification[] = [];

  public static getInstance(): DemoNotificationsController {
    if (this.instance === null) {
      this.instance = new DemoNotificationsController();
    }
    return this.instance;
  }

  get notifications(): UserNotification[] {
    return this._notifications;
  }

  retrieveNotifications = async (): Promise<UserNotification[]> => {
    if (this._notifications.length === 0) {
      this._notifications =
        await DemoDatabase.getInstance().VesselNotifications.getAllWithDefault();
    }
    return this._notifications;
  };

  createNotification = (eventText: string): void => {
    const id = isNaN(Number(DemoVesselController.getInstance().vessel?.id))
      ? Number(DemoVesselController.getInstance().vessel?.id) ?? 1
      : 1;
    if (this.notifications.length === 0) {
      const newNotification: UserNotification = {
        id: 1,
        timestamp: new Date().toISOString(),
        acknowledged: false,
        notificationEvents: [
          {
            id: 1,
            timestamp: new Date().toISOString(),
            eventText: eventText,
          },
        ],
        vesselName: DemoVesselController.getInstance().vessel?.name ?? 'Demo Vessel',
        vesselId: id,
      };
      this._notifications = [newNotification];
      DemoDatabase.getInstance().VesselNotifications.add(
        this._notifications[this._notifications.length - 1]
      );
      return;
    }
    const newNotificationEvents = this.prevNotificationCheck(eventText);
    if (newNotificationEvents.length <= 1) {
      const newNotification: Partial<UserNotification> = {
        timestamp: new Date().toISOString(),
        acknowledged: false,
        notificationEvents: newNotificationEvents,
        vesselName: DemoVesselController.getInstance().vessel?.name ?? 'Demo Vessel',
        vesselId: id,
      };
      this._notifications = createNewItemWithCustomId(
        this._notifications,
        newNotification,
        'id'
      ) as UserNotification[];
      DemoDatabase.getInstance().VesselNotifications.add(
        this._notifications[this._notifications.length - 1]
      );
      return;
    }

    const prevNotification = this._notifications[this._notifications.length - 1];
    this._notifications[this._notifications.length - 1] = {
      id: prevNotification.id,
      timestamp: new Date().toISOString(),
      acknowledged: false,
      notificationEvents: newNotificationEvents,
      vesselName: DemoVesselController.getInstance().vessel?.name ?? 'Demo Vessel',
      vesselId: id,
    };
    DemoDatabase.getInstance().VesselNotifications.add(
      this._notifications[this._notifications.length - 1]
    );
  };

  prevNotificationCheck = (msg: string): UserNotificationEvent[] => {
    const prevNotification = this._notifications[this._notifications.length - 1];
    const notificationEvents = prevNotification.notificationEvents;
    const sameNotification = this.checkWithin5Mins(
      notificationEvents[notificationEvents.length - 1].timestamp
    );
    const newNotificationEvent: Partial<UserNotificationEvent> = {
      timestamp: new Date().toISOString(),
      eventText: msg,
    };
    if (sameNotification) {
      return createNewItemWithCustomId(
        notificationEvents,
        newNotificationEvent,
        'id'
      ) as UserNotificationEvent[];
    }
    return [
      {
        ...newNotificationEvent,
        id: 1,
      } as UserNotificationEvent,
    ];
  };

  checkWithin5Mins = (timestampStr: string): boolean => {
    const timestamp = new Date(timestampStr).getTime();
    const currentTime = new Date().getTime();
    const dif = currentTime - timestamp;
    return dif <= 300000;
  };

  clearNotifications = (): void => {
    this._notifications = [];
    DemoDatabase.getInstance().VesselNotifications.clear();
  };
}

export default DemoNotificationsController;
