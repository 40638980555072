import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function postLogin(username: string, password: string): Promise<MyResponse> {
  return requestMiddleware({ token: 'TOKEN' });
}

export async function postCheckSession(): Promise<MyResponse> {
  return requestMiddleware({ message: '', status: true });
}

export async function postForgottenPassword(data: { email: string }): Promise<MyResponse> {
  return requestMiddleware({});
}

export async function postResetPassword(data: {
  email: string;
  authCode: string;
  newPassword: string;
}): Promise<MyResponse> {
  return requestMiddleware({});
}
