import { getLocations } from 'api/simpleDevice';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveError } from '../store/local/actions';
import { RootState } from '../store/rootReducer';
import { PermissionsContext } from '../views/VesselDashboard/permissionsContext';

interface LocationsContextInterface {
  locations: string[];
  refreshLocations: () => void;
  loading: boolean;
}

const LocationsContext = createContext<LocationsContextInterface>({
  locations: [],
  refreshLocations: () => {
    return;
  },
  loading: false,
});

const LocationsContextProvider = (props: { children: JSX.Element }) => {
  const [locations, setLocations] = useState<string[]>([]);
  const { selectedVessel } = useSelector((state: RootState) => state.selectedVessel);
  const [latestId, setLatestId] = useState<string | null>(selectedVessel?.id || null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { deviceManager } = useContext(PermissionsContext);

  const refreshLocations = () => {
    if (selectedVessel) {
      getLocations(selectedVessel.id)
        .then(response => {
          if (response && response.data) {
            if (Array.isArray(response.data)) {
              setLocations(response.data);
            }
          }
        })
        .catch(err => dispatch(saveError(err)))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (selectedVessel && selectedVessel.id !== latestId) {
      setLatestId(selectedVessel.id);
      if (deviceManager !== 'DENY') {
        refreshLocations();
      }
    }
  }, [selectedVessel]);

  return (
    <LocationsContext.Provider value={{ locations, refreshLocations, loading }}>
      {props.children}
    </LocationsContext.Provider>
  );
};

export { LocationsContext, LocationsContextProvider };
