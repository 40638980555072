import { DemoSimpleDevicesCache } from 'demo/caches/simpleDevices/SimpleDevicesCache';
import DemoDatabase from 'demo/persistentStorage';
import { SimpleDeviceTable } from 'demo/persistentStorage/database/tables';
import { createNewItemWithCustomId, createNewItemWithId } from 'demo/utility';
import { Controller, ControllerInterface, DeviceCategory } from 'store/selectedVessel/types';
import { SimpleDevice } from 'types/simpleDevices';

import DemoCamerasController from './camerasController';

class DemoAccessController {
  private static instance: DemoAccessController | null = null;
  private _controllers: Controller[] = [];
  private _interfaces: ControllerInterface[] = [];

  constructor() {
    if (this._controllers.length === 0) {
      this._controllers = require('demo/data/access/Interfaces.json');
    }
  }

  public static getInstance(): DemoAccessController {
    if (this.instance === null) {
      this.instance = new DemoAccessController();
    }
    return this.instance;
  }

  get controllers(): Controller[] {
    return this._controllers;
  }

  retrieveInterfaces = async (): Promise<ControllerInterface[]> => {
    if (this._interfaces.length === 0) {
      this._interfaces = await DemoDatabase.getInstance().VesselAccess.getAllWithDefault();
    }
    return this._interfaces;
  };

  generateInterfaceTemplate = async (): Promise<DeviceCategory[]> => {
    if (this._interfaces.length === 0) {
      this._interfaces = await DemoDatabase.getInstance().VesselAccess.getAllWithDefault();
    }
    const locations = await DemoSimpleDevicesCache.getInstance().getLocations();
    const newDefaultInterfaceCategories: any[] = [];
    for (const category of this._interfaces[0].categories) {
      let name = category.name.toUpperCase();
      if (name === 'MARINE') {
        name = 'MARITIME';
      } else if (name === 'TANKS') {
        name = 'TANK';
      }
      const devices = await DemoDatabase.getInstance().SimpleDevice.where<
        SimpleDeviceTable & { id: number }
      >('systemIndex', name);
      const newLocations: any[] = [];
      if (category.name === 'Link') {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [
            {
              allowed: 'NONE',
              name: 'Link',
              devices: [
                { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: 'NONE', selectable: true },
              ],
            },
          ],
          devices: [
            { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: 'NONE', selectable: true },
          ],
        });
        continue;
      } else if (category.name === 'Cameras') {
        const cameras = await DemoCamerasController.getInstance().retrieveCameras();
        const devices = [
          ...cameras.map(camera => {
            return {
              location: camera.location,
              name: camera.name,
              id: camera.id,
              warningState: 'NORMAL',
              manufacturer: 'null',
              model: 'null',
              serial: 'null',
              system: 'CAMERA',
              subSystem: 'CCTV',
              controllable: false,
              online: true,
              displayComponent: 'camera',
              fields: [],
              selectable: true,
              state: 'NORMAL',
              templateName: 'Camera',
              metaData: { capacity: '' },
              friendlyName: camera.name,
            } as SimpleDevice;
          }),
        ];
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: category.locations,
          devices: devices,
        });
        continue;
      }
      if (devices.length === 0) {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [],
          devices: [],
        });
        continue;
      }
      if (devices) {
        locations.forEach(location => {
          const locationDevices = devices.filter(device => device.location === location);
          if (locationDevices.length > 0) {
            newLocations.push({
              allowed: 'NONE',
              name: location,
              devices: locationDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: 'NONE',
                  selectable: true,
                };
              }),
            });
          }
        });
      }
      const newCategory = {
        name: category.name,
        allowed: 'NONE',
        locations: newLocations,
        devices:
          devices && devices.length > 0
            ? devices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: 'NONE',
                  selectable: true,
                };
              })
            : [],
      };
      newDefaultInterfaceCategories.push(newCategory);
    }
    return newDefaultInterfaceCategories;
  };

  generateInterface = async (): Promise<ControllerInterface[]> => {
    if (this._interfaces.length === 0) {
      this._interfaces = await DemoDatabase.getInstance().VesselAccess.getAllWithDefault();
    }
    const locations = await DemoSimpleDevicesCache.getInstance().getLocations();
    const newDefaultInterfaceCategories: any[] = [];
    for (const category of this._interfaces[0].categories) {
      let name = category.name.toUpperCase();
      if (name === 'MARINE') {
        name = 'MARITIME';
      } else if (name === 'TANKS') {
        name = 'TANK';
      }
      const devices = await DemoDatabase.getInstance().SimpleDevice.where<
        SimpleDeviceTable & { id: number }
      >('systemIndex', name);
      const newLocations: any[] = [];
      if (category.name === 'Link') {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: category.allowed,
          locations: [
            {
              allowed: 'ALL',
              name: 'Link',
              devices: [
                { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: true, selectable: true },
              ],
            },
          ],
          devices: [{ id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: true, selectable: true }],
        });
        continue;
      } else if (category.name === 'Cameras') {
        const cameras = await DemoCamerasController.getInstance().retrieveCameras();
        const devices = [
          ...cameras.map(camera => {
            return {
              location: camera.location,
              name: camera.name,
              id: camera.id,
              warningState: 'NORMAL',
              manufacturer: 'null',
              model: 'null',
              serial: 'null',
              system: 'CAMERA',
              subSystem: 'CCTV',
              controllable: false,
              online: true,
              displayComponent: 'camera',
              fields: [],
              selectable: true,
              state: 'NORMAL',
              templateName: 'Camera',
              metaData: { capacity: '' },
              friendlyName: camera.name,
            } as SimpleDevice;
          }),
        ];
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: category.allowed, //DemoCamerasController.getInstance().cameras.length > 0 ? 'FULL' : 'NONE'
          locations: category.locations,
          devices: devices,
        });
        continue;
      }
      if (devices.length === 0) {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [],
          devices: [],
        });
        continue;
      }
      if (devices) {
        locations.forEach(location => {
          const locationDevices = devices.filter(device => device.location === location);
          if (locationDevices.length > 0) {
            const categoryLocation = category.locations.find(
              catLocation => catLocation.name === location
            );
            const locationAllowed = devices.every(device => device.showInDefaultInterface)
              ? 'FULL'
              : 'NONE';
            //PARTIAL
            newLocations.push({
              allowed: categoryLocation ? locationAllowed : 'NONE',
              name: location,
              devices: locationDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: true,
                  selectable: true,
                };
              }),
            });
          }
        });
      }
      const newCategory = {
        name: category.name,
        allowed: category.allowed,
        locations: newLocations,
        devices:
          devices && devices.length > 0
            ? devices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: true,
                  selectable: true,
                };
              })
            : [],
      };
      newDefaultInterfaceCategories.push(newCategory);
    }
    this._interfaces = this._interfaces.map((interfaceItem, index) => {
      if (index === 0) {
        return { ...interfaceItem, categories: newDefaultInterfaceCategories };
      }
      return interfaceItem;
    });
    return this._interfaces;
  };

  createNewInterface = (data: ControllerInterface): void => {
    this._interfaces = createNewItemWithCustomId(this._interfaces, data, 'id', true);
    DemoDatabase.getInstance().VesselAccess.add(this._interfaces[this._interfaces.length - 1]);
  };

  deleteInterface = (interfaceId: string): void => {
    this._interfaces = this._interfaces.filter(interfaceItem => interfaceItem.id !== interfaceId);
    DemoDatabase.getInstance().VesselAccess.delete(interfaceId);
  };

  updateInterface = (interfaceId: string, data: ControllerInterface): void => {
    const interfaceFound = this._interfaces.findIndex(
      interfaceItem => interfaceItem.id === interfaceId
    );
    if (interfaceFound === -1) {
      return;
    }
    const newInterfaces = [...this._interfaces];
    newInterfaces[interfaceFound] = data;
    this._interfaces = newInterfaces;
    DemoDatabase.getInstance().VesselAccess.update(
      this._interfaces[interfaceFound].id as string,
      this._interfaces[interfaceFound]
    );
  };

  createController = (data: Controller): void => {
    this._controllers = createNewItemWithId(this._controllers, data, 'id');
  };

  updateController = (interfaceId: number, data: Controller): void => {
    const controllerIndex = this._controllers.findIndex(
      controller => controller.id === interfaceId
    );
    if (controllerIndex === -1) {
      return;
    }
    const newControllers = [...this._controllers];
    newControllers[controllerIndex] = data;
    this._controllers = newControllers;
  };

  removeController = (id: number): void => {
    this._controllers = this._controllers.filter(controller => controller.id !== id);
  };
}

export default DemoAccessController;
