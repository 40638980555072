import { Note } from 'store/types';

import DemoDatabase from '../persistentStorage';
import { createNewItemWithId } from '../utility';

class DemoNotesController {
  private _notes: Note[] = [];

  retrieveNotes = async (): Promise<Note[]> => {
    if (this._notes.length === 0) {
      this._notes = await DemoDatabase.getInstance().VesselNotes.getAllWithDefault();
    }
    return this._notes;
  };

  addNewNote = (note: Note): void => {
    const newDate = this.parseDateString(note.timestamp);
    let newNote = note;
    if (newDate) {
      newNote = { ...note, timestamp: newDate.toISOString() };
    }
    this._notes = createNewItemWithId(this._notes, newNote, 'id');
    DemoDatabase.getInstance().VesselNotes.add(this._notes[this._notes.length - 1]);
  };

  deleteNote = (id: number): void => {
    this._notes = this._notes.filter(note => note.id !== id);
    DemoDatabase.getInstance().VesselNotes.delete(id);
  };

  parseDateString(dateString: string): Date | null {
    const parts = dateString.split(/[\/,:\s]/);
    if (parts.length === 7) {
      const day = parseInt(parts[0], 10);
      const month = parseInt((Number(parts[1]) - 1).toString(), 10); // Months are zero-based in JavaScript
      const year = parseInt(parts[2], 10);
      const hours = parseInt(parts[4], 10);
      const minutes = parseInt(parts[5], 10);
      const seconds = parseInt(parts[6], 10);
      return new Date(year, month, day, hours, minutes, seconds);
    } else {
      return null;
    }
  }
}

export default DemoNotesController;
